import { SHOW_CONTEXT, HIDE_CONTEXT, SET_CURSOR } from './actionTypes';

export function showContext(contextType, data) {
	return {
		type: SHOW_CONTEXT,
		payload: {
			contextType: contextType,
			data
		}
	};
} 

export function closeContext(){
	return {
		type: HIDE_CONTEXT
	}
}

export function setCursorPos(x, y) {
	return {
		type: SET_CURSOR,
		payload: {
			cursorX: x,
			cursorY: y
		}
	};
}