import React, { Component } from 'react';
import moment from 'moment';

import DashTodo from '../../todo/components/DashTodo';
import Task from '../../task/components/Task';

import styles from './css/today.module.css';

export default class TodayView extends Component {
	componentDidMount() {
		this.interval = setInterval(()=>{
			if(new Date().getSeconds()===0){
				this.setState({});
			}
		},1000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	render() {
		const selectedDate = this.props.todayViewShowDate;
		const active_todos = [];
		const past_todos = [];
		this.props.all_todos.filter(todo=>{
			if(todo.complete){return false}
			let time = moment(todo.start_time);
			if(todo.due_time){time=moment(todo.due_time)}

			return selectedDate.isSame(time, 'day');

		}).forEach(todo => {
			let time = moment(todo.start_time);
			if(todo.due_time){time = moment(todo.due_time)}
			
			if(time.isAfter(selectedDate, 'minute')){active_todos.push(todo)}
			else {past_todos.push(todo)}
		});

		const sorted = active_todos.filter(todo=>{
			return !todo.complete;
		}).sort((a,b)=>{
			let a_time = moment(a.start_time);
			let b_time = moment(b.start_time);
			if(a.due_time){a_time=moment(a.due_time)}
			if(b.due_time){b_time=moment(b.due_time)}

			const a_diff = a_time.diff(selectedDate, 'minute');
			const b_diff = b_time.diff(selectedDate, 'minute')

			if(a_diff<b_diff){
				return -1;
			} else if(a_diff>b_diff){
				return 1;
			}
			return 0;
		}).concat(past_todos);

		const today_todos = sorted.map(todo => {
			if(todo.start_time){
				return(
					<DashTodo 
						todo={todo}
						key={todo._id}
						showContext={this.props.showContext}
						setCursorPos={this.props.setCursorPos}
						handleEdit={this.props.editTodo}
						handleDelete={this.props.deleteTodo}
					/>
				);
			} else {
				return(
					<Task 
						task={todo}
						key={todo._id}
						showContext={this.props.showContext}
						setCursorPos={this.props.setCursorPos}
						editTask={this.props.editTask}
					/>
				);
			}
		});

		return (
			<div className={styles.todoContainer}>
				{
				today_todos.length>0?
				today_todos:
				<div className={styles.imgContainer}>
					<a href="http://www.freepik.com">Provided by Freepik</a>
					<img 
						src="https://d2voyh5ncb6zec.cloudfront.net/relax.png" 
						alt="no events or tasks" 
						className={styles.noneImg}
					/>
					<p>No Events or Tasks today.</p>
					<p>Relax, or get a head start on tomorrow's tasks!</p>
				</div>
				}
			</div>
		);
	}
}
