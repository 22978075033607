import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';

import { selectionTimes } from './times';

import styles from './css/remindselector.module.css';

import DatePicker from './DatePicker';
import TimePicker from './TimePicker';

export default class RemindSelector extends Component {
	constructor(props){ 
		super(props);
		this.selectionTimes = selectionTimes;
		this.state = {
			text: this.getText(),
			show: false,
			time: this.props.display ? this.props.display.format('h:mm A'): "12:00 AM",
			date: this.props.display ? this.props.display.format('YYYY-MM-DD'):moment().toDate()
		};
	}
	getText = () => {
		if(this.props.display && this.props.data){
			//data_time: event start time
			const data_time = moment(
									moment(this.props.data.date).format('YYYY-MM-DD')+" "+
									moment(this.props.data.date).format('h:mm A'), 
								'YYYY-MM-DD h:mm A');
			//If passed start date is Unix Epoch, select Never
			if(this.props.display.valueOf()===moment.unix(0).toDate().valueOf()){
				return 'Never';
			} else if(this.props.display.isSame(data_time)){
				//passed start date is equal to remind date
				return 'At time of event'
			}

			//Times in minutes, if minutes is in hardcoded list select that item
			const times = [5, 15, 30, 60, 120, 720, 1440, 4320, 10080];
			const diff = Math.abs(this.props.display.diff(data_time, 'minutes'));
			if(times.includes(diff)){
				return this.getSelection(diff);
			} else {
				return 'Custom';
			}
		} else {
			return 'Never';
		}
	}

	getSelection = diff => {
		if(diff===10080){
			return this.selectionTimes[10];
		} else if(diff>=1440) {
			return diff/1440 + ' day' + (diff/1440>1 ? 's' :'') + ' before';  
		} else if(diff>=60){
			return diff/60 + ' hour' + (diff/60>1 ? 's' :'') + ' before';
		} else {
			return diff+ ' minutes before';
		}
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
		this.props.updateParent(this.props.name, moment.unix(0).toDate());
	}


	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	componentDidUpdate(prevProps, prevState) {
		if(!_.isEqual(prevProps, this.props) || !_.isEqual(prevState, this.state)){
			let date;
			let time;
			if(this.state.text==='At time of event'){
				//get current passed in start date
				date = this.props.data.date.format('YYYY-MM-DD');
				time = this.props.data.date.format('h:mm A');
			} else if(this.state.text==='Never') {
				date = moment.unix(0).format('YYYY-MM-DD');
				time = moment.unix(0).format('h:mm A');
			} else if(this.state.text!=='Custom'){
				//
				//Time from dropdown
				const offsetType = this.state.text.match(/\bminutes|\bhour|\bday|\bweek/)[0];
				const offset = this.state.text.match(/[0-9]+/)[0];
				const momObj = moment(
								this.props.data.date).subtract(offset, offsetType); 
				date = momObj.format('YYYY-MM-DD');
				time = momObj.format('h:mm A');
			} else {
				//custom time
				if(!this.state.time&&!this.state.date){
					date = moment().startOf('day').format('YYYY-MM-DD');
					time = moment().startOf('day').format('h:mm A');
				} else {
					date = this.state.date;
					time = this.state.time;
				}
			}

			this.props.updateParent(this.props.name, moment(moment(date).format('YYYY-MM-DD')+' '+time, 'YYYY-MM-DD h:mm A').toDate());
		}
	}

	handleClickOutside = e => {
		if(this.remindRef && !this.remindRef.contains(e.target)){
			this.setState({show: false});
		}
	}

	setRemindRef = node => {
		this.remindRef = node;
	}

	handleClick = e => {
		const text = e.target.innerText; 
		this.setState({text, show: false});
	}

	updateDate = (name, date) => {
		this.setState({[name]: moment(date, 'YYYY-MM-DD').toDate()});
	}

	updateTime = (name, time) => {
		this.setState({[name]: time});
	}

	getTDate = datetime => {
		let tDate = datetime.split('-');
		return new Date(tDate[0], tDate[1]-1, tDate[2]);
	}

	render(){
		return (
			<div className={styles.remindSelContainer}>
				<div className={styles.remindSelector}>
					<div className={styles.text}>Remind me:&nbsp;{this.state.text}</div>
					<div className={styles.imgContainer} onClick={()=>this.setState({show: true})}>
						<img 
							src="https://d2voyh5ncb6zec.cloudfront.net/downArrow.svg" 
							alt="Open Remind Menu" 
							className={styles.inputImg}
						/>
					</div>
				</div>
				{this.state.show ?  
					<div className={styles.remindListContainer} ref={this.setRemindRef}>
						<ul className={styles.remindList}>
							{this.selectionTimes.map((txt, index) => {
								return(<li onClick={this.handleClick} key={index}>{txt}</li>);
							})}
							<li onClick={this.handleClick}>Custom</li>
						</ul>	
					</div>
					: null
				}
				{this.state.text==='Custom' ? 
					<div className={styles.datetimeInput}>
						<div className={styles.dateInput}>
							<DatePicker 
								updateDate={this.updateDate} 
								name="date" 
								data={moment(this.state.date, 'YYYY-MM-DD').toDate()}
							/>
						</div>
						<TimePicker updateTime={this.updateTime} name="time" data={this.state.time}/>
					</div>
				:null 
				}
			</div>
		);
	}
}
