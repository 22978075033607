import React from 'react';
import moment from 'moment';
import ReactTooltip from "react-tooltip";

import { TASK_CONTEXT, TASK_INFO } from '../../general/context_menu/contextTypes';

import styles from '../css/task.module.css';

export default function Task(props) {
	function handleContext(e){
		e.preventDefault();
		if(props.setCursorPos && props.showContext){
			props.setCursorPos(e.pageX, e.pageY);
			props.showContext(TASK_CONTEXT, props.task); 
		}
	}

	function onClick(e){
		if(props.setCursorPos && props.showContext){
			props.setCursorPos(e.pageX, e.pageY); 
			props.showContext(TASK_INFO, props.task); 
		}
	}

	const getCategories = () => {
		const categories = props.task.categories.map((category, index) => {
			return (
				<div className={styles.category} key={index}>
					<span style={{color:category.color, fontWeight:'bold'}}>#&nbsp;</span>
					<span>{category.name}</span>
				</div>
			);
		});
		return categories;
	}

	const getDateText = taskDate => {
	 	const date = moment(taskDate);
	 	const today = moment();
	 	const minuteDiff = date.diff(today, 'minute');
	 	const hourDiff = date.diff(today, 'hour');
	 	const dayDiff = date.diff(today, 'day');
		if(minuteDiff<0){
	 		return <p className={styles.past}>over</p>;
	 	} else if(date.isSame(today, 'minute')){
			return <p className={styles.date}>due<span className={styles.now}>{' now '}</span></p>;
		} else if(minuteDiff<60){
			return <p className={styles.date}>due<span className={styles.dateNum}>{` ${minuteDiff} `}</span>mins</p>;
		} else if(hourDiff<24){ 
			return <p className={styles.date}>due<span className={styles.dateNum}>{` ${hourDiff} `}</span>hrs</p>;
		} else if(dayDiff<30){
			return <p className={styles.date}>due<span className={styles.dateNum}>{` ${dayDiff} `}</span>days</p>;
		} else {
			return <p className={styles.date}>due<span className={styles.dateNum}>{` ${date.format('M/D')} `}</span></p>;
		}
	}

	const handleComplete = () => {
		const { task } = props;
		const updated = {
			complete: !task.complete
		};
		props.editTask(updated, task._id);
	}

	return (
		<div className={styles.task} onContextMenu={handleContext} onClick={onClick}>
			<div className={styles.contentRegion}>
				<div className={styles.dateRegion}>{getDateText(props.task.due_time)}</div>
				<div className={styles.taskMain}>
					<h2 className={styles.taskText}>{props.task.name}</h2>
					<div className={styles.taskInfo}>
						{props.task.description ?<p className={styles.taskDescription}>{props.task.description}</p>:null}
					 	{props.task.categories && props.task.categories.length>0 ? 
							<div className={styles.categoryContainer}>
								{getCategories()}
							</div> 
							: null
						}
					</div>
				</div>
			</div>
			<div 
				className={styles.completeBtn} 
				data-tip
				data-for={props.task._id}
				onClick={e=>{
					e.stopPropagation();
					handleComplete();
				}}
			>
				<div className={styles.checkStem}></div>
				<div className={styles.checkKick}></div>
			</div>
			<ReactTooltip id={props.task._id} className={styles.tooltip}>
				<span>Mark as {props.task.complete?'uncomplete':'complete'}</span>
			</ReactTooltip>
		</div> 
	);
}
