import React, { Component } from 'react';
import anime from 'animejs/lib/anime.es.js';
import axios from 'axios';
import _ from 'lodash';

import styles from './css/friendbar.module.css';

export default class FriendBar extends Component {
	constructor(props){
		super(props);
		this.state = {
			showAdd: false,
			addText: '',
			allUsers: [],
			friends: this.props.friends,
			renderedFriends: []
		};
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
		this.renderFriends();
		this.getAllUsers();
		anime({
			targets: this.wrapperRef,
			width: [
            	{ value: 0, duration: 0 },
            	{ value: 250, duration: 500 }
	        ],
		});
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside = e =>{
		if(this.wrapperRef && !this.wrapperRef.contains(e.target)){
			this.props.hideBar();
		}
	}

	handleClose = () => {
		this.props.hideBar();
	}
	
	handleChange = e => {
		const { name, value } = e.target;
		this.setState({[name]:value});
	}

	getAllUsers = () => {
		axios.get('https://taskcence.herokuapp.com/api/user/getAll', {headers:{token: localStorage.getItem('token')}})
			.then(res=>{
				const allUsers = res.data.all_users.filter(user=>user.id!==this.props.id && !_.find(this.props.friends, {id:user.id}));
				this.setState({allUsers})
			})
			.catch(err=>console.log(err));
	}

	getResults = () => {
		let results = [];
		this.state.allUsers.forEach(user=>{
			if(user.username.toLowerCase().indexOf(this.state.addText.toLowerCase()) > -1){
				results.push(
					<div className={styles.userCard} key={user.id}>
						<p>{`${user.username} (${user.name})`}</p>
						<div 
							className={styles.plusBtn} 
							onClick={()=>this.props.handleAddFriend(user.id)}
						>
						</div>
					</div>
				);
			}
		});
		return results;
	}

	renderAdd = () => {
		const usersList = this.getResults();
		return(
			<div className={styles.overlay}>
				<div className={styles.addFriendContainer}>
					<div className={styles.addTop}>
						<button 
							onClick={()=>this.setState({showAdd: false})} 
							className={styles.addClose}
						>
							&times;
						</button>
						<input 
							className={styles.addInput}
							value={this.state.addText}
							onChange={this.handleChange}
							name="addText"
							placeholder="Username"
						/>
					</div>
					<div className={styles.resultContainer}>
						{this.state.addText?
							usersList
							:null
						}
					</div>
				</div>
			</div>
		);
	}

	renderFriends = () => {
		this.state.friends.forEach(friend=>{
			this.setState(prevState=>{
				return({
					renderedFriends: [
						...prevState.renderedFriends,
						<div key={friend.id} className={styles.friendCard}>
							<img 
								className={styles.profilePic}
								src={friend.profile_pic} 
								alt={friend.username} 
							/>
							<div className={styles.friendInfo}>
								<span className={styles.bold}>{friend.username}</span>
								<span>&nbsp;({friend.name})</span>
							</div>
						</div>
					]
				});
			});
		});
	}

	render() {
		return (
			<div 
				className={styles.friendBarContainer}
				ref={node=>this.wrapperRef=node}
			>
				<div className={styles.top}>
					<p>Friends</p>
					<button className={styles.addFriendBtn} onClick={()=>this.setState({showAdd: true})}>
						<img 
							className={styles.addFriendImg} 
							src="https://d2voyh5ncb6zec.cloudfront.net/addFriends.svg" 
							alt="add friends"
						/>
					</button>
					<button onClick={this.handleClose} className={styles.close}>&times;</button>
				</div>
				{this.state.showAdd?this.renderAdd():null}
				{this.props.friends.length>0?
					<div className={styles.friendContainer}>
						{this.state.renderedFriends}
					</div>
					:<p className={styles.center}>No Friends</p>
				}
			</div>
		);
	}
}

