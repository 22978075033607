import React, { Component } from 'react';
import { convertToRaw, convertFromRaw } from 'draft-js';
import moment from 'moment';

import Modal from './Modal';
import RichEditor from '../../misc_components/RichEditor';
import DatePicker from '../../misc_components/DatePicker';
import TimePicker from '../../misc_components/TimePicker';
import CategorySelector from '../../misc_components/CategorySelector';
import RemindSelector from '../../misc_components/RemindSelector';
import CheckAvailable from '../../misc_components/CheckAvailable';

import styles from '../css/createmodal.module.css';

export default class UpdateTodoModal extends Component {
	constructor(props){
		super(props);
		this.state = {
			name: this.props.data.todo.name,
			description: this.props.data.todo.description,
			categories: this.props.data.todo.categories,
			priority: this.props.data.todo.priority,
			start_date: new Date(this.props.data.todo.start_time), 
			start_time: moment(this.props.data.todo.start_time).format('h:mm A'),
			end_date: new Date(this.props.data.todo.end_time),
			end_time: moment(this.props.data.todo.end_time).format('h:mm A'),
			remind_time: moment(this.props.data.todo.remind_time),
			notes: convertFromRaw(JSON.parse(this.props.data.todo.notes)),
			errors: {
				name: ""
			},
			warnings: {
				start_time: ""
			}
		}

		this.all_todos = this.getAllTodos();

    	this.handleChange = this.handleChange.bind(this);
    	this.updateDate = this.updateDate.bind(this);
 		this.handleSubmit = this.handleSubmit.bind(this);
 		this.checkErrors = this.checkErrors.bind(this);
	}

	//----------------Form Info----------------------------
	handleChange(e){
		const { name, value } = e.target;
		this.setState({
			[name]: value
		});
	}

	checkWarnings = () => {
		const start_date = this.state.start_date;
		const start_time = this.state.start_time;
		let isWarning = false;
		this.all_todos.map(todo => {
			const orig_start = new Date(todo.start_time);
			const orig_end = new Date(todo.end_time);
			if(orig_start.getTime() <= this.combineDateTime(start_date, start_time).getTime() &&
				orig_end.getTime() >= this.combineDateTime(start_date, start_time).getTime()){
				isWarning = true;
				this.setState(prevState => {
					return ({

							warnings: {
								...prevState.warnings,
								start_time: true
							}});
				});
			}
			return 0;
		})
		return isWarning;
	}

	checkErrors(){
		if(this.state.name === ''){
			this.setState(prevState => {
				return {
					errors: {
						...prevState.errors,
						name: 'Name cannot be empty'
					}
				}
			});
		} else {
			this.setState(prevState => {
				return {
					errors: {
						...prevState.errors,
						name: ""
					}
				}
			})
		}
	}

	async handleSubmit(e){
		e.preventDefault();
		await this.checkErrors();
		const oldState = this.state.warnings.start_time;
		const isWarning = this.checkWarnings();
		if(isWarning && !oldState){
			return;
		}
		if(!this.state.errors.name){
			const start_date = moment(this.state.start_date).format('YYYY-MM-DD');
			const end_date = moment(this.state.end_date).format('YYYY-MM-DD');
			const todo = {
				name: this.state.name,
				description: this.state.description,
				categories: this.state.categories,
				priority: this.state.priority,
				start_time: moment(start_date+" "+this.state.start_time, 'YYYY-MM-DD h:mm A').toDate(),
				end_time: moment(end_date+" "+this.state.end_time, 'YYYY-MM-DD h:mm A').toDate(), 
				remind_time: this.state.remind_time,
				notes: JSON.stringify(convertToRaw(this.state.notes.getCurrentContent())),
				cleared: false
				//notification shows up
			};
			this.props.data.handleEdit(todo, this.props.data.todo._id);
			this.props.hideModal();
		}
	}

	//-----------------------DatePicker------------------------------

	combineDateTime = (date, time) => {
		return moment(date+" "+time, 'YYYY-MM-DD h:mm A').toDate();
	}

	updateDate(name, date){
		const d = moment(date).toDate();
		if(name==='start_date'){
			this.setState({
				[name]: d,
				end_date: d
			});
		} else {
			this.setState({
				[name]: d,
			});
		}	
		// this.setState({
		// 	[name]: date
		// });
	}

	updateTime = (name, time) => {
		this.setState({[name]: time});
	}

	getTDate = datetime => {
		let tDate = datetime.split('-');
		return new Date(tDate[0], tDate[1]-1, tDate[2]);
	}

	//-----------------------Misc------------------------------ 
	updateParent = (name, val) => {
		this.setState({[name]:val})
	}

	setWrapperRef(node){
		this.wrapperRef = node;
	}

	setEditorState = state => {
		this.setState({notes: state});
	}

	getCategories = () => {
		let categories = [];
		this.props.todos.forEach(todo => {
			todo.categories.forEach(category => {
				let res = categories.map(e => e.name);
				if(!res.includes(category.name)){
					categories.push(category);
				}
			})
		})
		return categories;
	}

	getAllTodos = () => {
		const project_todos = [];
		this.props.projects.forEach(p=>{
			if(p.events.length > 0){
				p.events.forEach(e=>project_todos.push(e));
			}
		});
		this.props.all_todos.forEach(t=>project_todos.push(t));
		return project_todos;

	}

	render() {
		return (
			<Modal
				hideModal={this.props.hideModal}
				width="65vw"
				maxWidth="900px"
				textAlign="center"
				top={true}
				topText="Edit Event"
			>
	        	<form className={styles.todoForm}>
	        		<div className={styles.nameDescription}>
		        		<input
			        		autoComplete="off"
			        		className={`${styles.todoInput} ${this.state.errors.name ? styles.error : null}`} 
			        		name="name"
			        		value={this.state.name}
			        		onChange={this.handleChange}
			        		placeholder="Name"
			        	/>
			        	<input
			        		autoComplete="off"
			        		className={`${styles.todoDescription} ${this.state.errors.description ? styles.error : null}`} 
			        		name="description"
			        		value={this.state.description} 
			        		onChange={this.handleChange}
			        		placeholder="Description"
			        	/>
		        	</div>
		        	<CategorySelector 
		        		all_categories={this.getCategories()}
		        		updateState={this.updateParent}
		        		data={this.props.data.todo.categories}
		        	/>
		        	<div className={styles.priorityCard}>
			        	<label className={styles.priorityInputContainer}>
				        	<input 
				        		type="checkbox" 
				        		checked={this.state.priority} 
				        		onChange={()=>this.setState({priority: !this.state.priority})}
				        	/>
				        	<span className={styles.priorityInput}></span>
				        	<div className={styles.priorityText}>Priority</div>
			        	</label>
		        	</div>
		        	<br />
		        	<div className={styles.leftContent}>
		        		<img 
		        			src="https://d2voyh5ncb6zec.cloudfront.net/clock.svg" 
		        			alt="time" 
		        			className={styles.clock}
		        		/>
		        		<div className={styles.timeLeft}>
		        			<h2 className={styles.subtitle}>Start</h2>
		        			<div className={styles.dateTimeInputRange}>
		        				<div className={styles.dateContainer}>
		        					<DatePicker 
		        						updateDate={this.updateDate} 
		        						name="start_date" 
		        						data={this.state.start_date}
		        					/>
		        				</div>
				        		<div className={styles.timeContainer}>
				        			<TimePicker updateTime={this.updateTime} name="start_time" data={this.state.start_time}/>
				        		</div>
		        			</div>
			        	</div>
			        	<img 
			        		src="https://d2voyh5ncb6zec.cloudfront.net/clock.svg" 
			        		alt="time" 
			        		className={`${styles.clock} ${styles.endClock}`}
			        	/>
			        	<div className={styles.timeRight}>
		        			<h2 className={styles.subtitle}>End</h2>
		        			<div className={styles.dateTimeInputRange}>
					        	<div className={styles.dateContainer}>
						        	<DatePicker 
						        		updateDate={this.updateDate} 
						        		name="end_date" 
						        		data={this.state.end_date}
						        		minDate={this.state.end_date}
						        	/>
					        	</div>
					        	<div className={styles.timeContainer}>
					        		<TimePicker updateTime={this.updateTime} name="end_time" data={this.state.end_time}/>
					        	</div>
				        	</div> 
			        	</div>
			        	<div className={styles.desktopRemind}>
			        		<RemindSelector 
			        			updateParent={this.updateDate} 
			        			name="remind_time"
			        			display={moment(this.props.data.todo.remind_time)}
			        			data={{date: moment(moment(this.state.start_date).format('YYYY-MM-DD')+" "+this.state.start_time,'YYYY-MM-DD h:mm A')}}
			        		/>
			        	</div>
			        	<div className={styles.richEditorRegion}>
			        		<RichEditor setEditorState={this.setEditorState} data={this.state.notes} height="180px"/>
			        	</div>
		        	</div>
		        	<div className={styles.availableContainer}>
		        		<CheckAvailable 
		        			all_todos={this.all_todos} 
		        			date={new Date(this.state.start_date)}
		        			current_id={this.props.data.todo._id}
		        			//current todo doesn't show up
		        		/>
		        	</div>
		        	<div className={styles.richEditorRegionMobile}>
		        		<RichEditor setEditorState={this.setEditorState} data={this.state.notes} height="180px"/>
		        	</div>
		        	<div className={styles.bottom}>
	        			<button type="button" className={styles.createButton} onClick={this.handleSubmit}>Save</button>
	        		</div>
	        	</form>
		</Modal>
		);
	}
}
