import { GET_TASKS, SET_LOADING, ADD_TASK, DELETE_TASK, EDIT_TASK } from './actionTypes';

const initialState = {
	data: [],
	isLoading: false
}
export function tasks(state=initialState, action){
	switch(action.type){
		case GET_TASKS:
			return {
				...state,
				data: action.payload
			};

		case SET_LOADING:
			return { 
				...state,
				isLoading: action.payload
			};

		case ADD_TASK:
			const data = [...state.data];
			data.push(action.payload);
			return {
				...state,
				data
			};

		case DELETE_TASK:
			const newData = state.data
				.filter(task => task._id!==action.payload)
				.map(task => task)
			return {
				...state,
				data: newData
			};

		case EDIT_TASK:
			const index = state.data.findIndex(task => task._id===action.payload._id);
			return {
				...state,
				data: Object.assign([], state.data,{[index]: action.payload})
			};

		default:
			return state;
	}
}