import axios from 'axios';

import { 
	CREATE_PROJECT, 
	DELETE_PROJECT,
	SET_PROJECTS, 
	CREATE_PROJECT_TODO, 
	DELETE_PROJECT_TODO,
	EDIT_PROJECT_TODO,
	ADD_MEMBERS,
	DELETE_MEMBER,
	CHANGE_PROJECT_NAME,
	ADD_ROADMAP_SECTION,
	EDIT_ROADMAP_SECTION,
	ADD_ROADMAP_ITEM,
	DEL_ROADMAP_SECTION,
	EDIT_ROADMAP_ITEM,
	DEL_ROADMAP_ITEM
} from './actionTypes';
import { SUCCESS, ERROR, INFO } from '../message/actionTypes';
import { PROJECT } from '../navigation/components/contentSwitcherTypes';

import { closeContext } from '../general/context_menu/actions';
import { hideModal } from '../general/modal/actions';

import { setShow } from '../navigation/actions';
import { setMessage } from '../message/actions';

const getConfig = () =>{
	return {
		headers: {
			token: localStorage.getItem('token')
		}
	};
}

export function createProject(data){
	return function(dispatch){
		return axios.post('https://taskcence.herokuapp.com/api/project/create', data, getConfig())
			.then(res=>{
				dispatch({type: CREATE_PROJECT, payload:res.data.project});
				dispatch(setMessage("Successfully created the project", SUCCESS));
				dispatch(setShow({name:PROJECT, data:res.data.project._id}))
			})
			.catch(err=>{
				console.log(err);
				dispatch(setMessage("There was an error creating the project", ERROR));
			}); 
	}
}

export function deleteProject(project_id, showMessage){
	return function(dispatch){
		dispatch({
			type: DELETE_PROJECT,
			payload: { project_id }
		});  
		if(showMessage){
			dispatch(setMessage("Deleted project", SUCCESS));
		}
	}
}

export function getProjects(){
	return function(dispatch) {
		return axios.get('https://taskcence.herokuapp.com/api/project/getProjects', getConfig()) 
			.then(res => {
				dispatch({type: SET_PROJECTS, payload: res.data.projects});
			})
			.catch(err => {
				console.log(err);
				dispatch(setMessage("An error occurred while loading projects", ERROR));
			}); 
		}
}

export function createProjectTodo(todo, data){
	const projectTodo = {
		...todo,
		project_id: data.project_id
	}; 

	return function(dispatch){
		return axios.post('https://taskcence.herokuapp.com/api/project/createTodo', projectTodo, getConfig())
			.then(res=>{
				dispatch({type:CREATE_PROJECT_TODO, payload:res.data.todo});
				dispatch(setMessage("Successfully created the event", SUCCESS));
			})
			.catch(err=>{
				console.log(err);
				dispatch(setMessage("Error while creating todo", ERROR));
			});
	}
} 

export function editProjectTodo(todo, todo_id){
	return function(dispatch){
		return axios.post('https://taskcence.herokuapp.com/api/project/editTodo/'+todo_id, todo, getConfig())
			.then(res=>{
				dispatch({type: EDIT_PROJECT_TODO, payload: res.data.todo});
				dispatch(setMessage("Edited Event", SUCCESS));
			})
			.catch(err =>{
				console.log(err);
				dispatch(setMessage("Error while editing the event", ERROR));
			});
	}
}

export function deleteProjectTodo(todo){
	return function(dispatch){
		return axios.delete('https://taskcence.herokuapp.com/api/project/deleteTodo/'+todo._id, getConfig()) 
			.then(res => {
				dispatch({type: DELETE_PROJECT_TODO, payload: {todo_id:todo._id, project_id:todo.project_id}});
				dispatch(setMessage("Deleted Event", SUCCESS));
			})
			.catch(err =>{
				console.log(err);
				dispatch(setMessage("An error occurred while deleting the event", ERROR));
			});
	}
}

export function getProjectMessages(project_id){
	return axios.get('https://taskcence.herokuapp.com/api/project/getMessages/'+project_id, getConfig())
		.then(res=>res.data.messages)
		.catch(err=>console.log(err));
}

export function addMembersToProject(members, project_id){
	const new_members = members.map(m=>m.id);
	return function(dispatch){
		return axios.post('https://taskcence.herokuapp.com/api/project/addMembers/', {
			members:new_members,
			project_id
		}, getConfig())
			.then(res=>{
				dispatch({type: ADD_MEMBERS,payload: res.data.project});
				dispatch(setMessage('Successfully added members to the project', SUCCESS));
			})
			.catch(err=>{
				console.log(err);
				dispatch(setMessage('Could not add members', ERROR));
			});
	}
}

export function removeMember(data){
	return function(dispatch){
		return axios.post('https://taskcence.herokuapp.com/api/project/removeMember/', {
			member_id: data.id,
			project_id: data.project_id
		}, getConfig())
			.then(res=>{
				dispatch({type: DELETE_MEMBER, payload: {
					member_id: data.id,
					project_id: data.project_id,
					new_members: res.data.new_members 
				}});
				dispatch(setMessage('Successfully removed the member', SUCCESS));
			})
			.catch(err=>{
				console.log(err);
				dispatch(setMessage('Could not remove the member', ERROR));
			});
	}
}

//for getting member details in a project that are not your friend
//(mutual friend added them to project)
export async function getExtraMemberDetail(id){
	const res = await axios.post('https://taskcence.herokuapp.com/api/project/getMemberDetails/', {
		id
	}, getConfig());
	return res.data;
}

export function changeProjectName(project, new_name){
	return function(dispatch){
		return axios.post('https://taskcence.herokuapp.com/api/project/changeName', {
			project,
			new_name
		}, getConfig())
			.then(res=>{
				dispatch({type:CHANGE_PROJECT_NAME, payload: {
					project_id:project._id,
					new_name
				}});
				dispatch(setMessage('Updated project name', SUCCESS));
			})
			.catch(err=>{
				console.log(err);
				dispatch(setMessage('Error updating project name', ERROR));
			});
	}
}

//--------------------------------Project Roadmap-----------------------------

//--------------------------------Roadmap Section-----------------------------
export function addRoadmapSection(section, project_id, user_id, showMessage){
	return function(dispatch){
		dispatch({type:ADD_ROADMAP_SECTION, payload:{
			section,
			project_id
		}});
		if(showMessage){
			dispatch(setMessage("Successfully created the section", SUCCESS));
		}
	}
}

export function editRoadmapSection(section, project_id, user_id, showMessage){
	return function(dispatch){
		dispatch({type:EDIT_ROADMAP_SECTION, payload: {
			section,
			project_id
		}});
		if(showMessage){
			dispatch(setMessage("Edited roadmap section", SUCCESS));
		}
	}
}

export function delRoadmapSection(section, project_id, user_id, context_menu, modal, showMessage){
	// CLOSE MODALS AND CONTEXT MENU FOR SECTION DELETE 
	// AND ITEM DELETE, SHOW MESSAGES THAT X WAS DELETED

	return function(dispatch){
		dispatch({type:DEL_ROADMAP_SECTION, payload: {
			section,
			project_id
		}});
		if(showMessage){
			dispatch(setMessage("Deleted roadmap section", SUCCESS));
		} else {
			if(context_menu.item || context_menu.section){
				 dispatch(closeContext());
				 dispatch(setMessage('Someone in the project deleted the section or item', INFO));
			}
			if(modal.parent_id || modal.roadmap_id){
				dispatch(hideModal());
				dispatch(setMessage('Someone in the project deleted the section or item', INFO))
			}

		}
	}
} 

//--------------------------------Roadmap Item-----------------------------
export function addRoadmapItem(item, user_id, showMessage){
	return function(dispatch){
		dispatch({type:ADD_ROADMAP_ITEM, payload:{
			...item
		}});
		if(showMessage){
			dispatch(setMessage("Created roadmap item", SUCCESS));
		}
	}
}

export function editRoadmapItem(item, project_id, user_id, showMessage, old_item){
	return function(dispatch){
		if(old_item.parent_id===item.parent_id){
			dispatch({type:EDIT_ROADMAP_ITEM, payload: {
				item,
				project_id
			}});
		} else {
			dispatch({type: DEL_ROADMAP_ITEM, payload: { item: old_item, project_id }});
			dispatch({type: ADD_ROADMAP_ITEM, payload: { ...item }});
		}
		
		if(showMessage){
			dispatch(setMessage("Edited roadmap item", SUCCESS));
		}
	}
}

export function delRoadmapItem(item, project_id, user_id, context_menu, modal, showMessage){
	return function(dispatch){
		dispatch({type:DEL_ROADMAP_ITEM, payload: {
			item,
			project_id
		}});
		if(showMessage){
			dispatch(setMessage("Deleted roadmap item", SUCCESS));
		} else {
			if(context_menu.item || context_menu.section){
				 dispatch(closeContext());
				 dispatch(setMessage('Someone in the project deleted the section or item', INFO));
			}
			else if(modal.parent_id || modal.roadmap_id){
				dispatch(hideModal());
				dispatch(setMessage('Someone in the project deleted the section or item', INFO))
			}
		}
	}
}

