import { SET_PROFILE_PIC, UPDATE_USER } from '../auth/actionTypes';
import { ERROR, SUCCESS } from '../message/actionTypes';

import { setMessage } from '../message/actions';

import axios from 'axios';

const getConfig = () => {
	return {
		headers: { 
			token: localStorage.getItem('token')
		}
	};
}
	
export function uploadProfilePic(file){
	return function(dispatch){
		//for register, token isn't loaded when user registers
		axios.get('https://taskcence.herokuapp.com/api/profile/upload/getURL', getConfig())
			.then(res => {
				const signedURL = res.data.signedURL;
				axios.put(signedURL, file)
					.then(res=>{
						setProfilePic(dispatch, res.data);
					})
					.catch(err=>{
						console.log(err);
						dispatch(setMessage("Error updating profile picture", ERROR));
					});
			})
			.catch(err => console.log(err));
		}
	}
	

export function getProfilePic(){
	return function(dispatch){
		axios.post('https://taskcence.herokuapp.com/api/profile/getURL',null, getConfig())
			.then(res=>{
				const signedURL = res.data.signedURL;
				axios.get(signedURL, {responseType:"arraybuffer"})
					.then(res=>{
						setProfilePic(dispatch, res.data);
					})
					.catch(err=>console.log(err));
			})
			.catch(err => console.log(err));
	}
	
}

const setProfilePic = (dispatch, file) =>{
	dispatch({type: SET_PROFILE_PIC, payload: file})
}

export function updateUser(updatedUser){
	return function(dispatch){
		return axios.post('https://taskcence.herokuapp.com/api/user/update', updatedUser, getConfig())
			.then(res=>{
				const updated = {
					name: res.data.updatedUser.name,
					email: res.data.updatedUser.email,
					disable_success:res.data.updatedUser.disable_success,
					disable_error:res.data.updatedUser.disable_error,
					disable_info:res.data.updatedUser.disable_info
				}
				dispatch({type: UPDATE_USER, payload: updated});
				dispatch(setMessage("Updated profile", SUCCESS));
			})
			.catch(err=>{
				console.log(err);
				dispatch(setMessage("Error updating profile", ERROR));
			});
	}
}