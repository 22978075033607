//----------------------------General Todo Component--------------------------
import React from 'react';
import moment from 'moment';
import styles from '../css/todo.module.css';

export default function Todo(props) {
	function formatDate(date) {
		return moment(date).format('h:mm A');
	}

	let borderColor = null;
	if(props.todo.categories && props.todo.categories.length > 0){
		borderColor = props.todo.categories[0].color;
	}  
	
	return (
		<div 
			className={styles.todo}  
			style={{borderLeft: '5px solid ' + borderColor}}
		>
			<div className={styles.contentRegion}>
				<div className={styles.todoDate}>
					<p>{formatDate(props.todo.start_time)}</p>
					<div className={styles.dash}>-</div>
					<p>{formatDate(props.todo.end_time)}</p>
				</div>
				<div className={styles.todoInfo}>
					<h2 className={styles.todoText}>{props.todo.name}</h2>
					<p className={styles.description}>{props.todo.description}</p>
				</div> 
			</div>
		</div>
	);
}
