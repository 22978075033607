import { SET_FRIENDS } from './actionTypes';

const initialState = {
	data: []
};

export function friend(state=initialState, action){
	switch(action.type){
		case SET_FRIENDS:
			return {
				...state,
				data: action.payload
			};

		default: 
			return state;
	}
}