import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { 
	HOME, 
	COMPLETED_ITEMS,
	PROFILE,   
	PROJECT 
} from '../../navigation/components/contentSwitcherTypes';
import { SIDEBAR_CONTEXT } from '../context_menu/contextTypes';
import { ADD_PROJECT } from '../modal/modalTypes';

import styles from './css/sidebar.module.css';

export default class Sidebar extends Component {
	constructor(props){
		super(props)
		this.state= {
			redirect: '',
			openProject: ''
		};
	}


	handleClick = path => {
		this.setState({redirect: <Redirect to={path}/>});
	}

	handleLogout = () => {
		localStorage.removeItem('token');
		this.setState({redirect: <Redirect to={'/login'}/>});
	}
 
	encode = buffer => {
		const d = new Uint8Array(buffer);
		const STRING_CHAR = d.reduce((data, byte)=> {
			return data + String.fromCharCode(byte);
		}, '');
		return btoa(STRING_CHAR);
	}

	handleContext = e => {
		if(this.props.setCursorPos && this.props.showContext){
			e.preventDefault();
			this.props.setCursorPos(e.pageX, e.pageY); 
			this.props.showContext(SIDEBAR_CONTEXT); 
		}
	}

	render() {
		const icons = [
			{
				src: "https://d2voyh5ncb6zec.cloudfront.net/house.svg", 
				alt: "home", 
				content:"Home", 
				show:{name:HOME}
			},
			{
				src: "https://d2voyh5ncb6zec.cloudfront.net/past.svg", 
				alt: "completed items", 
				content: "Completed & Past Items", 
				show: {name:COMPLETED_ITEMS}
			}
		]
		return (
			<div>
				{this.state.redirect?this.state.redirect:null}
				<div 
					className={styles.sidebar}
					ref={node => this.sidebarRef=node}
					onContextMenu={this.handleContext}
				>
					<div className={styles.iconRegion}>
						{icons.map((icon, index) => {
							return(
								<div 
									className={styles.iconContainer}
									style={{boxShadow: this.props.show===icon.show.name?'inset 12px -3px 0px -9px rgba(255,255,255,0.75)':null}} 
									onClick={()=>this.props.updateShow(icon.show)} 
									key={index}
								>
									<div className={styles.content}>
										<img src={icon.src} alt={icon.alt} className={styles.dashIcon}/>
										<span className={styles.text}>{icon.content}</span>
									</div>
								</div>
								);
						})}

						<div className={styles.createProjectContainer}>
							<button 
								className={styles.projectBtn}
								onClick={()=>this.props.showModal(ADD_PROJECT)}
							>
								Create Project
							</button>
						</div>

						{/*project list*/}
						<div className={styles.projectListContainer}>
							<div className={styles.projectListHeader} onClick={()=>this.setState({openProject:!this.state.openProject})}>
								<div 
									className={styles.arrowRight}
									style={{transform:this.state.openProject?'rotate(45deg)':null}}
								>
								</div>
								<p>Projects</p>	
							</div>
							<div className={styles.projectList}>
								{this.props.projects && this.state.openProject?
									this.props.projects.map(project=>
										<div 
											className={styles.project} 
											key={project._id}
											onClick={()=>this.props.updateShow({name:PROJECT,data:project._id})}
										>
											{project.name}
										</div>			
									)
								 	:null
								}
							</div>					
						</div>
						{/*end project list*/}

					</div>
					<div className={styles.bottom}>
						<div className={styles.iconContainer} onClick={()=>this.props.updateShow({name:PROFILE})}>
							<div className={styles.profileContainer}>
								<img 
									src={"data:image/jpeg;base64," +this.encode(this.props.profilePic)} 
									alt="logout" 
									className={styles.profilePic} 
								/>
								<p className={styles.bottomText}>{this.props.username}</p>
							</div>	
						</div>
						<div className={styles.iconContainer} onClick={this.handleLogout}>
							<div className={styles.bottomContent}>
								<img 
									src="https://d2voyh5ncb6zec.cloudfront.net/logout.svg" 
									alt="logout" 
									className={styles.dashIconSmall} 
								/>
								<p className={styles.bottomText}>Logout</p>
							</div>	
						</div>
					</div>
				</div>
			</div>
		);
	}
}
