import { SET_ERROR, RESET_ERRORS } from './actionTypes';

const initialState = {
	errors: {}
};

export function errors(state=initialState, action){
	switch(action.type){
		case SET_ERROR:
			return {
				...state,
				[action.payload.name]: action.payload.err

			};
		case RESET_ERRORS:
			return {};
		default:
			return state;
	}
}