import React, { Component } from 'react';

import styles from '../css/projecthome.module.css';

export default class MentionSelector extends Component {
	renderMembers = text => {
		return this.props.matchedMembers.map(member=>{
			return(
				<div 
					className={styles.memberCard} 
					key={member.id} 
					style={{cursor: 'pointer'}}
					onClick={()=>this.props.insertMention(member)} 
					//bug with target in slate editor, only clicking on photo triggers event (stopPropagation)
				>
					<img 
						src={member.profile_pic}
						className={styles.profilePic}
						alt="profile"
					/>
					<p className={styles.memberName}>{member.username}&nbsp;({member.name})</p>
				</div>
			);
		});
	}
 
	render() {
		const members = this.renderMembers(this.props.text);
		return (
			<div>
				{members}
			</div>
		);
	}
}
