import React, { Component } from 'react';
import moment from 'moment';
import ReactTooltip from "react-tooltip";

import { TODO_CONTEXT, TASK_CONTEXT, TODO_INFO, TASK_INFO } from '../context_menu/contextTypes';

import styles from './css/todolist.module.css';


export default class TodoList extends Component {
	constructor(props){
		super(props);
		this.state = {
			todoOpen: false,
			taskOpen: false
		}; 
	}

	handleContext = (e, item) => {
		e.preventDefault();
		this.props.setCursorPos(e.pageX, e.pageY);
		if(item.start_time){
			this.props.showContext(TODO_CONTEXT, { 
				todo:item,
				handleEdit: this.props.editTodo,
				handleDelete: this.props.deleteTodo 
			});
		} else {
			this.props.showContext(TASK_CONTEXT, item) 
		}
	}

	handleInfo = (e, item) => {
		this.props.setCursorPos(e.pageX, e.pageY);
		if(item.start_time){
			this.props.showContext(TODO_INFO, item);
		} else {
			this.props.showContext(TASK_INFO, item)
		}
	}

	handleComplete = item => {
		const updated = {
			complete: !item.complete
		};
		if(item.due_time){
			this.props.editTask(updated, item._id);
		} else {
			this.props.editTodo(updated, item._id);
		}
	}

	render() {
		const now = moment();
		let active_todos = [];
		this.props.todos.filter(todo=>{
			if(todo.complete){return false}
			const time = moment(todo.start_time);
			return !now.isSame(time, 'day');
		}).forEach(todo => {
			const time = moment(todo.start_time);
			if(time.isAfter(now, 'minute')){active_todos.push(todo)}
		});

		const todos = active_todos.sort((a,b)=>{
			const a_diff = moment(a.start_time).diff(now, 'minute');
			const b_diff = moment(b.start_time).diff(now, 'minute')

			if(a_diff<b_diff){
				return -1;
			} else if(a_diff>b_diff){
				return 1;
			}
			return 0;
		});

		let active_tasks = [];
		this.props.tasks.filter(task => {
			if(task.complete){return false}
			const time = moment(task.due_time);
			return !now.isSame(time, 'day');
		}).forEach(task => {
			const time = moment(task.due_time);
			if(time.isAfter(now, 'minute')){active_tasks.push(task)}
		});

		const tasks = active_tasks.sort((a,b)=>{
			const a_diff = moment(a.due_time).diff(now, 'minute');
			const b_diff = moment(b.due_time).diff(now, 'minute')

			if(a_diff<b_diff){
				return -1;
			} else if(a_diff>b_diff){
				return 1;
			}
			return 0;
		});
		


		const todoList = todos.slice(0,5).map(todo => {
			return (
				<div 
					className={`${styles.collapsibleItem} ${styles.item}`} 
					key={todo._id} 
					onContextMenu={e=>this.handleContext(e, todo)}
					onClick={e=>this.handleInfo(e, todo)}
				>
					<div className={styles.content}>
						<p className={styles.date}>{moment(todo.start_time).format('M/D')}</p>
						<div>
							<p className={styles.name}>{todo.name}</p>
							<div className={styles.categoryContainer}>
								{todo.categories.map((category, index)=>{
									return(
										<div className={styles.category} key={index}>
											<span style={{color:category.color, fontWeight:'bold'}}>#&nbsp;</span>
											<span>{category.name}</span>
										</div>
									);	
								})} 
							</div>
						</div>
					</div>
					<div 
						className={styles.completeBtn} 
						data-tip
						data-for="markComplete"
						onClick={e=>{
							e.stopPropagation();
							this.handleComplete(todo);
						}}
					>
						<div className={styles.checkStem}></div>
						<div className={styles.checkKick}></div>
					</div>
					<ReactTooltip id="markComplete" className={styles.tooltip}>
						<span>Mark as {todo.complete?'uncomplete':'complete'}</span>
					</ReactTooltip>
				</div>
			);
		});

		const taskList = tasks.slice(0,5).map(task => {
			return(
				<div 
					className={`${styles.collapsibleItem} ${styles.item}`} 
					key={task._id} 
					onContextMenu={e=>this.handleContext(e, task)}
					onClick={e=>this.handleInfo(e, task)}
				>
					<div className={styles.content}>
						<p className={styles.date}>{moment(task.due_time).format('M/D')}</p>
						<div>
							<p className={styles.name}>{task.name}</p>
							<div className={styles.categoryContainer}>
								{task.categories.map((category, index)=>{
									return(
										<div className={styles.category} key={index}>
											<span style={{color:category.color, fontWeight:'bold'}}>#&nbsp;</span>
											<span>{category.name}</span>
										</div>
									);	
								})}
							</div>
						</div>
					</div>
					<div 
						className={styles.completeBtn} 
						data-tip
						data-for="markComplete"
						onClick={e=>{
							e.stopPropagation();
							this.handleComplete(task);
						}}
					>
						<div className={styles.checkStem}></div>
						<div className={styles.checkKick}></div>
					</div>
					<ReactTooltip id="markComplete" className={styles.tooltip}>
						<span>Mark as {task.complete?'uncomplete':'complete'}</span>
					</ReactTooltip>
				</div>
			);
		});

		return (
			<div className={styles.todoContainer}>
				<div className={styles.top}>
					<p>Upcoming</p>
				</div>
				<div className={styles.collapsible}>
					<div 
						className={styles.collapsibleTitle}
						onClick={()=>this.setState({todoOpen:!this.state.todoOpen})}
					>
						<div 
							className={styles.arrowRight}
							style={{transform:this.state.todoOpen?'rotate(90deg)':null}}
						>
						</div>
						<p className={styles.title}>Events</p>
					</div>
					<div 
						className={styles.collapsibleContent} 
					>
						{this.state.todoOpen?todoList:null}
					</div>
				</div>

				<div className={styles.collapsible}>
					<div 
						className={styles.collapsibleTitle} 
						onClick={()=>this.setState({taskOpen:!this.state.taskOpen})}
					>
						<div 
							className={styles.arrowRight}
							style={{transform:this.state.taskOpen?'rotate(90deg)':null}}
						>
						</div>
						<p className={styles.title}>Tasks</p>
					</div>
					<div 
						className={styles.collapsibleContent} 
					>
						{this.state.taskOpen?taskList:null}
					</div>
				</div>
			</div>
		);
	}
}

