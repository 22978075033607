import React  from 'react';
import Register from './auth/components/Register';
import Login from './auth/components/Login';
import Dashboard from './general/components/Dashboard';
import ProtectedRoute from './general/components/ProtectedRoute';
import LandingPage from './general/landing/LandingPage';
import Support from './general/landing/Support';
import Pricing from './general/landing/Pricing';
import Blog from './general/landing/Blog';
import NotFound from './general/landing/NotFound';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";



function App() {
  return ( 
  	<Router>
    	<Switch>
    		<Route exact path="/"><LandingPage /></Route>
    		<Route path="/register" component={Register} />
    		<Route path="/login" component={Login} />
        <Route path="/support" component={Support}/>
        <Route path="/pricing" component={Pricing}/>
        <Route path="/blog" component={Blog}/>
        <ProtectedRoute path="/dashboard" component={Dashboard}/>
        <Route exact path="*" component={NotFound}/>
    	</Switch>
  	</Router>
    
  );
}

export default App;
