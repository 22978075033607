import { SET_MESSAGE, DELETE_MESSAGE } from './actionTypes';

const initialState = {
	messageType: "",
	message: "",
};

export function message(state=initialState, action){
	switch(action.type){
		case SET_MESSAGE:
			return {
				...state,
				messageType: action.payload.messageType,
				message: action.payload.message
			}

		case DELETE_MESSAGE:
			return initialState;

		default:
			return state;
	}
}