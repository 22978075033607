import React, { Component } from 'react';

import DashTodo from '../../todo/components/DashTodo';
import Task from '../../task/components/Task';

import { PROJECT } from '../../navigation/components/contentSwitcherTypes';

import styles from './css/searchbar.module.css';

export default class SearchBar extends Component {
	constructor(props){
		super(props);
		this.state = {
			text: "",
			loading: false,
			results: []
		};
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside = e =>{
		if(this.wrapperRef && !this.wrapperRef.contains(e.target)){
			this.setState({text: ''})
		}
	}

	componentDidUpdate(prevProps, prevState){
		if(prevState.text!==this.state.text 
			|| prevProps.todos!==this.props.todos
			|| prevProps.tasks!==this.props.tasks
			|| prevProps.projects!==this.props.projects
		){
			this.getResults();
		}
	}

	handleChange = (e) => {
		const { name, value } = e.target;
		this.setState({[name]: value});
	}

	isIn = (search, iterable) => {
		return iterable.indexOf(search) > -1
	}

	getResults = async () => {
		await this.setState({loading: true});
		let results = [];
		const search = this.state.text.toLowerCase();
		this.props.todos.forEach(todo=>{
			if(this.isIn(search, todo.name.toLowerCase()) || this.isIn(search, todo.description.toLowerCase()))
				results.push(todo);
		});

		this.props.tasks.forEach(task=>{
			if(this.isIn(search, task.name.toLowerCase()) || this.isIn(search, task.description.toLowerCase()))
				results.push(task);
		});

		this.props.projects.forEach(project=>{
			if(this.isIn(search, project.name.toLowerCase()))
				results.push(project);

			project.events.forEach(e=>{
				if(this.isIn(search, e.name.toLowerCase())) results.push(e);
			});

			project.roadmap.items.forEach(i=>{
				if(this.isIn(search, i.name.toLowerCase())) results.push({...i, project_id: project._id});
			});

			project.roadmap.sections.forEach(s=>{
				s.items.forEach(i=>{
					if(this.isIn(search, i.name.toLowerCase())) results.push({...i, project_id: project._id});
				});
			});
		});

		this.setState({loading: false, results});
	}

	renderResults = results => {
		return results.map(result=>{
			if(result.project_id && (result.complete===false || result.complete===true)){
				return(
					<div 
						className={styles.projectContainer}
						key={result._id}
						onClick={()=>{
							this.props.setShow({name:PROJECT, data:result.project_id});
							this.setState({text:''});
						}}
					>
						<p className={styles.sub}>Project Event</p>
						<p className={styles.title}>{result.name}</p>
					</div>
				);
			} else if(result.parent_id){
				return(
					<div 
						className={styles.projectContainer}
						key={result._id}
						onClick={()=>{
							this.props.setShow({name:PROJECT, data:result.project_id});
							this.setState({text:''});
						}}
					>
						<p className={styles.sub}>Roadmap Item</p>
						<p className={styles.title}>{result.name}</p>
					</div>
				);
			} else if(result.start_time){
				return(
					<DashTodo 
						todo={result}
						handleDelete={this.props.deleteTodo}
						handleEdit={this.props.editTodo}
						setCursorPos={this.props.setCursorPos}
						showContext={this.props.showContext}
						key={result._id}
					/>
				);
			} else if(result.due_time){
				return(
					<Task 
						task={result}
						setCursorPos={this.props.setCursorPos}
						showContext={this.props.showContext}
						editTask={this.props.editTask}
						key={result._id}
					/>
				);
			} else if(result.members){
				return(
					<div 
						className={styles.projectContainer} 
						key={result._id}
						onClick={()=>{
							this.props.setShow({name:PROJECT, data:result._id});
							this.setState({text:''});
						}}
					>
						<p className={styles.sub}>project</p>
						<p className={styles.title}>{result.name}</p>
					</div>
				);
			}
			return null;
		});
	}

	render() {
		return (
			<div className={styles.searchBarContainer}>
				<label className={styles.insetTxt}>Search</label>
				<img 
					className={styles.searchImg} 
					src="https://d2voyh5ncb6zec.cloudfront.net/search.svg"
					alt="search" 
				/>
				<input 
					className={styles.searchBar}
					placeholder="in projects, events, tasks, etc."
					onChange={this.handleChange}
					value={this.state.text}
					autoComplete="off"
					name="text"
				/>
				<div className={styles.resultsContainer} ref={node=>this.wrapperRef=node}>
					{this.state.loading?
						<div>
							loading...
						</div>
						:this.state.text?<div className={styles.resultContainer}>
							{this.renderResults(this.state.results)}
						</div>
						:null
					}
				</div>
			</div>
		);
	}
}
