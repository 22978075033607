import React, { Component } from 'react';

import styles from './css/occuringselector.module.css';

export default class OccuringSelector extends Component {
	constructor(props){
		super(props);
		this.state = {
			reoccuring: this.props.reoccuring,
			reoccuringNum: this.props.reoccuringNum || '',
			reoccuringUnit: this.props.reoccurUnit || DAY,
			showReoccuringUnits: false,
		};
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);;
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside = e =>{
		if(this.wrapperRef && !this.wrapperRef.contains(e.target)){
			this.setState({showReoccuringUnits: false});
		}
	}

	handleChange = (e, minValue) =>{
		const { name, value } = e.target;

		this.setState({
			[name]:value 
		});

		const showError = minValue >
			this.props.getDaysFromUnit(value, this.state.reoccuringUnit);

		this.props.setReoccuringDays(value);
		if(showError) return this.props.setError(true);
		else this.props.setError(false);
	}

	onKeyDown = e => {
		const allowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'];
		if(allowed.indexOf(e.key)===-1){
			e.preventDefault();
			return;
		} 
	}

	handleChecked = () => {
		this.setState({reoccuring: !this.state.reoccuring});
		this.props.setReoccuring(!this.state.reoccuring);
	}

	calcMinRepeat = (event_start, event_end) => {
		const s = event_start.startOf('day');
		const e = event_end.startOf('day');
		const diff = Math.abs(s.diff(e, 'days'));
		return diff+1;
	}


	render() { 
		const minRepeat = this.calcMinRepeat(this.props.start_date, this.props.end_date);
		const inputNum = this.state.reoccuringNum;
		const showError = minRepeat>this.props.getDaysFromUnit(
			this.state.reoccuringNum,
			this.state.reoccuringUnit
		);

		return (
			<div>
				<div className={styles.reoccuringCard}>
		        	<label className={styles.reoccuringInputContainer}>
			        	<input 
			        		type="checkbox" 
			        		checked={this.state.reoccuring}  
			        		onChange={this.handleChecked}
			        	/>
			        	<span className={styles.reoccuringInput}></span>
			        	<div className={styles.reoccuringText}>Repeat</div>
		        	</label> 
	        	</div>

	        	{this.state.reoccuring?
	        		<div className={styles.container}>
	        			<div className={styles.input}>
	        				Repeat every&nbsp;
	        				<input 
	        					className={styles.numInput}
	        					style={{borderBottom: showError?'1px solid red':''}}
	        					placeholder="#"
	        					onChange={e=>this.handleChange(e, minRepeat)}
	        					name="reoccuringNum"
	        					value={this.state.reoccuringNum ===-1?inputNum:this.state.reoccuringNum}
	        					onKeyDown={this.onKeyDown}
	        					autoComplete="off"
	        				/>
	        				<div className={styles.unitContainer}>
	        					{this.state.showReoccuringUnits?
	        						<div className={styles.unitList} ref={node=>this.wrapperRef=node}>
	        							{reoccuringUnits.map(u=>
	        								<div 
	        									className={styles.item}
	        									key={u}
	        									onClick={()=>{
	        										this.setState(prevState=>{
		        										return {
		        											reoccuringUnit: u,
		        											showReoccuringUnits: !prevState.showReoccuringUnits
		        										};
		        									});
		        									this.props.setReoccuringUnit(u);
		        								}}
	        								>
	        									{u.toLowerCase()+'(s)'}
	        								</div>
	        							)}
	        						</div>
	        						:null
	        					}
	        				</div>
	        				&nbsp;
	        				<span 
	        					onClick={()=>this.setState(prevState=>{
	        						return {
	        							showReoccuringUnits: !prevState.showReoccuringUnits
	        						};
	        					})}
	        					className={styles.unit}
	        				>
	        					{this.state.reoccuringUnit.toLowerCase()+'(s)'}
	        				</span>
	        			</div>
	        			{showError?
	        				<div className={styles.error}>
	        					Reoccuring days cannot be empty and cannot be shorter 
	        					than the event length
	        				</div>
	        				:null
	        			}
	        		</div>
	        		:null
	        	}
			</div>
		);
	}
}

const DAY = "DAY";
const WEEK = "WEEK";
const MONTH = "MONTH";

const reoccuringUnits = [
	DAY,
	WEEK,
	MONTH
];

