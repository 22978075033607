// ------------------------ Todo Component for Dashboard Only -----------------------
import React from 'react';
import styles from '../css/dashtodo.module.css';
import moment from 'moment';
import ReactTooltip from "react-tooltip";

import { TODO_CONTEXT, TODO_INFO } from '../../general/context_menu/contextTypes';

export default function DashTodo(props) {
	function showContext(e){
		e.preventDefault();
		if(props.setCursorPos && props.showContext){
			props.setCursorPos(e.pageX, e.pageY);
			props.showContext(TODO_CONTEXT, {
				todo:props.todo,
				handleEdit: props.handleEdit,
				handleDelete: props.handleDelete
			}); 
		}
	}

	function onClick(e){
		if(props.setCursorPos && props.showContext){
			props.setCursorPos(e.pageX, e.pageY); 
			props.showContext(TODO_INFO, props.todo); 
		}
	}

	const getCategories = () => {
		const categories = props.todo.categories.map((category, index) => {
			return(
				<div className={styles.category} key={index}>
					<span style={{color:category.color, fontWeight:'bold'}}>#&nbsp;</span>
					<span>{category.name}</span>
				</div>
			);
		}); 
		return categories;
	}

	const handleComplete = () => {
		const { todo } = props;
		const updated = {
			complete: !todo.complete
		};
		props.handleEdit(updated, todo._id);
	}

	const getDateText = (todoDate, end_time) => {
	 	const date = moment(todoDate);
	 	const today = moment();
	 	const minuteDiff = date.diff(today, 'minute');
	 	const hourDiff = date.diff(today, 'hour');
	 	const dayDiff = date.diff(today, 'day');
	 	if(today.isBetween(todoDate, end_time)){
	 		return <p className={styles.ongoing}>on going</p>
	 	} else if(minuteDiff<0){
	 		return <p className={styles.past}>over</p>;
	 	} else if(date.isSame(today, 'minute')){
			return <p className={styles.now}>now</p>;
		} else if(minuteDiff<60){
			return <p className={styles.date}>in<span className={styles.dateNum}>{` ${minuteDiff} `}</span>mins</p>;
		} else if(hourDiff<24){ 
			return <p className={styles.date}>in<span className={styles.dateNum}>{` ${hourDiff} `}</span>hrs</p>;
		} else if(dayDiff<30){
			return <p className={styles.date}>in<span className={styles.dateNum}>{` ${dayDiff} `}</span>days</p>;
		} else {
			return <p className={styles.date}>on<span className={styles.dateNum}>{` ${date.format('M/D')} `}</span></p>;
		}
	}	

	return (
		<div 
			className={styles.todo}  
			onContextMenu={showContext}
			onClick={onClick}
		>
			<div className={styles.contentRegion}>
				<div className={styles.dateRegion}>
					{getDateText(props.todo.start_time, props.todo.end_time)}
				</div>
				<div className={styles.todoMain}>
					<h2 className={styles.todoText}>{props.todo.name}</h2>
					<div className={styles.todoInfo}>
						<p className={styles.todoDescription}>{props.todo.description}</p>
					 	{props.todo.categories && props.todo.categories.length>=0 ? 
							<div className={styles.categoryContainer}>
								{getCategories()}
							</div> 
							: null
						}
					</div>
				</div>
			</div>
			<div 
				className={styles.completeBtn} 
				data-tip
				data-for={props.todo._id}
				onClick={e=>{
					e.stopPropagation();
					handleComplete();
				}}
			>
				<div className={styles.checkStem}></div>
				<div className={styles.checkKick}></div>
			</div>
			<ReactTooltip id={props.todo._id} className={styles.tooltip}>
				<span>Mark as {props.todo.complete?'uncomplete':'complete'}</span>
			</ReactTooltip>
		</div>
	);
} 