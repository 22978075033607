import React, { Component } from 'react';

import { HOME, COMPLETED_ITEMS, PROFILE, PROJECT } from './contentSwitcherTypes';

import Home from '../../general/components/Home';
import CompletedItems from '../../general/components/CompletedItems';
import Profile from '../../user/components/Profile';
import ProjectManager from '../../project/components/main/ProjectManager';

export default class ContentSwitcher extends Component {
	render() {
		switch(this.props.show){
			default:
			case HOME:
				return(
					<Home 
						showModal={this.props.showModal}
						todos={this.props.todos}
						tasks={this.props.tasks}
						setCursorPos={this.props.setCursorPos}
						showContext={this.props.showContext}
						name={this.props.name}
						editTodo={this.props.editTodo}
						editTask={this.props.editTask}
						addTodo={this.props.addTodo}
						deleteTodo={this.props.deleteTodo}
					/>
				);

			case COMPLETED_ITEMS:
				return(
					<CompletedItems 
						todos={this.props.todos}
						tasks={this.props.tasks}
						setCursorPos={this.props.setCursorPos}
						showContext={this.props.showContext}
						editTodo={this.props.editTodo}
						deleteTodo={this.props.deleteTodo}
						editTask={this.props.editTask}
					/>
				);

			case PROFILE:
				return(
					<Profile />
				);

			case PROJECT:
				return(
					<ProjectManager />
				)
		}
	}
}
