import React, { Component } from 'react';
import moment from 'moment';
import { convertFromRaw } from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';

import { CONFIRM_DELETE_MODAL, ADD_ROADMAP_SECTION } from '../../../general/modal/modalTypes';

import styles from './css/sectioninfo.module.css';

export default class SectionInfo extends Component {
	constructor(props){
		super(props);
		this.state = {
			context_height: 0
		};
	} 

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
		this.setState({context_height: this.wrapperRef.clientHeight});
	}  

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside = e =>{
		if(this.wrapperRef && !this.wrapperRef.contains(e.target)){
			this.props.closeContext();
		}
	}

	handleEdit = () =>{
		this.props.showModal(ADD_ROADMAP_SECTION, {
			...this.props.data.section,
			handleSubmit: this.props.data.handleEdit,
			project_id: this.props.data.project_id,
			id: this.props.data.id,
			btnName: 'Save',
			topText: 'Edit Section'
	 	});
		this.props.closeContext();
	} 

	handleDelete = () =>{
		this.props.showModal(CONFIRM_DELETE_MODAL, {
			handleDelete: this.props.data.handleDelete,
			topText: "Delete Section",
			deleteText: "Are you sure you want to delete",
			deleteName: this.props.data.section.name,
			info: 'All items within the section will be deleted.',
			deleteBtnTxt: 'Delete',
			height: '225px',
			item: {
				section: this.props.data.section,
				project_id: this.props.data.project_id
			}

		});
		this.props.closeContext();
	}

	getNotes = () => {
		const editorState = convertFromRaw(JSON.parse(this.props.data.section.notes));
		let html = stateToHTML(editorState);
		return(html);
	}

	render() {
		let posX = this.props.cursorX;
		let posY = this.props.cursorY;
		const owner = this.props.data.owner;
		const section = this.props.data.section;
		if(this.props.cursorX+370 > window.innerWidth){
			posX = posX - (370-(window.innerWidth-posX));
		}
		if(this.props.cursorY+this.state.context_height>window.innerHeight){
			posY = posY - (this.state.context_height-(window.innerHeight-posY))
		} 

		const notes = this.getNotes();

		const pastEnd = section.items.filter(i=>{
			return moment(i.end_time).isAfter(moment(section.end_date), 'minute');
		});

		return (
			<div 
				className={styles.contextMenu} 
				ref={node=>this.wrapperRef=node}
				style={{top: posY, left: posX}}
			>
				<div className={styles.top}>
					<div className={styles.topTitle}>{this.props.data.topText}</div>
					<button className={styles.btn} onClick={this.handleEdit}>
						<img 
							src="https://d2voyh5ncb6zec.cloudfront.net/edit.svg" 
							alt="edit" 
							className={styles.btnIcon} 
						/>
					</button>
					<button className={styles.btn} onClick={this.handleDelete}>
						<img 
							src="https://d2voyh5ncb6zec.cloudfront.net/trash.svg" 
							alt="trash" 
							className={styles.btnIcon}
						/>
					</button>
				</div>
				<h2 className={styles.name}>{section.name}</h2>
				{owner?
					<div className={styles.smallInfo}>
						<p className={styles.small}>Created By:</p>
						<img 
							src={owner.profile_pic} 
							alt={owner.username} 
							className={styles.profilePic}
						/>
						<p className={styles.profileUsername}>
							{`${owner.username} (${owner.username===this.props.data.username?'You':owner.name})`}
						</p>
					</div>
					:null
				}
				<div className={styles.timeContainer}>
					<div className={styles.start}>
						<p className={styles.small}>Start</p>
						<p className={styles.date}>
							{moment(section.start_date).format('MM/D/YY')}
						</p>
						<p>{moment(section.start_date).format('ddd, MMMM Do')}</p>
					</div>
					<div className={styles.arrows}>
						<div className={styles.rightArrow}></div>
						<div className={styles.rightArrow2}></div>
					</div>
					<div className={styles.end}>
						<p className={styles.small}>End</p>
						<p className={styles.date}>
							{moment(section.end_date).format('MM/D/YY')}
						</p>
						<p>
							{moment(section.end_date).format('ddd, MMMM Do')}
						</p>
					</div>
				</div>
				{notes!=='<p><br></p>'?
					<div 
						className={styles.notes}
						dangerouslySetInnerHTML={{__html: notes}}>
					</div>
					:null
				}
				{pastEnd.length>0?
					<div className={styles.statusSection}>
						<div className={styles.status}>
							There are
							<span className={styles.pastNum}>{' '+pastEnd.length+' '}</span>
							item(s) that run past the section's end date
						</div>
					</div>
					:null
				}
				{this.props.data.num_late>0?
					<div className={styles.statusSection}>
						<div className={styles.status}>
							There are
							<span className={styles.pastNum}>{' '+this.props.data.num_late+' '}</span>
							late item(s)
						</div>
					</div>
					:null
				}
			</div>
		);
	}
}
