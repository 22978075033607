import React, { Component } from 'react';
import _ from 'lodash';

import { ROADMAP_ITEM_INFO } from '../../../general/context_menu/contextTypes';

import styles from './css/item.module.css';

export default class RoadmapItem extends Component {
	shouldComponentUpdate(nextProps, nextState) {
		if(_.isEqual(nextProps.item, this.props.item)){
			return false;
		}
		return true;
	}

	handleClick = e => {
		this.props.setCursorPos(e.pageX, e.pageY);
		this.props.showContext(ROADMAP_ITEM_INFO, {
			item:this.props.item, 
			username:this.props.username,
			editRoadmapItem: this.props.editRoadmapItem,
			delRoadmapItem: this.props.delRoadmapItem,
			project_id: this.props.project._id,
			member_details: this.props.member_details,
			project: this.props.project,
			id: this.props.id
		}); 
	}

	lightOrDark = color => {
 
	    // Variables for red, green, blue values
	    var r, g, b, hsp;
	    
	    // Check the format of the color, HEX or RGB?
	    if (color.match(/^rgb/)) {

	        // If RGB --> store the red, green, blue values in separate variables
	        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
	        
	        r = color[1];
	        g = color[2];
	        b = color[3];
	    } 
	    else {
	        
	        // If hex --> Convert it to RGB: http://gist.github.com/983661
	        color = +("0x" + color.slice(1).replace( 
	        color.length < 5 && /./g, '$&$&'));

	        r = color >> 16;
	        g = (color >> 8) & 255;
	        b = color & 255;
	    }
	    
	    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
	    hsp = Math.sqrt(
	    0.299 * (r * r) +
	    0.587 * (g * g) +
	    0.114 * (b * b)
	    );

	    // Using the HSP value, determine whether the color is light or dark
	    if (hsp>127.5) {
 
	        return 'light';
	    } 
	    else {

	        return 'dark';
	    }
	}

	render() {
		const lightdark = this.lightOrDark(this.props.item.background_color);

		return (
			<div 
				className={styles.item}
				onClick={this.handleClick}
				style={{
					width: this.props.width,
					backgroundColor:this.props.item.background_color,
					color: lightdark==='light'?'#000000':'#ffffff'
				}}
			>
				{this.props.item.late?
					<div className={styles.lateContainer}>
						{lightdark==='light'?
							<img 
								src="https://d2voyh5ncb6zec.cloudfront.net/lateBlack.svg" 
								className={styles.lateImg} 
								alt="late"
							/>
							:<img 
								src="https://d2voyh5ncb6zec.cloudfront.net/lateWhite.svg" 
								className={styles.lateImg} 
								alt="late"
							/>
						}
					</div>
					:null
				}
				<div className={styles.name}>{this.props.item.name}</div>
			</div>
		);
	}
}
