import React, { Component } from 'react';
import ReactTooltip from "react-tooltip";

import Modal from './Modal';
import AddMember from '../../misc_components/AddMember';

import styles from '../css/createproject.module.css';
import _ from 'lodash';

export default class CreateProject extends Component {
	constructor(props){
		super(props);
		this.state = {
			name: "",
			members: [],
			name_error: false
		};
	}

	handleChange = e =>{
		const { name, value } = e.target;
		this.setState({
			[name]:value
		});
	}

	addMember = member => {
		if(_.find(this.state.members, member)){return}
		this.setState(prevState=>{
			return {
				members: [
					...prevState.members,
					member
				]
			};
		})
	}

	handleDeleteMember = member => {
		const members = this.state.members.filter(m=>m.id!==member.id);
		this.setState({members});
	}


	renderMembers = () => {
		const render = this.state.members.map(member=>{
			return(
				<div className={styles.memberCard} key={member.id} data-tip data-for={member.id}>
					<img src={member.profile_pic} alt="profile" className={styles.profilePic}/>
					<p className={styles.memberName}>{member.username}</p>
					<button 
						onClick={()=>this.handleDeleteMember(member)} 
						className={styles.delete}
					>
						&times;
					</button>
					<ReactTooltip id={member.id} className={styles.tooltip}>{member.name}</ReactTooltip>
				</div>
			);
		});
		return render; 
	}

	handleSubmit = e => {
		e.preventDefault();
		if(this.state.name.length===0){
			this.setState({name_error:true});
			return;
		}

		const member_ids = this.state.members.map(member=>member.id);
		member_ids.push(this.props.id);
		const project = {
			name: this.state.name,
			members: member_ids,
			tabs: [],
		}
		this.props.createProject(project);
		this.props.hideModal();
	}

	render() {
		return (
			<Modal 
				width="45vw" 
				maxWidth="400px" 
				height="80vh" 
				maxHeight="535px"
				hideModal={this.props.hideModal}
				top={true}
				topText="Create Project"
			>
				<div className={styles.formContainer}>
					<input 
						className={`${styles.nameInput} ${this.state.name_error?styles.error:null}`}
						onChange={this.handleChange}
						value={this.state.name}
						placeholder="Give your project a name"
						name="name"
						autoComplete="off"
						maxLength="75"
					/>
					<h1 className={styles.subtitle}>Add Members:</h1>
					<div className={styles.memberList}>
						{this.renderMembers()}
					</div>
					<div className={styles.addMemberContainer}>
						<AddMember 
							updateParent={this.addMember}
							friends={this.props.friends}
						/>
					</div>
					<button className={styles.create} onClick={this.handleSubmit}>
						Create Project
					</button>
				</div>
			</Modal>
		);
	}
}
