import React, { Component } from 'react';
import _ from 'lodash';

import Message from '../general/Message';
import MessageInput from '../general/MessageInput';

import { getProjectMessages } from '../../actions';

import styles from '../css/projectchat.module.css';

export default class ProjectChat extends Component {
	constructor(props){
		super(props);
		this.state = {
			messages: [],
			typing_users: []
		};
		this.socket=this.props.socket;
	}

	componentDidMount() {
		getProjectMessages(this.props.project._id)
			.then(messages=>this.setState({messages}))
			.catch(err=>console.log(err));		

		this.socket.on('chat-receive-message', data=>{
			this.setState(prevState=>{
				return {
					messages: [
						...prevState.messages,
						data.message
					]
				};
			});
		});

		this.socket.on('chat-typing', data=>{
			if(_.find(this.state.typing_users, {id:data.content.id}) 
				|| data.content.project_id!==this.props.project._id){return}

			this.setState(prevState=>{
				return {
					typing_users: [
						...prevState.typing_users,
						data.content
					]
				};
			});

			setTimeout(()=>{
				const typing_users = this.state.typing_users.filter(u=>u.id!==data.content.id);
				this.setState({typing_users});
			}, 2000);
			
		});
	}

	componentDidUpdate(){
		if(this.containerRef){
			this.containerRef.scrollTop = this.containerRef.scrollHeight - this.containerRef.clientHeight;
		}
	}

	handleScroll = e => {
		// const bottom = (e.target.scrollHeight - e.target.scrollTop) === (e.target.clientHeight);
  //   	if(!bottom) {
  //   		console.log('kere')
  //   	}
	}

	handleTyping = () => {
		this.socket.emit('chat-typing', {
			id: this.props.id,
			username: this.props.username,
			name: this.props.name,
			project_id: this.props.project._id
		});
	}

	handleSendMsg = editorVal => {
		const content = JSON.stringify(editorVal);
		this.socket.emit('chat-send-message', {
			id: this.props.id,
			project_id: this.props.project._id,
			username:this.props.username, 
			name:this.props.name,
			sent: new Date(),
			content
		});

		this.setState(prevState=>{
			return {
				messages: [
					...prevState.messages,
					{
						id: this.props.id,
						project_id: this.props.project._id,
						username:this.props.username,
						name:this.props.name,
						sent: new Date(),
						content
					}
				],
			};
		});
	}

	renderMessages = () => {
		return this.state.messages.map((message, index)=>{
			const isUserMsg = message.id===this.props.id;
			let user = _.find(this.props.member_details, {id:message.id});
			if(isUserMsg){
				user = {
					profile_pic:this.props.profile_pic,
					username: this.props.username,
					name: this.props.name
				}
			}
			return(
				<Message 
					isUserMsg={isUserMsg}
					message={message}
					key={index}
					user={user}
					prev={this.state.messages[index-1]}
					next={this.state.messages[index+1]}
					messagesLength={this.state.messages.length}
				/> 
			);
		});
	}

	getTyping = users => {
		let t = "";
		if(users.length===1){return users[0].name+ ' is typing...'}
		users.forEach((u,i)=>{
			if(i===users.length-1 && t.indexOf(u.name)>-1){t += ('and '+u.name)}
			else if(t.indexOf(u.name)>-1){ t+= (u.name + ', ')}
		});
		return t + ' are typing..';
	}

	render() {
		const typing_text = this.state.typing_users.length>0?
		(this.state.typing_users.length>2?'multiple users are typing...':
		this.getTyping(this.state.typing_users))
		:null
		return (
			<div className={styles.chatContainer}>
				{/*Start chat area*/}
				<div className={styles.chatHeader}>
					<p>Note: Only 100 messages will be saved</p>
				</div>
				<div 
					className={styles.messagesContainer} 
					ref={node=>this.containerRef=node}
					onScroll={this.handleScroll}
				>
					{this.renderMessages()}
					<p className={styles.typing}>{typing_text}</p>
				</div>
				{/*End chat area*/}
				<div className={styles.inputContainer}>
					<MessageInput 
						handleSendMsg={this.handleSendMsg}
						handleTyping={this.handleTyping}
						member_details={this.props.member_details}
					/>
				</div>
			</div>
		);
	}
}
 