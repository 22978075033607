//----------------------Todo---------------------------
export const TODO_CONTEXT = 'TODO_CONTEXT';
export const TODO_INFO = 'TODO_INFO';

// ----------------------Tasks------------------------
export const TASK_CONTEXT = 'TASK_CONTEXT';
export const TASK_INFO = 'TASK_INFO';

// ----------------------MISC------------------------
export const SIDEBAR_CONTEXT = 'SIDEBAR_CONTEXT';


// ----------------------PROJECT------------------------
export const PROJECT_TODO_CONTEXT = 'PROJECT_TODO_CONTEXT';
export const ROADMAP_SECTION_INFO = 'ROADMAP_SECTION_INFO';
export const ROADMAP_ITEM_INFO = 'ROADMAP_ITEM_INFO';