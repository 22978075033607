import React, { Component } from 'react';
import { Text } from 'slate';

import moment from 'moment';

import styles from '../css/projectchat.module.css';

export default class Message extends Component {
	shouldComponentUpdate(nextProps, nextState) {
		//every message doesn't need to be updated, fix in future?
		if(this.props.messagesLength!==nextProps.messagesLength){return true}
		else if(this.props.message.id===nextProps.message.id){
			return false;
		} 
		return true;
	}

	renderProfile = () => {
		return(
			<img 
				src={this.props.user.profile_pic}
				alt="profile"
				className={styles.profile}
			/>
		);
	}

	renderMsg = text => {
		return(
			<div className={styles.msgContainer}>
				<div className={styles.msgLine}></div>
				<div className={styles.txtBubble}>{text}</div>
			</div>
		);
	}

	renderMsgBorder = () => {
		if(!this.props.prev){
			return this.renderMsg('Chat starts here');
		}
		const today = moment().startOf('day');
		const prevSentTime = moment(this.props.prev.sent).startOf('day');
		const thisSentTime = moment(this.props.message.sent).startOf('day');


		if(!prevSentTime.isSame(thisSentTime, 'day')){
			const daysSinceToday = Math.abs(thisSentTime.diff(today, 'days'));
			if(daysSinceToday===0){ 
				return this.renderMsg('Today');
			} else if(daysSinceToday===1){ 
				return this.renderMsg('Yesterday');
			} else {
				const date = today.subtract(daysSinceToday, 'day').format('ddd, MMM Do');
				return this.renderMsg(date);
			}
		}
		return null;
	}

	serialize = (node, i) => {
		if(Text.isText(node)){ 
			const style = {
				fontWeight: node.bold?'bold':'normal',
				fontStyle: node.italic?'italic':'normal',
				textDecoration: node.underline?'underline':'initial'
			};
			return <span style={style} key={i}>{node.text}</span>;
		}

		const children = node.children.map((n,i) => this.serialize(n, i));

		switch(node.type){
			case 'unorderedlist':
				return <ul key={i}>{children}</ul>;
			case 'orderedlist':
				return <ol key={i}>{children}</ol>;
			case 'paragraph':
				return <p key={i}>{children}</p>;
			case 'list-item':
				return <li key={i}>{children}</li>;
			case 'mention':
				return <span 
							key={i} 
							className={this.props.isUserMsg?styles.lightMention:styles.darkMention}
						>
							{'@'+node.member.username}
						</span>;
			default:
				return children;
		}
	}

	render() {
		const isUserMsg = this.props.isUserMsg;
		const showProfile = 
			(this.props.next && this.props.next.id!==this.props.message.id)
			|| (!this.props.next)
			//no profile needed if 2 messages in a row

		const content = JSON.parse(this.props.message.content).map((node, i)=>{
			return this.serialize(node, i);
		}); 

		return ( 
			<React.Fragment>
				{this.renderMsgBorder()}
				<div className={styles.messageContainer}>
					{isUserMsg?
						<div className={styles.space}></div>
					:null
					}
					{!isUserMsg && showProfile?this.renderProfile():<div className={styles.profileSpacer}></div>}
					<div 
						className={styles.message} 
					>
						<div 
							className={`${styles.messageContent} 
										${isUserMsg?styles.userMsg:""}`}
							// dangerouslySetInnerHTML={{__html: content}}
						>
							{content}
						</div>
						{showProfile?
							<p className={`${isUserMsg?styles.txtRight:""} ${styles.name}`}>
								{this.props.message.name}
							</p>
						:null}
					</div>
					{isUserMsg && showProfile?this.renderProfile():<div className={styles.profileSpacer}></div>}
				</div>
			</React.Fragment>
		);
	}
}
