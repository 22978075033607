import React from 'react';
import moment from 'moment';

import Weekview from '../misc_components/WeekView';

import styles from './css/dashboard.module.css';

export default function DashHeader(props){
	return(
		<div className={styles.header}>
			<div className={styles.welcomeContainer}>
				<div className={styles.welcome}>
					<h1>Welcome, {props.name}!</h1>
					<p className={styles.date}>Today is <span>{moment().format('dddd, MMMM Do')}</span></p>
				</div>
				<img 
					src="https://d2voyh5ncb6zec.cloudfront.net/working.png" 
					alt="working" 
					className={styles.workingImg}
				/>
			</div>
			<div className={`${styles.weekview}`}>
				<Weekview 
					all_todos={props.all_todos} 
					todayViewShowDate={props.todayViewShowDate}
					updateViewShowDate={props.updateViewShowDate}
				/>
			</div>
		</div>
	);
}