import React, { Component } from 'react';
import moment from 'moment';
//import anime from 'animejs/lib/anime.es.js';

import styles from '../css/notificationbar.module.css';

export default class Notification extends Component {

	getTime = t => {
		const now = moment();
		const time = moment(t);
		if(time.isBefore(now, 'minute')){return <p className={styles.over}>over</p>}
		if(time.isSame(now, 'minute')){return <p className={styles.time}>now</p>}
		if(now.isSame(time, 'day')){
			const minuteDiff = time.diff(now, 'minute');
			const hourDiff = time.diff(now, 'hour');
			if(minuteDiff<60){
				return <p className={styles.time}>{minuteDiff+' min'+(minuteDiff>1?'s':'')}</p>;
			} else {
				return <p className={styles.time}>{hourDiff+' hour'+(hourDiff>1?'s':'')}</p>;
			}
		} else {
			return <p className={styles.time}>{time.format('M/D')}</p>;
		}
	}

	handleDeleteNoti = (notification) => {
		// const target = document.getElementById(notification._id);
		// console.log(notification._id);
		// console.log(target);
		// anime({
		// 	targets: target,
		// 	opacity: [1,0],
		// 	duration: 500,
		// });

		// setTimeout(()=>{this.props.clearNotification(notification._id)}, 500);
		this.props.clearNotification(notification._id);
		if(notification.project_id){
			this.props.editProjectTodo({cleared:true}, notification._id);
		} else if(notification.start_time){
			this.props.editTodo({cleared: true}, notification._id);
		} else if(notification.due_time) {
			this.props.editTask({cleared: true}, notification._id);
		} 
	}

	renderTodoNoti = () => {
		let time = this.props.item.start_time;
		let color;
		if(this.props.item.due_time){time=this.props.item.due_time}
		if(this.props.item.categories.length>0){color = this.props.item.categories[0].color}

		return (
			<div 
				className={styles.notification}
				id={this.props.item._id}  
				style={{borderLeft: '5px solid '+color}}
			>
				{this.getTime(time)}
				<div className={styles.contentRegion}>
					<div className={styles.notificationTop}>
						<p className={styles.name}>{this.props.item.name}</p>
						<button 
							className={styles.notificationClose}
							onClick={()=>this.handleDeleteNoti(this.props.item)}
						>
							&times;
						</button>
					</div>
					<p className={styles.description}>{this.props.item.description}</p>
					<div className={styles.categoryContainer}>
						{this.props.item.categories.map((category, index)=>{
							return(
								<div className={styles.category} key={index}>
									<span style={{color:category.color, fontWeight:'bold'}}>#&nbsp;</span>
									<span>{category.name}</span>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	}

	renderFriendReqNoti = () => {
		return(
			<div className={styles.frNotification}>
				<p>Friend Request from</p>
				<p>
					<span className={styles.bold}>{this.props.item.from_username}</span> 
					<span>&nbsp;({this.props.item.from_name})</span>
				</p>
				<button 
					onClick={()=>this.props.handleFriend(this.props.item, 'accept')} 
					className={`${styles.frBtn} ${styles.acc} ${styles.bold}`}>
						Accept
				</button>
				<button 
					onClick={()=>this.props.handleFriend(this.props.item, 'reject')} 
					className={`${styles.frBtn} ${styles.rej} ${styles.bold}`}>
					Reject
				</button>
			</div>
		);
		
	}

	render() {
		if(this.props.item.start_time || this.props.item.due_time){
			return this.renderTodoNoti();
		}
		else if(this.props.item.from_id){
			return this.renderFriendReqNoti();
		}
		return null;
	}
}
