import React, { Component } from 'react';
import anime from 'animejs/lib/anime.es.js';

import { ADD_PROJECT } from '../../modal/modalTypes';

import styles from '../css/todocontext.module.css';

export default class SidebarContext extends Component {
	constructor(props){
		super(props);
		this.state = {
			context_height: 0
		};
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
		this.setState({context_height: this.wrapperRef.clientHeight});
		anime({
			targets: this.wrapperRef,
			width: [
            	{ value: 0, duration: 0 }, 
            	{ value: 250 }
	        ],
	        easing: 'spring(0, 80, 20, 30)' 
		});
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside = e =>{
		if(this.wrapperRef && !this.wrapperRef.contains(e.target)){
			this.props.closeContext();
		}
	}

	handleAddProject = () => {
		this.props.showModal(ADD_PROJECT);
		this.props.closeContext();
	}

	render() {
		let posX = this.props.cursorX;
		let posY = this.props.cursorY;
		if(this.props.cursorX+250 > window.innerWidth){
			posX = posX - (250-(window.innerWidth-posX));
		}
		if(this.props.cursorY+this.state.context_height>window.innerHeight){
			posY = posY - (this.state.context_height-(window.innerHeight-posY))
		} 

		return (
			<div 
				className={styles.contextMenu} 
				ref={node=>this.wrapperRef=node}
				style={{top: posY, left: posX}}
			>
				<ul>
					<li>
						<button
							onClick={this.handleAddProject}
							className={styles.contextButton}
						> 
							<p>New Project</p>
						</button>

					</li>
				</ul>
			</div>
		);
	}
}
