import { SET_SHOW } from './actionTypes';

import { HOME } from './components/contentSwitcherTypes';

const initialState = {
	show: HOME,
	data: {}
};

export function navigation(state=initialState, action){
	switch(action.type){
		case SET_SHOW:
			return {
				show: action.payload.name,
				data:action.payload.data?action.payload.data:{}
			};

		default:
			return state;
	}
}