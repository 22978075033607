import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';

import LandingTopMenu from '../../general/landing/LandingTopMenu';
import MobileMenu from '../../general/landing/MobileMenu';

import { login } from '../actions';
import { setError, resetErrors } from '../../error/actions';
 
import styles from '../css/login.module.css';
 
class Login extends Component {
	constructor(props){
		super(props);
		this.state = {
			username: "",
			password: "",
			redirect: false,
			openMobileMenu: false,
			menuClosingAnimation: false
		}
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(e){
		const { name, value } = e.target;
		this.setState({
			[name]: value
		});
	}

	checkErrors(){
		const { setError } = this.props;
		if (this.state.username===''){
			setError({'name': 'login_user', 'err':'Username cannot be blank'});
		}
		if(this.state.password===''){
			setError({'name':'login_pass','err': 'Password cannot be blank'});
		}
	}

	componentDidMount() {
		if(localStorage.getItem('token')){
			const body = {
				token: localStorage.getItem('token')
			};
			axios.post('https://taskcence.herokuapp.com/api/user/verify', body)
				.then(res => {
					if(res.data.authenticated){
						this.setState({redirect: true});
					}
				})
				.catch(err => console.log(err));
		}
	}

	async handleSubmit(e){
		e.preventDefault();
		const { resetErrors } = this.props;
		resetErrors();
		await this.checkErrors();
		if (!this.props.login_user && !this.props.login_pass) { 
			const loginReq = {
				username: this.state.username.toLowerCase(),
				password: this.state.password
			}
			const { login } = this.props;
			await login(loginReq);
			if(!this.props.login_user && !this.props.login_pass){
				this.setState({ redirect: true });
			}
		}
	}

	closeMenu = () => {
		this.setState({menuClosingAnimation: true});
		setTimeout(()=>{
			this.setState({openMobileMenu: false, menuClosingAnimation: false});
		}, 500);
			
	}

	render() {
		if(this.state.redirect){
			return (<Redirect to="/dashboard"/>);
		}
		return (
			<div className={styles.container}>
				<LandingTopMenu 
					openMenu={()=>this.setState({openMobileMenu: true})}
				/>
				<div className={styles.login}>
					<div className={styles.loginForm}>  
						<h2 className={styles.title}>Log in</h2>
						<form onSubmit={this.handleSubmit} autoComplete='on'>  
							<input
								className={this.props.login_user ? styles.error: null}   
								type="text" 
								name="username"  
								autoComplete="username"
								value={this.state.username} 
								placeholder="Username"
								onChange={this.handleChange}
							/>
							{this.props.login_user ? <p className={styles.errorMsg}>{this.props.login_user}</p> : null}
							<br />
							<input
								className={this.props.login_pass ? styles.error: null}   
								type="password" 
								name="password"
								autoComplete="current-password" 
								value={this.state.password} 
								placeholder="Password"
								onChange={this.handleChange}
							/>
							{this.props.login_pass ? <p className={styles.errorMsg}>{this.props.login_pass}</p> : null}
							<br />
							<button>Login</button>
							<span className={styles.info}>
								<p className={styles.accountMessage}>Not Registered?</p> 
								<Link to="/register">Create an account</Link>
							</span> 
						</form>
					</div>
					<div className={styles.center}>
						<img 
							src="https://d2voyh5ncb6zec.cloudfront.net/LoginBackground.png" 
							alt="work" 
							className={styles.background}
						/>
					</div>
					{this.state.openMobileMenu?
						<div className={styles.menuContainer}>
							<MobileMenu 
								closeMenu={this.closeMenu}
								closeAnimation={this.state.menuClosingAnimation}
							/>
						</div>
						:null
					}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) { 
	const { login_user, login_pass } = state.errors;
	return {
		login_user: login_user,
		login_pass: login_pass
	};
}

const mapDispatchToProps = {
	login,
	setError,
	resetErrors
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
