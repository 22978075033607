import React, { Component } from 'react';
import _ from 'lodash';
import ReactTooltip from "react-tooltip";
import moment from 'moment';

import { PROJECT_TODO_CONTEXT, TODO_INFO } from '../../../general/context_menu/contextTypes'; 

import styles from './css/projecttodo.module.css';

const now = moment();

export default class ProjectTodo extends Component {
	showContext = e =>{
		e.preventDefault();
		if(this.props.setCursorPos && this.props.showContext){
			this.props.setCursorPos(e.pageX, e.pageY);
			this.props.showContext(PROJECT_TODO_CONTEXT, {
				todo:this.props.todo,
				handleEdit: this.props.handleEdit,
				handleDelete: this.props.handleDelete,
				member_details: this.props.member_details
			}); 
		}
	} 

	onClick = e =>{
		if(this.props.setCursorPos && this.props.showContext){
			this.props.setCursorPos(e.pageX, e.pageY); 
			this.props.showContext(TODO_INFO, {...this.props.todo, member_details:this.props.member_details}); 
		}
	}

	getDateText = () => {
	 	const start_time = moment(this.props.todo.start_time);
	 	const end_time = moment(this.props.todo.end_time);
	 	const today = moment();
	 	const minuteDiff = start_time.diff(today, 'minute');
	 	const hourDiff = start_time.diff(today, 'hour');
	 	const dayDiff = start_time.diff(today, 'day');
	 	if(today.isBetween(start_time, end_time, 'minute')){
	 		return <p className={styles.ongoing}>on going</p>
	 	} else if(minuteDiff<0){
	 		return <p className={styles.past}>over</p>;
	 	} else if(start_time.isSame(today, 'minute')){
			return <p className={styles.now}>now</p>;
		} else if(minuteDiff<60){
			return <p className={styles.date}>in<span className={styles.dateNum}>{` ${minuteDiff} `}</span>mins</p>;
		} else if(hourDiff<24){ 
			return <p className={styles.date}>in<span className={styles.dateNum}>{` ${hourDiff} `}</span>hrs</p>;
		} else if(dayDiff<30){
			return <p className={styles.date}>in<span className={styles.dateNum}>{` ${dayDiff} `}</span>days</p>;
		} else {
			return <p className={styles.date}>on<span className={styles.dateNum}>{` ${start_time.format('M/D')} `}</span></p>;
		}
	}

	getShowComplete = () => {
		if(!this.props.todo.reoccuring) return true;
		const start = moment(this.props.todo.start_time);
		return now.isAfter(start);
	}

	render() {
		const showComplete = this.getShowComplete();

		return (
			<div 
				className={styles.todoContainer}
				onContextMenu={this.showContext}
				onClick={this.onClick}
			>
				<div className={styles.status}>{this.getDateText()}</div>
				<div className={styles.content}>
					<div className={styles.name}>{this.props.todo.name}</div>
					<div className={styles.description}>{this.props.todo.description}</div>
					{this.props.todo.assigned_users.length>0?
						<div className={styles.assignedContainer}>
							Assigned to:

							{this.props.todo.assigned_users.length===this.props.member_details.length?
								<div className={styles.all}>everyone</div>
								:<div className={styles.profileContainer}>
									{this.props.todo.assigned_users.map(id=>{
										const user = _.find(this.props.member_details, {id});
										if(!user) return null;
										return(
											<div key={id} data-tip data-for={id} className={styles.profile}>
												<img src={user.profile_pic} alt={user.username} className={styles.profilePic}/>
												<ReactTooltip id={id} className={styles.tooltip}>
													{user.username}&nbsp;({user.name})
												</ReactTooltip>
											</div>
										);
									})}
								</div>
							}
						</div>
					:null
					}
				</div>
				{showComplete?
					<div 
						className={styles.completeBtn} 
						data-tip
						data-for={this.props.todo._id}
						onClick={e=>{
							e.stopPropagation();
							if(this.props.todo.reoccuring) 
								this.props.handleEdit(
									{
										completed_num: this.props.todo.completed_num+1,
										last_completed: moment().toDate()
									},
									this.props.todo._id
								);
							else this.props.handleEdit({complete:!this.props.todo.complete}, this.props.todo._id);
							//handleComplete();
						}}
					>
						<div className={styles.checkStem}></div>
						<div className={styles.checkKick}></div>
						<ReactTooltip id={this.props.todo._id} className={styles.tooltip}>
							<span>Mark as {this.props.todo.complete?'uncomplete':'complete'}</span>
						</ReactTooltip>
					</div>
					:null
				}
			</div>
		);
	}
}
