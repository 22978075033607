import React, { Component } from 'react';

import styles from '../css/modal.module.css';

export default class Modal extends Component {
	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside = e =>{
		if(this.wrapperRef && !this.wrapperRef.contains(e.target)){
			this.props.hideModal();
		}
	}

	handleClose = () => {
		this.props.hideModal();
	}

	render() {
		return (
			<div className={styles.Overlay}>
				<div 
					className={styles.Modal} 
					ref={node=>this.wrapperRef=node}
					style={{
						width:this.props.width, 
						maxWidth: this.props.maxWidth, 
						minWidth: this.props.minWidth,
						height: this.props.height,
						maxHeight: this.props.maxHeight,
						textAlign: this.props.textAlign,
						padding: this.props.padding
					}}
				>
					{this.props.top?
						<div className={styles.top}>
							{this.props.topText?<span>{this.props.topText}</span>:null}
							<button onClick={this.handleClose} className={styles.modalClose}>&times;</button>
						</div>
						:null
					}
					{this.props.children}
				</div>
			</div>
		);
	}
}
