import React, { Component } from 'react';
import axios from 'axios';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import LandingTopMenu from '../../general/landing/LandingTopMenu';
import MobileMenu from '../../general/landing/MobileMenu';

import { uploadProfilePic } from '../../user/actions';
import { login } from '../actions';

import { defaultPic } from '../../general/images/defaultProfileBase64';
 
import styles from '../css/register.module.css';
 
class Register extends Component {
	constructor(){
		super();
		this.state = {
			username: '',
			email: '',
			name: '', 
			password1: '', 
			password2: '',
			errors: {
				username: '',
				email: '',
				name: '',
				password1: '',
				password2: ''
			},
			redirect: false,
			openMobileMenu: false,
			menuClosingAnimation: false
		}
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(e){
		const { name, value } = e.target;
		const regex = /^$|[a-zA-Z0-9-@.]+$/g; 
		if( (name==='username') && (!value.match(regex)) ){return}
		this.setState({
			[name]: value
		});
	}

	checkErrors(){
		let re = /\S+@\S+\.\S+/;
		if(this.state.username===''){
			this.setState(prevState => {
				return {errors: {...prevState.errors, username: 'Username cannot be blank'}}
			});
		}
		if(this.state.email==='' || !re.test(this.state.email)){
			this.setState(prevState => {
				return {errors: {...prevState.errors, email: 'Please enter a valid email'}}
			});
		}
		if(this.state.name===''){
			this.setState(prevState => {
				return {errors: {...prevState.errors, name: 'Name cannot be blank'}}
			});
		}
		if(this.state.password1.length < 6){
			this.setState(prevState => {
				return {errors: {...prevState.errors, password1: 'Passwords must be at least 6 characters in length'}}
			});
		}
		if(this.state.password2 !== this.state.password1 || this.state.password2===''){
			this.setState(prevState => {
				return {errors: {...prevState.errors, password2: 'Passwords must match'}}
			});
		}
	}

	dataURLtoFile = (dataurl, filename) => {
		let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
	}

	uploadDefault = async (id, data) => {
		const body = {
			username: data.username,
			password: data.password
		}

		await this.props.login(body);
		const file = this.dataURLtoFile(defaultPic, 'default.png');
		this.props.uploadProfilePic(file);
	}

	async handleSubmit(e){
		e.preventDefault();
		this.setState(prevState => {
			return {
				errors: {
					username: '',
					email: '',
					name: '',
					password1: '',
					password2: ''

				}
			}
		})
		await this.checkErrors();
		const { username, email, name, password1, password2 } = this.state.errors;
		if(!username && !email && !name && !password1 && !password2){
			const new_user = {
				username: this.state.username.toLowerCase().replace(/\s/g,''),
				name: this.state.name,
				email: this.state.email.toLowerCase(),
				password: this.state.password2
			}
			axios.post('https://taskcence.herokuapp.com/api/user/register', new_user)
				.then(res => {
					this.uploadDefault(res.data.user, new_user)
					this.setState({ redirect: true });
				})
				.catch(err => {
					if(err.response.data.err_user){
						this.setState(prevState => {
							return {
								errors: {
									...prevState.errors,
									username: err.response.data.err_user
								}
							};
						});
					}
					if(err.response.data.err_email){
						this.setState(prevState => {
							return {
								errors: {
									...prevState.errors,
									email: err.response.data.err_email
								}
							};
						});
					}
				});	 
		}
		
	} 

	closeMenu = () => {
		this.setState({menuClosingAnimation: true});
		setTimeout(()=>{
			this.setState({openMobileMenu: false, menuClosingAnimation: false});
		}, 500);
			
	}

	render() {
		if(this.state.redirect){
			return (<Redirect to="/login"/>);
		}
		const { username, email, name, password1, password2 } = this.state.errors;
		return (
			<div>
				<LandingTopMenu 
					openMenu={()=>this.setState({openMobileMenu: true})}
				/>
				<div className={styles.register}> 
					<div className={styles.registerForm}>
						<form onSubmit={this.handleSubmit} autoComplete='on'>
							<h2 className={styles.title}>Start managing tasks for free!</h2>
							<input  
								className={username ? styles.error : null}
								type="text" 
								name="username" 
								autoComplete='username' 
								value={this.state.username} 
								placeholder="Username"
								onChange={this.handleChange}
							/>
							{username ? <p className={styles.errorMsg}>{username}</p> : null}
							<br />
							<input
								className={name ? styles.error : null}  
								type="text" 
								name="name" 
								value={this.state.name} 
								placeholder="Name"
								onChange={this.handleChange}
							/>
							{name ? <p className={styles.errorMsg}>{name}</p> : null}
							<br />
							<input
								className={email ? styles.error : null}  
								type="text" 
								name="email"
								autoComplete='on' 
								value={this.state.email} 
								placeholder="Email"
								onChange={this.handleChange}
							/>
							{email ? <p className={styles.errorMsg}>{email}</p> : null}
							<br />
							<input
								className={password1 ? styles.error : null}   
								type="password" 
								name="password1" 
								autoComplete='new-password'
								value={this.state.password1} 
								placeholder="Password"
								onChange={this.handleChange}
							/>
							{password1 ? <p className={styles.errorMsg}>{password1}</p> : null}
							<br />
							<input 
								className={password2 ? styles.error : null} 
								type="password" 
								name="password2"
								autoComplete='new-password' 
								value={this.state.password2} 
								placeholder="Repeat Password"
								onChange={this.handleChange}
							/>
							{password2 ? <p className={styles.errorMsg}>{password2}</p> : null}
							<br />
							<button>Sign up</button>
							<span className={styles.info}>
								<p className={styles.accountMessage}>Already have an account?</p> 
								<Link to="/login">Login</Link>
							</span> 
						</form>
					</div>
					<div className={styles.center}>
						<img 
							src="https://d2voyh5ncb6zec.cloudfront.net/SignupBackground.png" 
							alt="work" 
							className={styles.background}
						/>
					</div>
					{this.state.openMobileMenu?
						<div className={styles.menuContainer}>
							<MobileMenu 
								closeMenu={this.closeMenu}
								closeAnimation={this.state.menuClosingAnimation}
							/>
						</div>
						:null
					}
				</div>
			</div>
		); 
	}
}

export default connect(null, { uploadProfilePic, login })(Register);

