import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { encode } from '../../../friend/actions';
import { showModal } from '../../../general/modal/actions';
import { setShow } from '../../../navigation/actions';
import { 
	createProjectTodo, 
	editProjectTodo, 
	deleteProjectTodo, 
	addMembersToProject,
	removeMember,
	changeProjectName,
	addRoadmapItem,
	editRoadmapItem,
	delRoadmapItem, 
	addRoadmapSection,
	delRoadmapSection,
	editRoadmapSection,
	deleteProject
} from '../../actions';
import { setCursorPos, showContext } from '../../../general/context_menu/actions';
 
import ProjectViewer from './ProjectViewer';

import styles from '../css/projectviewer.module.css';

class ProjectManager extends Component {
	// shouldComponentUpdate(nextProps, nextState) {
	// 	console.log(nextProps)
	// 	if(this.props.project.members.length===nextProps.project.members.length){
	// 		return false;
	// 	}
	// 	return true;
	// }

	render() {
		return (
			this.props.project?
				<ProjectViewer 
					project={this.props.project}
					id={this.props.id}
					showModal={this.props.showModal}
					createProjectTodo={this.props.createProjectTodo}
					setCursorPos={this.props.setCursorPos}	
					showContext={this.props.showContext}
					editProjectTodo={this.props.editProjectTodo}
					deleteProjectTodo={this.props.deleteProjectTodo}
					username={this.props.username}
					name={this.props.name} 
					profile_pic={this.props.profile_pic} 
					friends={this.props.friends}
					addMembersToProject={this.props.addMembersToProject}
					removeMember={this.props.removeMember}
					changeProjectName={this.props.changeProjectName}
					addRoadmapItem={this.props.addRoadmapItem}
					editRoadmapItem={this.props.editRoadmapItem}
					delRoadmapItem={this.props.delRoadmapItem}
					addRoadmapSection={this.props.addRoadmapSection}
					delRoadmapSection={this.props.delRoadmapSection}
					editRoadmapSection={this.props.editRoadmapSection}
					context_menu={this.props.context_menu}
					modal={this.props.modal}
					setShow={this.props.setShow}
					projects={this.props.projects}
					deleteProject={this.props.deleteProject}
				/>
				:<p className={styles.error}>There was en error loading the project.</p>
			
		);
	}
}

const mapStateToProps = state => {
	return {
		project: _.find(state.project.data, {_id:state.navigation.data}),
		id: state.auth.id,
		username:state.auth.username,
		name:state.auth.name,
		profile_pic: "data:image/jpeg;base64,"+encode(state.auth.profilePic),
		friends: state.friend.data,
		context_menu: state.context_menu.data,
		modal: state.modal.data,
		projects: state.project.data
	};
}

const mapDispatchToProps = {
	showModal,
	createProjectTodo,
	setCursorPos,
	showContext,
	editProjectTodo,
	deleteProjectTodo,
	addMembersToProject,
	removeMember,
	changeProjectName,
	addRoadmapItem,
	editRoadmapItem,
	delRoadmapItem,
	addRoadmapSection,
	delRoadmapSection,
	editRoadmapSection,
	setShow ,
	deleteProject
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectManager);
