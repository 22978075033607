import React, { Component } from 'react';
import moment from 'moment';
import { convertToRaw, convertFromRaw } from 'draft-js';

import Modal from '../../../general/modal/modals/Modal';
import DatePicker from '../../../general/misc_components/DatePicker';
import RichEditor from '../../../general/misc_components/RichEditor';

import styles from './css/addroadmapsection.module.css';

const today = moment();

export default class AddRoadmapSection extends Component {
	constructor(props){
		super(props);
		const name = this.props.data.name;
		const start_date = this.props.data.start_date;
		const end_date = this.props.data.end_date;
		const notes = this.props.data.notes;

		this.state = {
			name: name || '',
			start_date: start_date?moment(start_date):today.startOf('day'),
			end_date: end_date?moment(end_date):today.startOf('day'),
			notes: notes?convertFromRaw(JSON.parse(notes)):'',
			name_error: false
		}; 
	}

	handleChange = e => {
		const { name, value } = e.target;
		this.setState({[name]:value});
	}

	updateDate = (name, value) => {
		const date = moment(value, 'YYYY-MM-DD');
		if(name==='start_date'){
			this.setState({
				[name]: date,
				end_date: date,
			});
		} else {
			this.setState({
				[name]: date,
			});
		}	
	}

	setEditorState = state => {
		this.setState({notes: state});
	} 

	handleSubmit = () => {
		if(!this.state.name){
			return this.setState({name_error:true});
		}

		const section = {
			...this.state,
			start_date: moment(this.state.start_date, 'YYYY-MM-DD').toDate(),
			end_date: moment(this.state.end_date, 'YYYY-MM-DD').toDate(),
			notes: JSON.stringify(convertToRaw(this.state.notes.getCurrentContent())),
			owner_id: this.props.data.id,
		};
		if(this.props.data._id){
			section['section_id'] = this.props.data._id;
		}
		delete section.name_error;
		
		this.props.hideModal();
		this.props.data.handleSubmit(section, this.props.data.project_id);
	}

	render() {
		return ( 
			<Modal
				hideModal={this.props.hideModal}
				width="65vw" 
				maxWidth="900px"
				height="675px"
				textAlign="center"
				top={true}
				topText={this.props.data.topText}  
			>
				<div className={styles.form}>
					<input 
						onChange={this.handleChange}
						value={this.state.name}
						className={`${styles.nameInput} ${this.state.name_error?styles.error:''}`}
						autoComplete="off"
						placeholder="Section name"
						maxLength={100}
						name="name"
					/>
					<div className={styles.dateRegion}>
						<div className={styles.dateContainer}>
							<h2 className={styles.subtitle}>Start Date</h2>
							<DatePicker 
								updateDate={this.updateDate} 
								name="start_date"
								data={this.state.start_date.toDate()}
							/>
						</div>
						<div className={styles.dateContainer}>
							<h2 className={styles.subtitle}>End Date</h2>
							<DatePicker 
								updateDate={this.updateDate} 
								name="end_date"
								data={this.state.end_date.toDate()}
								minDate={this.state.start_date.toDate()}
							/> 
						</div>
					</div>
					<div className={styles.notesContainer}>
						<RichEditor 
							setEditorState={this.setEditorState}
							data={this.state.notes}
							height="325px"
						/>
					</div>
					<button className={styles.btn} onClick={this.handleSubmit}>
						{this.props.data.btnName}
					</button>
				</div>
			</Modal>
		);
	}
}
