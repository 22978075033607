import React, { Component } from 'react';
import { convertToRaw } from 'draft-js';
import moment from 'moment';

import styles from '../css/createmodal.module.css';

import Modal from './Modal';
import DatePicker from '../../misc_components/DatePicker';
import TimePicker from '../../misc_components/TimePicker';
import RichEditor from '../../misc_components/RichEditor';
import CheckAvailable from '../../misc_components/CheckAvailable';
import CategorySelector from '../../misc_components/CategorySelector';
import RemindSelector from '../../misc_components/RemindSelector';

import clock from '../../images/clock.svg';


export default class CreateTodoModal extends Component {
	constructor(props){ 
		super(props);
		this.today = new Date();
		this.state = {
			name: "",
			description: "",
			categories: [],
			priority: false,
			start_date: `${this.today.getFullYear()}-${this.today.getMonth()+1<10 ? '0': ''}${this.today.getMonth()+1}-${this.today.getDate()}`, 
			start_time: "00:00",
			end_date: `${this.today.getFullYear()}-${this.today.getMonth()+1<10 ? '0': ''}${this.today.getMonth()+1}-${this.today.getDate()}`,
			end_time: "00:00",
			remind_time: undefined,
			notes: "",
			errors: {
				name: ""
			},
			warnings: {
				start_time: false
			}
		};

		this.all_todos = this.getAllTodos();

    	this.handleChange = this.handleChange.bind(this);
    	this.updateDate = this.updateDate.bind(this);
 		this.handleSubmit = this.handleSubmit.bind(this);
 		this.checkErrors = this.checkErrors.bind(this);
	}

	//----------------Form Info----------------------------
	handleChange(e){
		const { name, value } = e.target;
		this.setState({
			[name]: value
		});
	}

	checkWarnings = () => {
		const { start_date, start_time } = this.state;
		let isWarning = false;
		this.all_todos.map(todo => {
			const start = moment(todo.start_time);
			const end = moment(todo.end_time);
			if(moment(start_date+" "+start_time, 'YYYY-MM-DD h:mm A').isBetween(start, end)){
				isWarning = true;
				this.setState(prevState => {
					return ({
							warnings: {
								...prevState.warnings,
								start_time: true
							}});
				});
			}
			return 0;
		})
		return isWarning;
	}

	checkErrors(){
		if(this.state.name === ''){
			this.setState(prevState => {
				return {
					errors: {
						...prevState.errors,
						name: 'Name cannot be empty'
					}
				}
			});
		} else {
			this.setState(prevState => {
				return {
					errors: {
						...prevState.errors,
						name: ""
					}
				}
			})
		}
	}

	async handleSubmit(e){
		e.preventDefault();
		await this.checkErrors();
		const oldState = this.state.warnings.start_time;
		const isWarning = this.checkWarnings();
		if(isWarning && !oldState){
			return;
		}
		if(!this.state.errors.name){
			const { start_date, start_time, end_date, end_time } = this.state;
			const todo = {
				name: this.state.name,
				description: this.state.description,
				categories: this.state.categories,
				priority: this.state.priority,
				start_time: moment(start_date+" "+start_time, 'YYYY-MM-DD h:mm A').toDate(),
				end_time: moment(end_date+" "+end_time, 'YYYY-MM-DD h:mm A').toDate(), 
				remind_time: this.state.remind_time,
				notes: JSON.stringify(convertToRaw(this.state.notes.getCurrentContent()))
			};
			this.props.data.handleAdd(todo, this.props.data);
			this.props.hideModal();
		}
	}

	//-----------------------DatePicker------------------------------

	combineDateTime = (date, time) => {
		return new Date(parseInt(date.slice(0, 4)), 
						parseInt(date.slice(5,7))-1, 
						parseInt(date.slice(8,10)), 
						parseInt(time.slice(0,2)), 
						parseInt(time.slice(3,5))

		);
	}

	updateDate(name, date){
		if(name==='start_date'){
			this.setState({
				[name]: date,
				end_date: date
			});
		} else {
			this.setState({
				[name]: date,
			});
		}	
		// this.setState({
		// 	[name]: date
		// });
	}

	getTDate = datetime => {
		let tDate = datetime.split('-');
		return new Date(tDate[0], tDate[1]-1, tDate[2]);
	}

	//-----------------------Misc------------------------------ 
	setEditorState = state => {
		this.setState({notes: state});
	}

	getCategories = () => {
		let categories = [];
		this.props.all_todos.forEach(todo => {
			todo.categories.forEach(category => {
				let res = categories.map(e => e.name);
				if(!res.includes(category.name)){
					categories.push(category);
				}
			})
		})
		return categories;
	}

	getAllTodos = () => {
		const project_todos = [];
		this.props.projects.forEach(p=>project_todos.push(p.events));
		// console.log([
		// 	...this.props.all_todos,
		// 	...project_todos
		// ])
		return [
			...this.props.all_todos,
			...project_todos
		];
	}

	render() {
		return (
			<Modal
				hideModal={this.props.hideModal}
				width="65vw"
				maxWidth="900px"
				maxHeight="760px"
				textAlign="center"
				top={true}
				topText="Create Event"
			>
	        	<form className={styles.todoForm}>
	        		<div className={styles.nameDescription}>
		        		<input
			        		autoComplete="off"
			        		className={`${styles.todoInput} ${this.state.errors.name ? styles.error : null}`} 
			        		name="name"
			        		value={this.state.name}
			        		onChange={this.handleChange}
			        		placeholder="Name"
			        	/>
			        	<input
			        		autoComplete="off"
			        		className={`${styles.todoDescription}`} 
			        		name="description"
			        		value={this.state.description} 
			        		onChange={this.handleChange}
			        		placeholder="Description"
			        	/>
		        	</div>
		        	<CategorySelector 
		        		all_categories={this.getCategories()}
		        		updateState={this.updateDate}
		        	/>
		        	<div className={styles.priorityCard}>
			        	<label className={styles.priorityInputContainer}>
				        	<input 
				        		type="checkbox" 
				        		checked={this.state.priority} 
				        		onChange={()=>this.setState({priority: !this.state.priority})}
				        	/>
				        	<span className={styles.priorityInput}></span>
				        	<div className={styles.priorityText}>Priority</div>
			        	</label>
		        	</div>
		        	<br />
		        	<div className={styles.leftContent}>
		        		<img src={clock} alt="time" className={styles.clock}/>
		        		<div className={styles.timeLeft}>
		        			<h2 className={styles.subtitle}>Start</h2>
		        			<div className={styles.dateTimeInputRange}>
		        				<div className={styles.dateContainer}>
		        					<DatePicker 
		        						updateDate={this.updateDate} 
		        						name="start_date" 
		        						warning={this.state.warnings.start_time}
		        					/>
		        				</div>
				        		<div className={styles.timeContainer}>
				        			<TimePicker 
				        				updateTime={this.updateDate}
				        				name="start_time" 
				        				warning={this.state.warnings.start_time}
				        			/>
				        		</div>
		        			</div>
			        	</div>
			        	<img src={clock} alt="time" className={`${styles.clock} ${styles.endClock}`}/>
			        	<div className={styles.timeRight}>
		        			<h2 className={styles.subtitle}>End</h2>
		        			<div className={styles.dateTimeInputRange}>
					        	<div className={styles.dateContainer}>
						        	<DatePicker 
						        		updateDate={this.updateDate} 
						        		name="end_date" 
						        		data={this.getTDate(this.state.end_date)}
						        		minDate={this.getTDate(this.state.start_date)}
						        	/>
					        	</div>
					        	<div className={styles.timeContainer}>
					        		<TimePicker 
					        			updateTime={this.updateDate} 
					        			name="end_time"
					        		/>
					        	</div>
				        	</div>
			        	</div>
			        	<div className={styles.desktopRemind}>
			        		<RemindSelector 
			        			updateParent={this.updateDate} 
			        			name="remind_time"
			        			data={{date: moment(this.state.start_date+' '+this.state.start_time, 'YYYY-MM-DD h:mm A')}}
			        		/>
			        	</div>
			        	<div className={styles.richEditorRegion}>
			        		<RichEditor setEditorState={this.setEditorState} height="180px"/>
			        	</div>
		        	</div>
		        	<div className={styles.availableContainer}>
		        		<CheckAvailable 
		        			all_todos={this.props.all_todos} 
		        			date={this.getTDate(this.state.start_date)}
		        		/>
		        	</div>
		        	<div className={styles.richEditorRegionMobile}>
		        		<RichEditor setEditorState={this.setEditorState} height="180px"/>
		        	</div>
	        		<button type="button" className={styles.createButton} onClick={this.handleSubmit}>Create Event</button>
	        	</form>
		    </Modal>
		);
	}
}
