import { 
	ADD_NOTIFICATION, 
	CLEAR_NOTIFICATION, 
	CLEAR_ALL, 
	SET_NOTIFICATIONS, 
	SHOW_NOTI_BAR,
	HIDE_NOTI_BAR
} from './actionTypes';

import _ from 'lodash';

const initialState = {
	data: [],
	show: false
};

export function notification(state=initialState, action){
	switch(action.type){
		case ADD_NOTIFICATION:
			if(_.find(state.data, {_id: action.payload._id})){
				return {
					...state,
					data: state.data
				}
			}
			return {
				...state,
				data: [...state.data, action.payload]
			};

		case CLEAR_NOTIFICATION:
			const newData = state.data.filter(notification=>notification._id!==action.payload);
			return {
				...state,
				data: newData
			};

		case CLEAR_ALL:
			return {
				...state,
				data: []
			};

		case SET_NOTIFICATIONS:
			const data = [];
			action.payload.forEach(notification=>{
				if(!_.find(data, {_id:notification._id})){
					data.push(notification);
				}
			});
			return {
				...state,
				data
			};

		case SHOW_NOTI_BAR:
			return {
				...state,
				show: true
			}

		case HIDE_NOTI_BAR:
			return {
				...state,
				show: false
			}

		default:
			return state;
	}
}