import React, { Component } from 'react';
import { connect } from 'react-redux';
import io from 'socket.io-client';

import ContextManager from '../context_menu/ContextManager';
import ModalManager from '../modal/ModalManager';
import Sidebar from './Sidebar';
import ContentSwitcher from '../../navigation/components/ContentSwitcher';
import NotificationManager from '../../notification/components/NotificationManager';
import SearchBar from '../misc_components/SearchBar';
import FriendManager from '../../friend/components/FriendManager';
import MessageManager from '../../message/components/MessageManager';

import { getTodos, editTodo, addTodo, deleteTodo } from '../../todo/actions';
import { getTasks, editTask } from '../../task/actions';
import { showModal } from '../modal/actions';
import { showContext, setCursorPos } from '../context_menu/actions';
import { showNotiBar } from '../../notification/actions';
import { getProfilePic } from '../../user/actions';
import { addNotification } from '../../notification/actions';
import { getProjects } from '../../project/actions';
import { setShow } from '../../navigation/actions';
import { getFriends } from '../../friend/actions';

import styles from './css/dashboard.module.css';

let socket;
const ENDPOINT = 'https://taskcence.herokuapp.com';

class Dashboard extends Component {
	async componentDidMount(){
		socket = io(ENDPOINT);
		socket.emit('login', {token:localStorage.getItem('token')}); //connect to socket

		socket.on('friend-request-receive', data=>{
			this.props.addNotification(data);
		});

		const { getTodos, getTasks, getProfilePic, getProjects, setShow, getFriends } = this.props;
		getTodos(localStorage.getItem('token'));
		getTasks(localStorage.getItem('token'));
		getProfilePic();
		await getFriends();
		getProjects();
		setShow(this.props.navigation);
	}

	componentWillUnmount(){
		if(socket){
			socket.emit('disconnect');
			socket.off();
		}
	}

	updateShow = val => {
		this.props.setShow(val, this.props.show);
	}

	renderNotification = () => {
		return(
			<div className={styles.notificationBtn} onClick={()=>this.props.showNotiBar()}>
				{this.props.notifications.length>0?<div className={styles.alert}></div>:null}
				<img 
					className={styles.notificationImg}
					src="https://d2voyh5ncb6zec.cloudfront.net/notification.svg" 
					alt="notifications" 
				/>
			</div>
		);	
	}

	render(){
		return (
			<div className={styles.dashboard}>
				<Sidebar 
					updateShow={this.updateShow} 
					show={this.props.show} 
					username={this.props.username}
					profilePic={this.props.profilePic}
					showContext={this.props.showContext}
					setCursorPos={this.props.setCursorPos}
					projects={this.props.projects}
					showModal={this.props.showModal}
				/>
				<div className={styles.mainContentContainer}>
					<div className={styles.mainContent}>
						<ContextManager />
						<ModalManager />
						<NotificationManager />
						<MessageManager />
						<div className={styles.main}>
							<div className={styles.mainTop}>
								<SearchBar 
									todos={this.props.todos}
									tasks={this.props.tasks}
									projects={this.props.projects}
									deleteTodo={this.props.deleteTodo}
									editTodo={this.props.editTodo}
									setCursorPos={this.props.setCursorPos}
									showContext={this.props.showContext}
									editTask={this.props.editTask}
									setShow={this.props.setShow}
								/>
								<FriendManager />
								{this.renderNotification()}
							</div>
							<ContentSwitcher 
								showModal={this.props.showModal}
								todos={this.props.todos}
								tasks={this.props.tasks}
								setCursorPos={this.props.setCursorPos}
								showContext={this.props.showContext}
								show={this.props.show}
								name={this.props.name}
								editTodo={this.props.editTodo}
								editTask={this.props.editTask}
								addTodo={this.props.addTodo}
								deleteTodo={this.props.deleteTodo}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
	
}

const mapStateToProps = state => {
	return {
		username: state.auth.username,
		name: state.auth.name,
		email: state.auth.email,
		todos: state.todos.data,
		tasks: state.tasks.data,
		notifications: state.notification.data,
		profilePic: state.auth.profilePic,
		projects: state.project.data,
		show: state.navigation.show,
		friends: state.friend.data,
		navigation: state.auth.navigation
	};
}

const mapDispatchToProps = {
	getTodos,
	showModal,
	getTasks,
	showContext,
	setCursorPos,
	showNotiBar,
	editTodo,
	editTask,
	getProfilePic,
	addNotification,
	getProjects,
	setShow,
	addTodo,
	deleteTodo,
	getFriends
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
