import React, { Component } from 'react';
import moment from 'moment';
import { convertFromRaw } from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';

import styles from '../css/todoinfo.module.css';

export default class TaskInfo extends Component {
	constructor(props){
		super(props);
		this.state = {
			context_height: 0
		};
	} 

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
		this.setState({context_height: this.wrapperRef.clientHeight});
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	setWrapperRef = node =>{
		this.wrapperRef = node;
	}

	handleClickOutside = e =>{
		if(this.wrapperRef && !this.wrapperRef.contains(e.target)){
			this.props.closeContext();
		}
	}

	getCategories = () => {
		const categories = this.props.task.categories.map((category, index) => {
			return(
				<div className={styles.category} key={index}>
					<span style={{color: category.color, fontWeight:'bold'}}>#&nbsp;</span>
					<span>{category.name}</span>
				</div>
			);
		});
		return categories;
	}

	getDate = () => {
		const due = moment(this.props.task.due_time).format('ddd M/D/YYYY h:mm A');
		return(
			<p className={styles.date}>
				{'due '+due}
			</p>
		);
	}

	getNotes = () => {
		const editorState = convertFromRaw(JSON.parse(this.props.task.notes));
		let html = stateToHTML(editorState);
		return(html);
	}

	render() {
		let posX = this.props.cursorX;
		let posY = this.props.cursorY;
		if(this.props.cursorX+400 > window.innerWidth){
			posX = posX - (400-(window.innerWidth-posX));
		}
		if(this.props.cursorY+this.state.context_height>window.innerHeight){
			posY = posY - (this.state.context_height-(window.innerHeight-posY))
		}

		const notes = this.getNotes();

		return (
			<div 
				className={styles.contextMenu} 
				ref={this.setWrapperRef}
				style={{top: posY, left: posX}}
			>
				<div className={styles.infoTop}>
					<p className={styles.title}>Task</p>
				</div>
				<div className={styles.infoContent}>
					<div className={styles.container}>
						<div className={styles.nameContainer}>
							{this.props.task.priority ? 
								<div className={styles.priority}></div>
								:null
							}
							<h1 className={styles.todoName}>{this.props.task.name}</h1>
						</div>
						<p className={styles.todoDesc}>{this.props.task.description}</p>
						{this.props.task.categories && this.props.task.categories.length>0 ? 
							<div className={styles.categoryContainer}>
								{this.getCategories()}
							</div>
							: null
						} 
					</div>
					<div className={styles.container}>
						{this.getDate()}
					</div>
					{notes!=='<p><br></p>'?
						<div 
							className={styles.container} 
							dangerouslySetInnerHTML={{__html: notes}}>
						</div>
						:null
					}
				</div>
			</div>
		);
	}
}
