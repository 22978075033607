import React, {Component} from 'react';
import styles from './css/datepicker.module.css';

let todayTimestamp = new Date().setHours(0,0,0,0);

export default class MyDatePicker extends Component {
    state = {
        getMonthDetails: []
    }

    constructor(props) {
        super(props);
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth();
        this.state = {
            year,
            month,
            selectedDay: todayTimestamp,
            monthDetails: this.getMonthDetails(year, month),
            shownDate: ""
        }

        this.setWrapperRef = this.setWrapperRef.bind(this);
    }

    componentDidMount() { 
        window.addEventListener('click', this.addBackDrop);
        if(!this.props.data){
            this.setDateToInput(this.state.selectedDay);
        } else {
            const { data } = this.props;
            const val = `${data.getFullYear()}-${data.getMonth()+1>=10 ? data.getMonth()+1 : '0'+(data.getMonth()+1)}-${data.getDate()>=10 ? data.getDate() : '0'+data.getDate()}`;
            this.setState({shownDate: val});
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.data && this.props.data && prevProps.data.getTime() !== this.props.data.getTime()){
            const { data } = this.props;
            const val = `${data.getFullYear()}-${data.getMonth()+1>=10 ? data.getMonth()+1 : '0'+(data.getMonth()+1)}-${data.getDate()>=10 ? data.getDate() : '0'+data.getDate()}`;
            this.setState({shownDate: val});
        }
    }


    componentWillUnmount() {
        window.removeEventListener('click', this.addBackDrop);
    }

    addBackDrop = e => {
        if(this.state.showDatePicker && !this.wrapperRef.contains(e.target)) {
            this.showDatePicker(false);
        }
    }

    showDatePicker = (showDatePicker=true) => {
        this.setState({ showDatePicker })
    }

    /**
     *  Core
     */

    daysMap = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    monthMap = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    
    getDayDetails =args=> {
        let date = args.index - args.firstDay; 
        let day = args.index%7;
        let prevMonth = args.month-1;
        let prevYear = args.year;
        if(prevMonth < 0) {
            prevMonth = 11;
            prevYear--;
        }
        let prevMonthNumberOfDays = this.getNumberOfDays(prevYear, prevMonth);
        let _date = (date < 0 ? prevMonthNumberOfDays+date : date % args.numberOfDays) + 1;
        let month = date < 0 ? -1 : date >= args.numberOfDays ? 1 : 0;
        let timestamp = new Date(args.year, args.month, _date).getTime();
        return {
            date: _date,
            day,
            month, 
            timestamp,
            dayString: this.daysMap[day]
        }
    }

    getNumberOfDays =(year, month)=> {
        return 40 - new Date(year, month, 40).getDate();
    }

    getMonthDetails =(year, month)=> {
        let firstDay = (new Date(year, month)).getDay();
        let numberOfDays = this.getNumberOfDays(year, month);
        let monthArray = [];
        let rows = 6;
        let currentDay = null;
        let index = 0; 
        let cols = 7;

        for(let row=0; row<rows; row++) {
            for(let col=0; col<cols; col++) { 
                currentDay = this.getDayDetails({
                    index,
                    numberOfDays,
                    firstDay,
                    year,
                    month
                });
                monthArray.push(currentDay);
                index++;
            }
        }
        return monthArray;
    }

    isCurrentDay =day=> {
        return day.timestamp === todayTimestamp;
    }

    isSelectedDay =day=> {
        return day.timestamp === this.state.selectedDay;
    }

    getDateFromDateString =dateValue=> {
        let dateData = dateValue.split('-').map(d=>parseInt(d, 10));
        if(dateData.length < 3) 
            return null;

        let year = dateData[0];
        let month = dateData[1];
        let date = dateData[2];
        return {year, month, date};
    }

    getMonthStr =month=> this.monthMap[Math.max(Math.min(11, month), 0)] || 'Month';

    getDateStringFromTimestamp =timestamp=> {
        let dateObject = new Date(timestamp);
        let month = dateObject.getMonth()+1;
        let date = dateObject.getDate();
        return dateObject.getFullYear() + '-' + (month < 10 ? '0'+month : month) + '-' + (date < 10 ? '0'+date : date);
    }

    setDate =dateData=> {
        let selectedDay = new Date(dateData.year, dateData.month-1, dateData.date).getTime();
        this.setState({ selectedDay })
        if(this.props.onChange) {
            this.props.onChange(selectedDay);
        }
    }

    updateDateFromInput =()=> {
        let dateValue = this.state.shownDate;
        let dateData = this.getDateFromDateString(dateValue);
        if(dateData !== null) { 
            this.setDate(dateData);
            this.setState({ 
                year: dateData.year, 
                month: dateData.month-1, 
                monthDetails: this.getMonthDetails(dateData.year, dateData.month-1)
            })
        }
    }

    setDateToInput = (timestamp) => {
        let dateString = this.getDateStringFromTimestamp(timestamp);
        this.setState({shownDate: dateString});
    }

    onDateClick =  async (day) => {
        await this.setState({selectedDay: day.timestamp}, ()=>this.setDateToInput(day.timestamp));
        if(this.props.onChange) {
            this.props.onChange(day.timestamp);
        }
        this.props.updateDate(this.props.name, this.state.shownDate);
        this.showDatePicker(false);
    }

    setYear =offset=> {
        let year = this.state.year + offset;
        let month = this.state.month;
        this.setState({ 
            year,
            monthDetails: this.getMonthDetails(year, month)
        })
    }

    setMonth =offset=> {
        let year = this.state.year;
        let month = this.state.month + offset;
        if(month === -1) {
            month = 11;
            year--;
        } else if(month === 12) {
            month = 0;
            year++;
        }
        this.setState({ 
            year, 
            month,
            monthDetails: this.getMonthDetails(year, month)
        })
    }

    setWrapperRef(node){
        this.wrapperRef = node;
    }

    calcDisable = day => {
        if(!this.props.minDate || this.props.stopDisable) return false;

        const day_month = this.state.month;
        const day_date = day.date;
        const day_year = this.state.year;

        const data_month = this.props.minDate.getMonth();
        const data_date = this.props.minDate.getDate();
        const data_year = this.props.minDate.getFullYear();
        
        if(day_year>data_year) return false;
        if(day_year<data_year) return true;
        if(day_month<data_month && day_year<=data_year) return true;
        if(day_date<data_date && day_month===data_month) return true;

        return false;
    }


    /**
     *  Renderers
     */

    renderCalendar() {
        let days = this.state.monthDetails.map((day, index)=> {
            return (
                <div className={`${styles.cDayContainer} ${day.month !== 0  || this.calcDisable(day)? styles.disabled : null} 
                                    ${this.isCurrentDay(day) ? styles.highlight : null} ${this.isSelectedDay(day) ? styles.highlightBlue : null}`} key={index}>
                    <div className={styles.cdcDay}>
                        <span onClick={()=>this.onDateClick(day)}>
                            {day.date}
                        </span>
                    </div>
                </div>
            )
        })

        return (
            <div className={styles.cContainer}>
                <div className={styles.ccHead}>
                    {['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].map((d,i)=><div key={i} className={styles.cchName}>{d}</div>)}
                </div>
                <div className={styles.ccBody}>
                    {days}
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className={styles.datePicker} ref={this.setWrapperRef}>
                <div className={`${styles.mdpInput} ${this.props.warning?styles.warning:null}`}  onClick={()=> this.showDatePicker(true)}>
                    <input type='date' onChange={this.updateDateFromInput} disabled="disabled" value={this.state.shownDate}/>
                    <span>
                        <img 
                            src="https://d2voyh5ncb6zec.cloudfront.net/calendar.svg"
                            alt="Open Calendar" 
                            className={styles.calendarImg}
                        />
                    </span>
                </div>
                {this.state.showDatePicker ? ( 
                    <div className={styles.mdpContainer}>
                        <div className={styles.mdpcHead}>
                            <div className={styles.mdpchButton}>
                                <div className={styles.mdpchbInner} onClick={()=> this.setYear(-1)}>
                                    <span className={styles.mdpchbiLeftArrows}></span>
                                </div>
                            </div>
                            <div className={styles.mdpchButton}>
                                <div className={styles.mdpchbInner} onClick={()=> this.setMonth(-1)}>
                                    <span className={styles.mdpchbiLeftArrow}></span>
                                </div>
                            </div>
                            <div className={styles.mdpchContainer}>
                                <div className={styles.mdpchcYear}>{this.state.year}</div>
                                <div className={styles.mdpchcMonth}>{this.getMonthStr(this.state.month)}</div>
                            </div>
                            <div className={styles.mdpchButton}>
                                <div className={styles.mdpchbInner} onClick={()=> this.setMonth(1)}>
                                    <span className={styles.mdpchbiRightArrow}></span>
                                </div>
                            </div>
                            <div className={styles.mdpchButton} onClick={()=> this.setYear(1)}>
                                <div className={styles.mdpchbInner}>
                                    <span className={styles.mdpchbiRightArrows}></span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.mdpcBody}>
                            {this.renderCalendar()}
                        </div>
                    </div>
                ) : ''}
            </div>
        )
    }

}