import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import { auth } from './auth/reducer';
import { todos } from './todo/reducer';
import { errors } from './error/reducer';
import { context_menu } from './general/context_menu/reducer';
import { modal } from './general/modal/reducer';
import { tasks } from './task/reducer';
import { notification } from './notification/reducer';
import { message } from './message/reducer';
import { project } from './project/reducer';
import { navigation } from './navigation/reducer';
import { friend } from './friend/reducer';
import thunk from 'redux-thunk';

import { HOME } from './navigation/components/contentSwitcherTypes';

const rootReducer = combineReducers({ 
	auth, 
	todos,
	tasks,
	errors,
	context_menu,
	modal,
	notification,
	message,
	project, 
	navigation,
	friend
});

const initialState = {
	auth: { 
		username: "",
		name: "",
		email: "",
		profilePic: {},
		id: '',
		navigation: {}
	},
	todos: {
		data: [],
		isLoading: false
	},
	errors: {},
	context_menu: {
		show: false,
		type: "",
		data: {},
		cursorPos: {
			cursorX: 0,
			cursorY: 0
		}
	},
	modal: {
		show: false,
		type: "",
		data: {}
	},
	notification: {
		data: [],
		show: false
	},
	message: {
		messageType: "",
		message: ""
	},
	project: {
		data: []
	},
	navigation: {
		show: HOME,
		data: {}
	},
	friend: {
		data: []
	}
}

//window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f

export const store = createStore(rootReducer, 
								initialState, 
								compose(
									applyMiddleware(thunk))
								);
 