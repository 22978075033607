import { GET_TODOS, SET_LOADING, ADD_TODO, DELETE_TODO, EDIT_TODO } from './actionTypes';
import { SUCCESS, ERROR } from '../message/actionTypes';

import axios from 'axios';

import { setMessage } from '../message/actions';


export function getTodos(token){
	let config = {
			headers: { 
				token: token
			}
		};
	
	return function(dispatch) {
		dispatch({type: SET_LOADING, payload: true})
		return axios.get('https://taskcence.herokuapp.com/api/todos', config) 
			.then(res => {
				dispatch({type: GET_TODOS, payload: res.data});
				dispatch({type: SET_LOADING, payload: false});
			})
			.catch(err =>{
				console.log(err);
				dispatch(setMessage("An error occurred while loading events", ERROR));
			}); 
		}
}

export function addTodo(todo){
	let config = {
			headers: { 
				token: localStorage.getItem('token')
			}
		};
	return function(dispatch){
		return axios.post('https://taskcence.herokuapp.com/api/todos/create', todo, config)
			.then(res => {
				dispatch({type: ADD_TODO, payload: res.data.todo});
				dispatch(setMessage("Event Successfully Created!", SUCCESS));
			})
			.catch(err =>{
				console.log(err);
				dispatch(setMessage("Error while creating event", ERROR));
			});
	}
}

export function deleteTodo(todo){
	const todo_id = todo._id;
	let config = {
		headers: {
			token: localStorage.getItem('token')
		}
	};
	return function(dispatch){
		return axios.delete('https://taskcence.herokuapp.com/api/todos/delete/'+todo_id, config) 
			.then(res => {
				dispatch({type: DELETE_TODO, payload: todo_id});
				dispatch(setMessage("Deleted Event", SUCCESS));
			})
			.catch(err =>{
				console.log(err);
				dispatch(setMessage("An error occurred while deleting the event", ERROR));
			});
	}
}

export function editTodo(todo, todo_id){
	let config = {
		headers: {
			token: localStorage.getItem('token')
		}
	};
	return function(dispatch){
		return axios.post('https://taskcence.herokuapp.com/api/todos/update/'+todo_id, todo, config)
			.then(res => {
				dispatch({type: EDIT_TODO, payload: res.data.todo});
				dispatch(setMessage("Edited Event", SUCCESS));
			})
			.catch(err => {
				console.log(err);
				dispatch(setMessage("An error occurred while editing the event", ERROR));
			}); 
	}
}


