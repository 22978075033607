import React, { Component } from 'react';
import moment from 'moment';
import { convertFromRaw } from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import _ from 'lodash';
import ReactTooltip from "react-tooltip";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import styles from '../css/todoinfo.module.css';

const now = moment();

export default class TodoInfo extends Component {
	constructor(props){
		super(props);
		this.state = {
			context_height: 0
		};

		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
		this.setState({context_height: this.wrapperRef.clientHeight});
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	setWrapperRef(node){
		this.wrapperRef = node;
	}

	handleClickOutside(e){
		if(this.wrapperRef && !this.wrapperRef.contains(e.target)){
			this.props.closeContext();
		}
	}

	getCategories = () => {
		const categories = this.props.todo.categories.map((category, index) => {
			return(
				<div className={styles.category} key={index}>
					<span style={{color: category.color, fontWeight:'bold'}}>#&nbsp;</span>
					<span>{category.name}</span>
				</div>
			);
		});
		return categories;
	}

	getMinUnitFromDays = days => {
		if(days<7) return days+" day(s)";
		else if(days<30) return Math.floor(days/7)+" week(s)";
		else if(days<365) return Math.floor(days/30) + " months(s)";
	}

	getDate = () => {
		const start = moment(this.props.todo.start_time);
		const end = moment(this.props.todo.end_time);
		if(this.props.todo.reoccuring){
			const repeat_until = moment(this.props.todo.repeat_until);
			const original_start = moment(this.props.todo.original_start);
			const isInfiniteReoccuring = original_start.isSame(repeat_until, 'day');
			const original_end = original_start.clone().add(start.diff(end, 'minute'), 'minute');
			const diff = Math.abs(original_start.diff(original_end, 'day'));

			const formatted_original_start = original_start.format('h:mm A');
			const formatted_end = end.format('h:mm A');

			return(
				<div className={styles.date}>
					<p>
						{diff===0?
							`same day, ${formatted_original_start}-${formatted_end}`
							:
							`day 0 ${formatted_original_start}- day ${diff} ${formatted_end}`
						}
					</p>
					<span>{`event occurs every ${this.getMinUnitFromDays(this.props.todo.repeat_days)}`}</span>
					<span>
						{isInfiniteReoccuring?
							` infinitely`
							:
							` from ${original_start.format('M/D/YYYY')}-${repeat_until.format('M/D/YYYY')}`
						}
					</span>
				</div>
			);
		} else if(start.isSame(end, 'days')){
			return(
				<p className={styles.date}>
					{`${start.format('ddd M/D/YYYY')} ${start.format('h:mm A')}-${end.format('h:mm A')}`}
				</p>
			);
		} else {
			return(
				<React.Fragment>
					<p className={styles.date}>{`${start.format('ddd M/D/YYYY')} ${start.format('h:mm A')} to`}</p>
					<p className={styles.date}>{`${end.format('ddd M/D/YYYY')} ${end.format('h:mm A')}`}</p>
				</React.Fragment>
			);
		}
	}

	getReoccuringEventProgress = event => {
		const start = moment(event.original_start);
		const diff = Math.abs(now.diff(start, 'day'));
		const total = Math.floor(diff/event.repeat_days);
		if(total===0) return {completed: 0, total: 0};
		return {completed: event.completed_num, total}
	}

	getNotes = () => {
		const editorState = convertFromRaw(JSON.parse(this.props.todo.notes));
		let html = stateToHTML(editorState);
		return(html);
	}

	render() {
		let posX = this.props.cursorX;
		let posY = this.props.cursorY;
		if(this.props.cursorX+400 > window.innerWidth){
			posX = posX - (400-(window.innerWidth-posX));
		}
		if(this.props.cursorY+this.state.context_height>window.innerHeight){
			posY = posY - (this.state.context_height-(window.innerHeight-posY))
		}
		const notes = this.getNotes(); 

		const progress = this.props.todo.reoccuring?
							this.getReoccuringEventProgress(this.props.todo)
							:null;

		return (
			<div 
				className={styles.contextMenu} 
				ref={this.setWrapperRef}
				style={{top: posY, left: posX}}
			>
				<div className={styles.infoTop}>
					<p className={styles.title}>Event</p>
				</div>
				<div className={styles.infoContent}>
					<div className={styles.container}>
						<div className={styles.nameContainer}>
							{this.props.todo.priority ? 
								<div className={styles.priority}></div>
								:null
							}
							<h1 className={styles.todoName}>{this.props.todo.name}</h1>
						</div>
						<p className={styles.todoDesc}>{this.props.todo.description}</p>
						{this.props.todo.categories && this.props.todo.categories.length>0 ? 
							<div className={styles.categoryContainer}>
								{this.getCategories()}
							</div>
							: null
						} 
					</div>
					<div className={styles.container}>
						{this.getDate()}
					</div>
					{this.props.todo.assigned_users && this.props.todo.assigned_users.length>0?
						<div className={styles.assignedContainer}>
							Assigned to:
							{this.props.todo.assigned_users.map(id=>{
								const user = _.find(this.props.todo.member_details, {id});
								if(!user) return null;
								return(
									<div key={user.id}>
										<img 
											src={user.profile_pic} 
											className={styles.profilePic} 
											alt={user.username}
											data-tip
											data-for={user.id}
										/>
										<ReactTooltip id={user.id}>{user.username}&nbsp;({user.name})</ReactTooltip>
									</div>
								);
							})}
						</div>
						:null
					}
					{notes!=='<p><br></p>'?
						<div 
							className={styles.container} 
							dangerouslySetInnerHTML={{__html: notes}}>
						</div>
						:null
					}
					{progress?
						<div className={styles.analysisContainer}>
							<div className={styles.graphContainer}>
								<CircularProgressbar 
									value={
										progress.total===0?
											100
											:(Math.round(progress.completed/progress.total*100))
									}
									text={`${progress.total===0?
											100
											:(Math.round(progress.completed/progress.total*100))}%`}
									styles={{
										path: {
											stroke: `rgb(29, 50, 107)`,
										},
										text: {
											fill: `rgb(29, 50, 107)`
										}
									}}
								/>
							</div>
							<div className={styles.completed}>
								{`${progress.completed}/${progress.total} completed`}
							</div>
						</div>
						:null
					}
				</div>
			</div>
		);
	}
}
