import React, { Component } from 'react';

export default class TextInput extends Component {
	constructor(props){
		super(props);
		this.state = {
			showInput: false,
			val: this.props.val
		} 
	}

	handleClickOutside = e =>{
		if(this.wrapperRef && !this.wrapperRef.contains(e.target)){
			document.removeEventListener('mousedown', this.handleClickOutside);
			this.setState({showInput: false});
			if(this.state.val.length>this.props.minLength){
				this.props.updateParent(this.props.name, this.state.val);
			} else {
				this.setState({val: this.props.val})
			}
		}
	}

	handleChange = e => {
		const { value } = e.target;
		this.setState({val:value})
	}

	handleDouble = () => {
		this.setState({showInput:true}, ()=>{
			document.addEventListener('mousedown', this.handleClickOutside);
		});
		
	}

	render() {
		return (
			this.state.showInput?
			<input 
				value={this.state.val}
				onChange={this.handleChange}
				ref={node=>this.wrapperRef=node}
				style={{
					fontSize:this.props.fontSize,
					border: 'none',
					borderBottom: '1px solid lightgray',
					outline: 'none',
					fontWeight: this.props.bold?'bold':null,
					width: this.props.width
				}}

			/>
			:
			<p 
				onDoubleClick={this.handleDouble}
				style={{
					fontSize:this.props.fontSize,
					fontWeight: this.props.bold?'bold':null
				}}
			>
				{this.props.val}
			</p>
		);
	}
}
