import { 
	SET_MESSAGE, 
	DELETE_MESSAGE,
	ERROR,
	SUCCESS,
	INFO 
} from './actionTypes';

export function setMessage(message, messageType){
	return (dispatch, getState) => {
		const user = getState().auth;
		if(user.disable_success && messageType===SUCCESS) return;
		if(user.disable_error && messageType===ERROR) return;
		if(user.disable_info && messageType===INFO) return;
 
		dispatch( {
			type: SET_MESSAGE,
			payload: {
				message,
				messageType
			}
		});
	}
}

export function deleteMessage(){
	return {
		type: DELETE_MESSAGE
	};
}
