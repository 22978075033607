import React, { Component } from 'react';
import Modal from './Modal';

import styles from '../css/confirmdelete.module.css';

export default class ConfirmDelete extends Component {
	//----------------Todo----------------------------
	handleDelete = () => {
		this.props.data.handleDelete(this.props.data.item);
		this.props.hideModal();
	}

	handleClose = () => {
		this.props.hideModal();
	}

	render() {
		return (
			<Modal
				width="250px"
				height={this.props.data.height || '175px'}
				hideModal={this.props.hideModal}
				top={false}
				textAlign="center"
				padding="10px"
			>
				<h2 className={styles.title}>{this.props.data.topText}</h2>
				<div>
					<p className={styles.sub}>{this.props.data.deleteText}</p>
					<p className={styles.todoName}>{this.props.data.deleteName}?</p>
					<p className={styles.info}>{this.props.data.info}</p>
				</div>
				<div className={styles.buttonRegion}>
					<button className={styles.deleteBtn} onClick={this.handleDelete}>
						{this.props.data.deleteBtnTxt}
					</button>
					<button className={styles.cancelBtn} onClick={this.handleClose}>Cancel</button>
				</div>
			</Modal>
		);
	}
}
