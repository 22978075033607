import React, { Component } from 'react';
import moment from 'moment';
import { convertToRaw, convertFromRaw } from 'draft-js';

import { getTime, statusTimes } from '../../../general/misc_components/times';

import Modal from '../../../general/modal/modals/Modal';
import RichEditor from '../../../general/misc_components/RichEditor';

import styles from './css/updatestatus.module.css';

export default class UpdateStatus extends Component {
	constructor(props){
		super(props);
		const current_end = moment(this.props.data.item.current_end_time);
		const end = moment(this.props.data.item.end_time);
		const isSame = current_end.isSame(end, 'minute');
		const time = !isSame?getTime(current_end, end):null;
		const notes = this.props.data.item.status_notes?
			convertFromRaw(JSON.parse(this.props.data.item.status_notes)):'';

		this.state = {
			num: time?time.num:0,
			time: time?time.time:'time',
			status: time?time.status:'status',
			notes,
			showTime: false,
			showStatus: false,
			showWarning: false
		}; 

		this.start_time = moment(this.props.data.item.start_time);
		this.end_time = end;
	}  

	onKeyDown = e => {
		const allow = ['0','1','2','3','4','5','6','7','8','9','Backspace'];
		if(allow.indexOf(e.key)===-1){
			e.preventDefault();
		}
	}

	setEditorState = state => {
		this.setState({notes: state});
	}
 
	handleChange = e => {
		const { name, value } = e.target;
		this.setState({[name]: value});
	}

	handleStatus = val => {
		this.setState({
			showStatus: false,
			status: val
		});
	}

	handleTime = val => {
		this.setState({
			showTime: false,
			time: val
		});
	}

	handleSubmit = () => {
		if(this.state.time==='time' || this.state.status==='status' || this.state.num===0){
			return this.props.hideModal();
		}

		const item_time = this.end_time;
		const item_start = this.start_time;
		const new_time = this.state.status==='late'?
			item_time.add(this.state.num, this.state.time):
			item_time.subtract(this.state.num, this.state.time);
		if(new_time.isBefore(item_start)){
			return this.setState({showWarning: true});
		}

		const updated = {
			_id:this.props.data.item._id,
			current_end_time: new_time,
			status_notes: JSON.stringify(convertToRaw(this.state.notes.getCurrentContent()))
		};
		this.props.data.editRoadmapItem(updated, this.props.data.project_id, this.props.data.item);
		this.props.hideModal(); 
	}

	render() {
		return (
			<Modal
				hideModal={this.props.hideModal}
				width="65vw" 
				minWidth="330px"
				maxWidth="900px"
				height="550px"
				textAlign="center"
				top={true}
				topText={this.props.data.topText}
			>
				<div className={styles.form}>
					<div className={styles.itemInfo}>
						<h2 className={styles.name}>{this.props.data.item.name}</h2>
						<div className={styles.times}>
							<div className={styles.startTime}>
								 {this.start_time.format('M/D/YYYY h:mm A')}
							</div> 
							<div className={styles.arrows}>
								<div className={styles.rightArrow}></div>
								<div className={styles.rightArrow2}></div>
							</div>
							<div className={styles.endTime}>
								<div className={styles.right}>
									{this.end_time.format('M/D/YYYY h:mm A')}
								</div>
							</div>
						</div>
					</div>

					<div className={styles.inputs}>
						<div className={styles.numInputContainer}>
							<input 
								className={styles.numInput}
								name="num"
								placeholder="Number"
								value={this.state.num}
								onChange={this.handleChange}
								onKeyDown={this.onKeyDown}
								maxLength="6"
							/>
						</div>

						<div className={styles.timeInputContainer}>
							<div 
								className={styles.timeInput} 
								onClick={()=>this.setState({showTime: !this.state.showTime})}
								style={{
									color: this.state.time==='time'?'lightgray':'black'
								}}
							>
								{this.state.time}
							</div>
							{this.state.showTime?
								<div className={styles.timeResults}>
									{statusTimes.map((time, i)=>
										<div 
											className={styles.result} 
											key={i}
											onClick={()=>this.handleTime(time)}
										>
											{time}
										</div>
									)}				
								</div>
								:null
							}
						</div>

						<div className={styles.statusInputContainer}>
							<div 
								className={styles.statusInput} 
								onClick={()=>this.setState({showStatus: !this.state.showStatus})}
								style={{
									color: this.state.status==='status'?'lightgray':'black'
								}}
							>
								{this.state.status}
							</div>
							{this.state.showStatus?
								<div className={styles.statusResults}>
									<div 
										className={styles.result} 
										name="late" 
										onClick={()=>this.handleStatus('late')}
									>
										late
									</div>
									<div 
										className={styles.result} 
										name="early" 
										onClick={()=>this.handleStatus('early')}
									>
										early
									</div>					
								</div>
								:null
							}
						</div>
					</div>

					{this.state.showWarning?
						<div> 
							<div className={styles.warning}>
								New end time cannot be before the start time
							</div>
						</div>
						:null
					}


					<div className={styles.notesContainer}>
						<RichEditor 
							setEditorState={this.setEditorState}
							height={this.state.showWarning?"275px":"300px"}
							placeholder="Status notes..."
							data={this.state.notes || null}
						/> 
					</div>
					<button className={styles.btn} onClick={this.handleSubmit}>
						Update
					</button> 
				</div>
			</Modal>
		);  
	}
} 
