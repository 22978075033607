import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';

import ProjectTodo from '../general/ProjectTodo';

import { ADD_PROJECT_TODO, ADD_MEMBER, CONFIRM_DELETE_MODAL } from '../../../general/modal/modalTypes';

import styles from '../css/projecthome.module.css';

const now = moment();

export default class ProjectHome extends Component {
	constructor(props){
		super(props);
		this.state = {
			showFilter: false,
			filter: 'Uncompleted'
		};
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);

		this.props.socket.on('project-add-members', data=>{
			if(data.content.project_id===this.props.project._id){
				this.props.updateMembers(data.content.members);
			}
		});
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside = e =>{
		if(this.wrapperRef && !this.wrapperRef.contains(e.target)){
			this.setState({showFilter:false});
		}
	}

	handleAddEvent = () => {
		this.props.showModal(ADD_PROJECT_TODO, {
			handleAdd:this.props.createProjectTodo, 
			project_id:this.props.project._id,
			member_details: this.props.member_details
		});
	}

	handleAddMembers = (members, project_id) => {
		const m = members.map(m=>{
			const temp = {...m};
			delete temp.profile_pic
			return temp;
		});

		this.props.socket.emit('project-add-members', {
			members:m,
			project_id,
			id:this.props.id
		});

		this.props.addMembersToProject(members, project_id);
	}

	handleItemClick = val => {
		this.setState({filter:val});
	}

	renderMembers = () => {
		//weird edge case scenario when logging in, a user would appear twice
		let member_details = [];
		this.props.member_details.forEach(m=>{
			if(!_.find(member_details, {id:m.id})){
				member_details.push({...m});
			}
		});

		return member_details.map(member=>{
			return(
				<div className={styles.memberCard} key={member.id}>
					<div className={styles.imgContainer}> 
						<img 
							className={styles.profilePic}
							src={member.profile_pic}
							alt="profile"
						/>
						{this.props.online.indexOf(member.id)>-1?
							<div className={styles.online}></div>
							:null
						}
						{this.props.id===this.props.project.owner_id?
							<div 
								className={styles.delete} 
								onClick={()=>this.props.showModal(CONFIRM_DELETE_MODAL, {
									item: {
										...member,
										project_id: this.props.project._id
									},
									topText: "Remove Member",
									deleteText: "Are you sure you want to remove",
									deleteName: `${member.username} (${member.name})`,
									deleteBtnTxt: 'Remove',
									handleDelete: this.props.removeMember
								})}
							>
								&times;
							</div>
							:null
						}
					</div>
					<p className={styles.memberName}>{`${member.username} (${member.name})`}</p>
				</div>
			);
		});
	}

	getFilteredEvents = () => {
		const filtered = this.props.project.events.filter(t=>{
			if(this.state.filter==='Completed') return t.complete;
			else if(this.state.filter==='Uncompleted') return !t.complete;
			else if(this.state.filter==='Reoccuring') return t.reoccuring;
			else return true;
		}).map(t=>{
			// FILTER EVENTS IF Reoccuring
			if(t.reoccuring){
				const start = moment(t.start_time);
				const end = moment(t.end_time);
				const diff = Math.abs(start.diff(end, 'minute'));
				const last_completed = moment(t.last_completed);
				const repeat_every = t.repeat_days;
				const days_from_start = now.clone().startOf('day')
										.diff(
											start.clone().startOf('day'), 
											'day'
										);
				const days_until_start = days_from_start%repeat_every;

				if(now.isAfter(end) &&!start.isSame(end, 'day')) return {
					...t,
					//original_start: start, 
					hidden: true
				};


				if(days_until_start===1) return {...t, /*original_start: start*/};
				if(days_until_start===0) {    
					const new_start_time = moment(
						now.format('YYYY-MM-DD')
						+" "+   
						start.format('h:mm A'), 'YYYY-MM-DD h:mm A'
					);  
					const new_end_time = new_start_time.clone().add(diff, 'minutes');

					if(last_completed.isBetween(new_start_time, new_end_time, 'minute', '[]')) 
						return {
							...t,
							//original_start: start,
							start_time: new_start_time.add(repeat_every, 'day'),
							end_time: new_end_time.add(repeat_every, 'day'),
							hidden: true
						};

					return {
						...t,
						start_time: new_start_time,
						end_time: new_end_time,
						//original_start: start,
					}
				}
				return {
					...t,
					//original_start: start, 
					hidden: true
				};
			}	
			return t;
		});

		return filtered.map(event=>{
			if(this.state.filter==='Uncompleted' && event.hidden) return null;
			else return( 
				<ProjectTodo 
					todo={event}
					member_details={this.props.member_details}
					key={event._id}
					setCursorPos={this.props.setCursorPos}	
					showContext={this.props.showContext}
					handleEdit={this.props.editProjectTodo}
					handleDelete={this.props.deleteProjectTodo}
				/>
			);
		}).filter(i=>i!==null);
	}

	render() {
		return (
			<div className={styles.homeContainer}>
				<div className={styles.homeContent}>
					<button 
						className={styles.createBtn} 
						onClick={this.handleAddEvent}
					>
						Create Event
					</button>
					<div className={styles.filterTab}>
						<h2 className={styles.subtitle}>Events</h2>
						<div 
							className={styles.filter} 
							onClick={()=>this.setState({
								showFilter:!this.state.showFilter
							})}
							ref={node=>this.wrapperRef=node}
						>
							<div className={styles.filterContainer}>
								<div className={styles.l1}></div>
								<div className={styles.l2}></div>
								<div className={styles.l3}></div>
								<div className={styles.l4}></div>
							</div>
							<div className={styles.text}>{this.state.filter}</div>
							{this.state.showFilter?
								<div className={styles.filterBy}>
									<p className={styles.item1} onClick={()=>this.handleItemClick('Completed')}>
										Completed Events
									</p>
									<p className={styles.item2} onClick={()=>this.handleItemClick('Uncompleted')}>
										Uncompleted Events
									</p>
									<p className={styles.item2} onClick={()=>this.handleItemClick('Reoccuring')}>
										Reoccuring Events
									</p>
								</div>
								:null
							}
						</div>
					</div>
					<div className={styles.eventList}>
						{this.getFilteredEvents()}
					</div>
				</div>
				<div className={styles.memberList}>
					<button 
						className={styles.addMemberBtn}
						onClick={()=>this.props.showModal(ADD_MEMBER, {
							member_details: this.props.member_details,
							friends:this.props.friends,
							project_id: this.props.project._id,
							addMembersToProject: this.handleAddMembers 
						})} 
					>
						Add Members
					</button>
					{this.renderMembers()}
				</div>
			</div>
		);
	}
}
