import { SHOW_CONTEXT, HIDE_CONTEXT, SET_CURSOR } from './actionTypes';

const initialState = {
	show: false,
	type: "",
	data: {},
	cursorPos: {
		cursorX: 0,
		cursorY: 0
	}
}

export function context_menu(state=initialState, action) {
	switch(action.type){
		case SHOW_CONTEXT:
			return {
				...state,
				show: true,
				type: action.payload.contextType,
				data: action.payload.data
			};

		case HIDE_CONTEXT:
			return  initialState;

		case SET_CURSOR:
			return {
				...state,
				cursorPos: {
					cursorX: action.payload.cursorX,
					cursorY: action.payload.cursorY
				}
			}

		default:
			return state;
	}
}