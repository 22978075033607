import { SET_ERROR, RESET_ERRORS } from './actionTypes';

export function setError(error){
	return {
		type: SET_ERROR,
		payload: error
	}
}

export function resetErrors(){
	return {
		type: RESET_ERRORS
	};
}