import React, { Component } from 'react';
import styles from './css/timepicker.module.css';

import { time_choices } from './times.js';

export default class TimePicker extends Component {
	constructor(props){
		super(props);
		this.state = {
			time: this.props.data ? this.props.data : "12:00 AM",
			showTimes: false
		};
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
		this.inputRef.addEventListener('keydown', this.handleInput);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
		this.inputRef.removeEventListener('keydown', this.handleInput);
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevState.time!==this.state.time){
			this.props.updateTime(this.props.name, this.state.time)
		}
	}

	handleClickOutside = e => {
		if(this.timesRef && !this.timesRef.contains(e.target)){
			const input = this.state.time;
			const reg = /^[0,1]?[0-9]:[0-5][0-9]\s[AaPp][Mm]/;
			if(!input.match(reg)){
				this.setState({time: "12:00 AM"});
			}
			this.setState({showTimes: false});
		}
	}

	handleInput = e => {
		const allowed = ['0','1','2','3','4','5','6','7','8','9','Backspace',' ','ArrowLeft','ArrowRight','Shift','A','P','M','a','p','m',':'];
		if(allowed.indexOf(e.key)===-1){
			e.preventDefault();
		}
	}

	handleChange = e =>{
		const { name, value } = e.target;
		this.setState({
			[name]:value
		});
		this.props.updateTime(this.props.name, value);
	}

	setTimesRef = node => {
		this.timesRef = node;
	}

	setInputRef = node => {
		this.inputRef = node;
	}

	handleClick = e => {
		this.setState({time: e.target.innerText, showTimes: false});
	}

	render() {
		const times = time_choices.map((time, i)=>{
			return(
				<li onClick={this.handleClick} key={i}>{time}</li>
			);
		});

		return ( 
			<div className={`${styles.timesContainer} ${this.props.warning?styles.warning:null}`}>
				<div className={styles.inputContainer}
				onClick={()=>{this.setState({showTimes: true})}}>
					<input  
						name="time"
						value={this.state.time}
						onChange={this.handleChange}
						className={styles.timepicker} 
						ref={this.setInputRef}
						autoComplete="off"
					/>
					<span>
						<img 
							src="https://d2voyh5ncb6zec.cloudfront.net/downArrow.svg" 
							alt="Open Menu" 
							className={styles.inputImg}
						/>
					</span>
				</div>
				{this.state.showTimes ? 
					<div className={styles.times} ref={this.setTimesRef}>
						<ul className={styles.timeList}>
							{times}
						</ul>
					</div>
					: null
				}
			</div>
		);
	}
}
