import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import LandingTopMenu from './LandingTopMenu';
import MobileMenu from './MobileMenu';

import styles from './css/pricing.module.css';

export default class Pricing extends Component {
	constructor(props){
		super(props);
		this.state = {
			openMobileMenu: false,
			menuClosingAnimation: false
		}
	}

	closeMenu = () => {
		this.setState({menuClosingAnimation: true});
		setTimeout(()=>{
			this.setState({openMobileMenu: false, menuClosingAnimation: false});
		}, 500);
			
	}

	renderFeatures = () => {
		const features = [
			"Unlimited Todos/Tasks",
			"Unlimited Projects and Project members",
			"Unlimited Project Todos",
			"Unlimited Project Roadmap Sections/Items",
			"100 Saved Project Messages"
		];

		return features.map(t=>{
			return(
				<div className={styles.item} key={t}>
					<span className={styles.checkmark}>
					    <div className={styles.checkmark_stem}></div>
					    <div className={styles.checkmark_kick}></div>
					</span>
					<div className={styles.text}>{t}</div>
				</div>
			);
		});
	}

	render() {
		return (
			<div>
				<LandingTopMenu 
					openMenu={()=>this.setState({openMobileMenu: true})}
				/>
				<div className={styles.content}>
					<div className={styles.title}>
						Taskcence is completely&nbsp;
						<span className={styles.highlight}>free</span>!
					</div>
					<div className={styles.features}>
						{this.renderFeatures()}
					</div>
					<div className={styles.container}>
						<button className={styles.signUp}> 
							<Link to="/register">Sign up Now!</Link>
						</button>
					</div>
				</div>
				{this.state.openMobileMenu?
					<div className={styles.menuContainer}>
						<MobileMenu 
							closeMenu={this.closeMenu} 
							closeAnimation={this.state.menuClosingAnimation}
						/>
					</div>
					:null
				}
			</div>
		);
	}
}
