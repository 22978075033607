import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { convertToRaw, convertFromRaw } from 'draft-js';

import { BlockPicker, TwitterPicker } from 'react-color';
import Modal from '../../../general/modal/modals/Modal';
import DatePicker from '../../../general/misc_components/DatePicker';
import TimePicker from '../../../general/misc_components/TimePicker';
import RichEditor from '../../../general/misc_components/RichEditor';
import MembersSelector from './MembersSelector';

import styles from './css/addroadmapitem.module.css';

const today = moment();

export default class AddRoadmapItem extends Component {
	constructor(props){
		super(props);
		const assigned_users = this.props.data.assigned_users?this.getAssignedFromData():[]; 
		const sectionSelection = this.props.data.parent_id?
								_.find(this.props.data.project.roadmap.sections, {_id:this.props.data.parent_id})
								:null;

		const start_time = this.props.data.start_time?moment(this.props.data.start_time):null;
		const end_time = this.props.data.end_time?moment(this.props.data.end_time):null;

		this.state = {
			name: this.props.data.name || '',
			start_date: start_time || today,
			start_time: start_time?start_time.format('h:mm A'):'12:00 AM',
			end_date: end_time || today,
			end_time: end_time?end_time.format('h:mm A'):'12:00 AM',
			assigned_users,
			notes: this.props.data.notes?convertFromRaw(JSON.parse(this.props.data.notes)):'',
			name_error: false,
			showPopup: false,
			background_color: this.props.data.background_color || '#0062B1',
			sectionSelection: sectionSelection || { name: 'No section' }
		};
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
		this.wrapperRef.addEventListener('keydown', this.handleInput);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
		this.wrapperRef.removeEventListener('keydown', this.handleInput);
	}

	handleClickOutside = e => {
		//!this.state.showPopup makes sure selection click takes precedence over window lostener
		if(this.wrapperRef && this.container && !this.container.contains(e.target)){
			this.setState({showPopup: false});
		}
	}

	getAssignedFromData = () => {
		const assigned_users = [];
		this.props.data.assigned_users.forEach(id=>{
			const found = _.find(this.props.data.member_details, {id});
			if(found){
				assigned_users.push(found);
			}
		});
		return assigned_users;
	}

	handleChange = e => {
		const { name, value } = e.target;
		this.setState({[name]:value});
	}

	updateDate = (name, value) => {
		const date = moment(value, 'YYYY-MM-DD');
		if(name==='start_date'){
			this.setState({
				[name]: date,
				end_date: date,
			});
		} else {
			this.setState({
				[name]: date,
			});
		}
	}

	updateTime = (name, value) => {
		this.setState({[name]:value});
	}

	handleColorChange = color => {
		this.setState({
			background_color: color.hex
		});
	}
    

	setEditorState = state => {
		this.setState({notes: state});
	}

	handleSelection = selection => {
		this.setState({
			sectionSelection:selection, 
			showPopup:false
		});
	}


	handleSubmit = () => {
		if(!this.state.name){
			return this.setState({name_error:true});
		}

		const parent_id = this.state.sectionSelection._id || this.props.data.project_id;
		const assigned_users = this.state.assigned_users.map(l=>l.id);
		const s = moment(this.state.start_date).format('YYYY-MM-DD');
		const e = moment(this.state.end_date).format('YYYY-MM-DD');
		const item = {
			name: this.state.name,
			start_time: moment(s+" "+this.state.start_time, 
				'YYYY-MM-DD h:mm A').toDate(),
			end_time: moment(e+" "+this.state.end_time, 
				'YYYY-MM-DD h:mm A').toDate(),
			background_color: this.state.background_color, 
			notes: JSON.stringify(convertToRaw(this.state.notes.getCurrentContent())),
			owner_id: this.props.data.id,
			assigned_users,
			parent_id 
		};
		let old_item;

		if(this.props.data._id) {
			item['_id']=this.props.data._id;
			old_item = {
				_id: this.props.data._id,
				parent_id: this.props.data.parent_id
			};
		}

		this.props.hideModal();
		this.props.data.handleSubmit(item, this.props.data.project_id, old_item);
	}

	render() {
		const data = moment(this.props.data.start_time)
			.isSame(this.state.start_date, 'day');

		return ( 
			<Modal
				hideModal={this.props.hideModal}
				width="65vw" 
				maxWidth="900px"
				height="700px"
				textAlign="center"
				top={true}
				topText={this.props.data.topText}
			>
				<div className={styles.form}>
					<input 
						onChange={this.handleChange}
						value={this.state.name}
						className={`${styles.nameInput} ${this.state.name_error?styles.error:''}`}
						autoComplete="off"
						placeholder="Item name"
						maxLength={75}
						name="name"
					/>
					<h2 className={`${styles.subtitle} ${styles.desktop}`}>Start Time</h2>
					<div className={styles.dateTimeContainer}>
						<h2 className={`${styles.subtitle} ${styles.mobile}`}>Start Time</h2>
						<div className={styles.dateContainer}>
							<DatePicker 
								updateDate={this.updateDate} 
								name="start_date"
								data={data?this.state.start_date.toDate():null}
							/>
						</div>
						<div className={styles.timeContainer}>
							<TimePicker 
								updateTime={this.updateTime} 
								name="start_time"
								data={this.state.start_time}
							/>
						</div>
					</div> 
					<h2 className={`${styles.subtitle} ${styles.desktop}`}>Expected End Time</h2>
					<div className={styles.dateTimeContainer}>
						<h2 className={`${styles.subtitle} ${styles.mobile}`}>Expected End Time</h2>
						<div className={styles.dateContainer}>
							<DatePicker 
								updateDate={this.updateDate} 
								name="end_date"
								data={this.state.end_date.toDate()}
								minDate={this.state.start_date.toDate()}
							/>
						</div>
						<div className={styles.timeContainer}>
							<TimePicker 
								updateTime={this.updateTime} 
								name="end_time"
								data={this.state.end_time}
							/>
						</div>
					</div>
					<div className={styles.memberSelectorContainer}>
						<h2 className={`${styles.subtitle} ${styles.mb}`}>Assigned Users</h2>
						<MembersSelector 
							member_details={this.props.data.member_details}
							updateParent={this.updateTime}
							name="assigned_users"
							added={this.state.assigned_users}
						/>
					</div>

					<div className={styles.itemIdSelection}>
						<h3 className={styles.subtitle2}>Assigned Section:</h3>
						<div className={styles.sectionParent}>
							<div 
								className={styles.selection} 
								onClick={()=>this.setState({showPopup:!this.state.showPopup})}
								ref={node=>this.wrapperRef=node}
							>
								{this.state.sectionSelection.name}
								<div className={styles.downArrow} style={this.state.showPopup?{
									transform:'rotate(90deg)',
									marginTop: '6px'
								}:{}}></div>
							</div>
							{this.state.showPopup?
								<div className={styles.popup} ref={node=>this.container=node}>
									<div 
										className={styles.result} 
										onClick={()=>this.handleSelection({name:'No selection'})}
									>

										No section
									</div>
									{this.props.data.project.roadmap.sections.map(s=>
										<div 
											className={styles.result} 
											key={s._id}
											onClick={()=>this.handleSelection(s)}
										>
											{s.name}
										</div>
									)}
								</div>
								:null
							}
						</div>	
					</div>
					<div className={styles.colorContainer}>
						<h3 className={styles.subtitle}>Section Color: </h3>
						<div className={styles.switch}>
							<div className={styles.mobileColor}>
								<BlockPicker 
									color={this.state.background_color}
									colors={[
										'#0062B1', //blue
										'#d92929', //red
										'#0dd14b', //green
										'#ebe71c', //yellow
										'#000000', //black
										'#77acf7', //light blue
										'#f07373', //light red
										'#5df08c', //light green
										'#f2f094', //light yellow
										'#f7f7f7'  //white 
									]}

									onChangeComplete={ this.handleColorChange }
								/> 
							</div> 
							<div className={styles.desktopColor}>
								<div 
									className={styles.color}
									style={{backgroundColor:this.state.background_color}}
								>
								</div>
								<TwitterPicker 
									color={this.state.background_color}
									triangle="hide"
									colors={[
										'#0062B1', //blue
										'#77acf7', //light blue
										'#d92929', //red
										'#f07373', //light red
										'#0dd14b', //green
										'#5df08c', //light green
										'#ebe71c', //yellow
										'#f2f094', //light yellow
										'#000000', //black
										'#f7f7f7'  //white 
									]}
									onChangeComplete={ this.handleColorChange }
								/> 
							</div>
						</div>
					</div>

					<div>
						<RichEditor 
							setEditorState={this.setEditorState}
							data={this.state.notes || null}
							height="275px"
						/>
					</div>
					<button className={styles.btn} onClick={this.handleSubmit}>
						{this.props.data.btnName}
					</button>
				</div> 
			</Modal>
		);
	}
}
