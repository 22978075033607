//---------------------------------Can be Merged with TodoContext----------------

import React, { Component } from 'react';
import anime from 'animejs/lib/anime.es.js';

import styles from '../css/todocontext.module.css';

import { EDIT_TASK, CONFIRM_DELETE_MODAL } from '../../modal/modalTypes';


export default class TodoContext extends Component {
	constructor(props){
		super(props);
		this.state = {
			context_height: 0
		};

		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
		this.setState({context_height: this.wrapperRef.clientHeight});
		anime({
			targets: this.wrapperRef,
			width: [
            	{ value: 0, duration: 0 }, 
            	{ value: 250 }
	        ],
	        easing: 'spring(0, 80, 20, 30)' 
		});
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	setWrapperRef(node){
		this.wrapperRef = node;
	}

	handleClickOutside(e){
		if(this.wrapperRef && !this.wrapperRef.contains(e.target)){
			this.props.closeContext();
		}
	}

	handleEdit(){
		this.props.showModal(EDIT_TASK, this.props.task);
		this.props.closeContext();
	} 

	handleDelete(){
		this.props.showModal(CONFIRM_DELETE_MODAL, {
			item: this.props.task,
			handleDelete: this.props.deleteTask,
			deleteText: "Are you sure you want to delete",
			deleteName: this.props.task.name,
			deleteBtnTxt: "Delete",
			topText: "Delete Task"
		});
		this.props.closeContext();
	}

	render() {
		let posX = this.props.cursorX;
		let posY = this.props.cursorY;
		if(this.props.cursorX+250 > window.innerWidth){
			posX = posX - (250-(window.innerWidth-posX));
		}
		if(this.props.cursorY+this.state.context_height>window.innerHeight){
			posY = posY - (this.state.context_height-(window.innerHeight-posY))
		} 

		return (
			<div 
				className={styles.contextMenu} 
				ref={this.setWrapperRef}
				style={{top: posY, left: posX}}
			>
				<ul>
					<li>
						<button
							onClick={this.handleEdit}
							className={styles.contextButton}
						> 
							<img 
								src="https://d2voyh5ncb6zec.cloudfront.net/edit.svg" 
								alt='edit' 
								className={styles.pic}
							/>
							Edit 
						</button>

					</li>
					<li>
						<button 
							onClick={this.handleDelete}
							className={styles.contextButton}
						>	
							<img 
								src="https://d2voyh5ncb6zec.cloudfront.net/trash.svg" 
								alt='delete' 
								className={styles.pic}
							/>
							Delete
						</button>
					</li>
				</ul>
			</div>
		);
	}
}
