import _ from 'lodash';
import { 
	CREATE_PROJECT, 
	DELETE_PROJECT,
	SET_PROJECTS, 
	CREATE_PROJECT_TODO, 
	DELETE_PROJECT_TODO,
	EDIT_PROJECT_TODO,
	ADD_MEMBERS,
	DELETE_MEMBER,
	CHANGE_PROJECT_NAME,
	ADD_ROADMAP_SECTION,
	EDIT_ROADMAP_SECTION,
	ADD_ROADMAP_ITEM,
	DEL_ROADMAP_SECTION,
	EDIT_ROADMAP_ITEM,
	DEL_ROADMAP_ITEM
} from './actionTypes';

const initialState = {
	data: []
};

export function project(state=initialState, action){
	switch(action.type){
		case CREATE_PROJECT:
			return {
				...state,
				data: [
					...state.data,
					action.payload
				]
			}; 

		case DELETE_PROJECT:
			return {
				...state,
				data: state.data.filter(p=>p._id!==action.payload.project_id)
			};

		case SET_PROJECTS:
			return {
				...state,
				data: action.payload
			};

		case CREATE_PROJECT_TODO:
			const updatedData = state.data.map(item => {
			    if (item._id === action.payload.project_id) {
			        return {
			        	...item,
			        	events: [...item.events, action.payload],
			        };
			    }
			    return item;
			});

			return {
				...state,
				data:updatedData
			};

		case EDIT_PROJECT_TODO:
			const newData = state.data.map(project=>{
				if(project._id===action.payload.project_id){
					const index = project.events.findIndex(todo => todo._id===action.payload._id);
					return {
						...project,
						events: Object.assign([], project.events,{[index]: action.payload})
					}
				}

				return project;
			});

			return {
				...state,
				data:newData
			}

		case DELETE_PROJECT_TODO:
			const newData2 = state.data.map(project=>{
				if(project._id===action.payload.project_id){
					const newEvents = project.events.filter(event=>event._id!==action.payload.todo_id);
					return {
						...project,
						events:newEvents
					};
				}
				return project;
			});

			return {
				...state,
				data:newData2
			};

		case ADD_MEMBERS:
			const newProjects = state.data.map(project=>{
				if(project._id===action.payload._id){
					return {
						...project,
						members: action.payload.members
					};
				}

				return project;
			});
			return { 
				...state,
				data: newProjects
			};

		case DELETE_MEMBER:
			const projects = state.data.map(project=>{
				if(project._id===action.payload.project_id){
					return {
						...project,
						members: action.payload.new_members
					};
				}

				return project;
			});
			return {
				...state,
				data: projects
			};

		case CHANGE_PROJECT_NAME:
			const new_projects = state.data.map(project=>{
				if(project._id===action.payload.project_id){
					return {
						...project,
						name: action.payload.new_name
					};
				}
				return project;
			});
			return {
				...state,
				data: new_projects
			};

		case ADD_ROADMAP_SECTION:
			const new_projects2 = state.data.map(project=>{
				if(project._id===action.payload.project_id){
					return {
							...project,
							roadmap: {
								...project.roadmap,
								sections: [
									...project.roadmap.sections,
									action.payload.section
								],
							}
						}
				}
				return project;
			});
			return {
				...state,
				data: new_projects2
			};

		case EDIT_ROADMAP_SECTION:
			return {
				...state,
				data: state.data.map(project=>{
					if(project._id===action.payload.project_id){
						return {
							...project,
							roadmap: {
								...project.roadmap,
								sections: project.roadmap.sections.map(section=>{
												if(section._id===action.payload.section._id){
													return {
														...action.payload.section,
														items: [...section.items]
													}
												}
												return section;
											})
							}
						};
					}
					return project;
				}) 
			}

		case DEL_ROADMAP_SECTION:
			const new_projects3 = state.data.map(project=>{
				if(project._id===action.payload.project_id){
					return {
						...project,
						roadmap: {
							...project.roadmap,
							sections: project.roadmap.sections.filter(s=>s._id!==action.payload.section._id)
						}
					}
				}

				return project;
			});
			return {
				...state,
				data: new_projects3
			};

		case ADD_ROADMAP_ITEM:
			const new_projects4 = state.data.map(project=>{
				const indexOfSection = _.findIndex(project.roadmap.sections, {_id:action.payload.parent_id});
				if(project._id===action.payload.parent_id){
					return {
						...project,
						roadmap: {
							...project.roadmap,
							items: [
								...project.roadmap.items,
								action.payload
							]
						}
					};
				} else if(indexOfSection>-1) {
					const new_sections = Object.assign([], project.roadmap.sections,{[indexOfSection]: {
						...project.roadmap.sections[indexOfSection],
						items: [
							...project.roadmap.sections[indexOfSection].items,
							action.payload
						]
					}});
					return {
						...project,
						roadmap: {
							...project.roadmap,
							sections: new_sections
						}
					};
				}
				return project;
			});
			return {
				...state,
				data: new_projects4
			};

		case EDIT_ROADMAP_ITEM:
			return {
				...state,
				data: state.data.map(project=>{
					if(project._id===action.payload.project_id){
						const iOfSection = _.findIndex(project.roadmap.sections, {
							_id:action.payload.item.parent_id
						});

						return {
							...project,
							roadmap: iOfSection===-1?
							{
								//general section item
								...project.roadmap,
								items: project.roadmap.items.map(item=>{
									if(item._id===action.payload.item._id){
										return {...action.payload.item}
									}

									return item;
								})
							}
							:
							{
								//item belonging to section
								...project.roadmap,
								sections: project.roadmap.sections.map(section=>{
									if(section._id===action.payload.item.parent_id){
										return {
											...section,
											items: section.items.map(i=>{
														if(i._id===action.payload.item._id){
															return {...action.payload.item};
														}

														return i;
													})
										}
									}

									return section;
								})
							}
						}
					}

					return project;
				})	
			};

		case DEL_ROADMAP_ITEM:
			return {
				...state,
				data: state.data.map(project=>{
					if(project._id===action.payload.project_id){
						const iOfSection = _.findIndex(project.roadmap.sections, {
							_id:action.payload.item.parent_id
						});

						return {
							...project,
							roadmap: iOfSection===-1?
							{
								...project.roadmap,
								items: project.roadmap.items.filter(i=>i._id!==action.payload.item._id)
							}
							:
							{
								//section item
								...project.roadmap,
								sections: project.roadmap.sections.map(section=>{
									if(section._id===action.payload.item.parent_id){
										return {
											...section,
											items: section.items.filter(i=>i._id!==action.payload.item._id)
										};
									}

									return section;
								})
							}
						}
					}

					return project;
				})
			};

		default:
			return state;
	}
}