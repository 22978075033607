import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Portal } from 'react-portal';

import { deleteMessage } from '../actions';

import Message from './Message';
 
class MessageManager extends Component {
	startTimer = () => {
		setTimeout(this.props.deleteMessage, 3000);
	}

	render() {
		if(this.props.messageType){
			return (
					<Portal>
						<Message 
							message={this.props.message}
							messageType={this.props.messageType}
							startTimer={this.startTimer}
						/>
					</Portal>
				);
		} 
		return null;
	}
}

const mapStateToProps = state => {
	return {
		messageType: state.message.messageType,
		message: state.message.message
	}
}

const mapDispatchToProps = {
	deleteMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageManager);
