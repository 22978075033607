import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';

import RoadmapItem from './RoadmapItem';

import { ROADMAP_SECTION_INFO } from '../../../general/context_menu/contextTypes';

import styles from './css/section.module.css';

const pxPerMinute = 100/24/60;
 
export default class Section extends Component {
	// optimize section rendering in the future

	// shouldComponentUpdate(nextProps, nextState) {
	// 	if(_.isEqual(this.props.section, nextProps.section)){
	// 		return false;
	// 	}
	// 	return true;
	// }

	onScroll = e => {
		const synced = document.querySelectorAll('.scrollSync');
		synced.forEach(ele=>ele.scrollLeft = e.currentTarget.scrollLeft);
	}

	getInterfereFromItem = (iterable, item) => {
		const start = moment(item.start_time);
		const end = moment(item.end_time);
		const current_end = moment(item.current_end_time);

		let interfere =  0;
		iterable.forEach(i=>{
			if(i._id===item._id) return;
			const s = moment(i.start_time); 
			const e = moment(i.current_end_time);

			if((start.isBetween(s, e, 'minute','[)') 
						|| current_end.isBetween(s, e, 'minute', '()')) && i.interfere===interfere){
 
				interfere++;
			}
		});

		const late = current_end.isAfter(end);

		return {
			num: interfere,
			late
		};
	}

	renderItem = (item, index) => {
		const start = moment(item.start_time);
		const end = moment(item.current_end_time);
		const left = Math.abs(
			this.props.dates.start.clone().diff(start, 'minute')
		) * pxPerMinute + 100; 

		const top = item.interfere*37;
		const width = Math.abs(end.diff(start, 'minute')) * pxPerMinute;
		return(
			<div 
				key={item._id}
				className={styles.itemContainer} 
				style={{
					left,
					top,
					width,
				}}
			>
				<RoadmapItem 
					item={item}
					width={width}
					showModal={this.props.showModal}
					setCursorPos={this.props.setCursorPos}	
					showContext={this.props.showContext}
					editRoadmapItem={this.props.editRoadmapItem}
					delRoadmapItem={this.props.delRoadmapItem}
					project={this.props.project}
					member_details={this.props.member_details}
					id={this.props.id}
				/>
			</div>
		);
	}

	handleInfo = (e, section, owner, num_late) => {
		if(this.props.setCursorPos && this.props.showContext){
			this.props.setCursorPos(e.pageX, e.pageY);
			this.props.showContext(ROADMAP_SECTION_INFO, {
				section, 
				owner, 
				num_late,
				username:this.props.username,
				handleEdit: this.props.editRoadmapSection,
				handleDelete:this.props.delRoadmapSection,
				project_id: this.props.project._id,
				id: this.props.id,
				topText: 'Project Section'
			});
		}
	}

	renderMarker = (date, i, height) => {
		const props = {
			key: i,
			style: {
				left: i*100+'px',
				height
			}
		};

		const start = moment(this.props.section.start_date);
		const end = moment(this.props.section.end_date); 
		
		const sameStart = date.isSame(start, 'day');

		const sameEnd = date.isSame(end, 'day');

		if((sameStart || sameEnd) && this.props.section.start_date){
			return(
				<div
					className={styles.sectionDatesMarker}
					{...props}
				>
					<div 
						className={styles.line}
						style={{
							height: (height.slice(0, height.length-2) - 70.5)/2 - 15 + 'px'
						}}
					></div>
					<div className={`${styles.small} ${styles.nm}`}>
						{sameStart?'Section Start':(sameEnd?'Section End':'')}
					</div>
					<div 
						className={styles.line}
						style={{
							height: (height.slice(0, height.length-2) - 70.5)/2 - 15 + 'px'
						}}
					></div>
				</div>
			);
		}

		return(
			<div className={styles.daySeparator} {...props}></div>
		);
	}

	render() {
		const isSection = this.props.section.name;
		const name = this.props.section.name || 'General Section';
		const owner = isSection?
			this.props.section.owner_id===this.props.id?
				{
					id:this.props.id,
					username: this.props.username,
					name: this.props.name,
					profile_pic: this.props.profile_pic
				}
				:_.find(this.props.member_details, {id:this.props.section.owner_id})
			:null; 


		const start = this.props.dates.start;
		let width = this.props.markerDates.length * 100;

		const section_items = this.props.section.items || this.props.section;
		section_items.forEach((item, index, arr)=>{
			const interfere = this.getInterfereFromItem(arr.slice(0, index), item);
			arr[index] = {
				...arr[index],
				interfere: interfere.num,
				late: interfere.late
			};
		});

		let itemRows = 0;
		let num_late = 0;

		section_items.forEach(i=>{
			if (i.interfere>itemRows) {
				itemRows=i.interfere;
			}
			if(i.late){
				num_late++;
			} 
		});
		itemRows++;
		const height = itemRows*37>187?itemRows*37+5+'px':'187px';

		const today = moment()
		const todayMarkLeft = today.diff(start, 'minute') * pxPerMinute + 100;
		// console.log(start)
		// console.log(moment().diff(start, 'minute'))

		return (
			<div className={styles.container}>
				<div 
					className={styles.sectionCard} 
					onClick={isSection?e=>this.handleInfo(e, this.props.section, owner, num_late):null}
				>
					<h3 className={styles.sectionName}>{name}</h3>
					{owner?
						<div className={styles.sectionInfo}>
							<p className={styles.small}>Created By</p>
							<div className={styles.profile}>
								<img 
									src={owner.profile_pic}
									alt={owner.username}
									className={styles.profilePic}
								/>
								<p className={styles.profileName}>
									{`${owner.username} (${owner.username===this.props.username?'You':owner.name})`}
								</p>
							</div>
						</div>
						:null
					}
					{
						num_late>0?
						<div className={styles.lateNum}>
							<span className={styles.red}>{num_late}&nbsp;</span> 
							late item(s)
						</div>
						:null
					}
				</div>
				<div 
					className={`${styles.timelineContainer} ${'scrollSync'}`} 
					onScroll={this.onScroll}
				>
					<div 
						className={styles.section} 
						style={{
							width:width!==0?width:'auto',
							height
						}}
					>
						{this.props.markerDates.map((date, i)=>
							i>0?
							this.renderMarker(date, i, height)
							:null
						)}
						{!today.isAfter(this.props.dates.end)?
							<div 
								className={styles.todayMarker}
								style={{
									left: todayMarkLeft,
									height
								}}
							>
							</div>
							:null
						}
						{section_items.map((item, i)=>this.renderItem(item, i)) }
					</div>
				</div>
			</div>
		);
	}
}
 