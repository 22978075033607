import React, { Component } from 'react';
import moment from 'moment';

import styles from './css/checkavailable.module.css';

import Todo from '../../todo/components/Todo';

export default class CheckAvailable extends Component {
	render() {
		const passed_date = moment(this.props.date);
		const todos = this.props.all_todos.filter(todo => {
			if(todo._id===this.props.current_id){return false}
			const todo_start = moment(todo.start_time);
			if(todo_start.isSame(passed_date, 'day')){
				return true;
			}
			return false;
		}).map((todo, index) => {return(<Todo todo={todo} key={index}/>)});

		const daysMap = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
		const monthMap = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

		let date = this.props.date;
		date = `${daysMap[date.getDay()]}, ${monthMap[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
		return (
			<div className={styles.mainContent}>
				<h1 className={styles.date}>{date}</h1>
				<p className={styles.eventNum}>{todos.length} event(s)</p>
				<div className={styles.todos}>
					{todos}
				</div>
			</div>
		);
	}
}
