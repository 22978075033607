import React, { Component } from 'react';
import {Editor, EditorState, RichUtils, getDefaultKeyBinding, KeyBindingUtil} from 'draft-js';

import 'draft-js/dist/Draft.css';
import styles from './css/richeditor.module.css';

const MAX_LENGTH = 600;

export default class RichEditor extends Component {
	constructor(props){
		super(props);
		this.state = {
			editorState: this.props.data ? EditorState.createWithContent(this.props.data): EditorState.createEmpty()
		};

    	this.handleKeyCommand = this.handleKeyCommand.bind(this);
    	this.toggleInlineStyle = this.toggleInlineStyle.bind(this);
    	this.toggleBlockType = this.toggleBlockType.bind(this);
	}

	componentDidMount() {
		this.props.setEditorState(this.state.editorState);
	}

	componentDidUpdate(prevProps, prevState) { 
		if(prevState.editorState!==this.state.editorState){
			this.props.setEditorState(this.state.editorState);
		}
	}

	onChange = editorState => {
		this.setState({editorState});
	}

	handleKeyCommand(command, editorState) {
	    let newState = RichUtils.handleKeyCommand(editorState, command);
	    if(!newState && command === 'strikethrough') {
	    	newState = RichUtils.toggleInlineStyle(editorState, 'STRIKETHROUGH');
	    }

	    if(!newState && command === 'blockquote') {
	    	newState = RichUtils.toggleInlineStyle(editorState, 'blockquote');
	    }

	    if(!newState && command === 'ordered-list') {
	    	newState = RichUtils.toggleInlineStyle(editorState, 'ordered-list-item');
	    }

	    if(!newState && command === 'unordered-list') {
	    	newState = RichUtils.toggleInlineStyle(editorState, 'unordered-list-item');
	    }

	    if (newState) {
	      this.onChange(newState);
	      return 'handled';
	    }
	    return 'not-handled';
  	}

  	toggleInlineStyle(event) {
	    event.preventDefault();
	    let style = event.currentTarget.getAttribute('data-style');
	    this.setState({
	      editorState: RichUtils.toggleInlineStyle(this.state.editorState, style)
	    });
  	}

  	toggleBlockType(event){
  		event.preventDefault();

	    let block = event.currentTarget.getAttribute('data-block');
	    this.setState({
	      editorState: RichUtils.toggleBlockType(this.state.editorState, block)
	    });
  	} 

  	keyBindingFunction = event => {
  		if (KeyBindingUtil.hasCommandModifier(event) && event.shiftKey && event.key === 'x') {
    		return 'strikethrough';
    	}

   		if (KeyBindingUtil.hasCommandModifier(event) && event.shiftKey && event.key === '7') {
		  return 'ordered-list';
		}

		if (KeyBindingUtil.hasCommandModifier(event) && event.shiftKey && event.key === '8') {
		  return 'unordered-list';
		}

		if (KeyBindingUtil.hasCommandModifier(event) && event.shiftKey && event.key === '9') {
		  return 'blockquote';
		}
    	return getDefaultKeyBinding(event);
  	}

	_getLengthOfSelectedText = () => {
		const currentSelection = this.state.editorState.getSelection();
		const isCollapsed = currentSelection.isCollapsed();

		let length = 0;

		if (!isCollapsed) {
			const currentContent = this.state.editorState.getCurrentContent();
			const startKey = currentSelection.getStartKey();
			const endKey = currentSelection.getEndKey();
			const startBlock = currentContent.getBlockForKey(startKey);
			const isStartAndEndBlockAreTheSame = startKey === endKey;
			const startBlockTextLength = startBlock.getLength();
			const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
			const endSelectedTextLength = currentSelection.getEndOffset();
			const keyAfterEnd = currentContent.getKeyAfter(endKey);
			if (isStartAndEndBlockAreTheSame) {
				length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
			} else {
				let currentKey = startKey;
				while (currentKey && currentKey !== keyAfterEnd) {
				  if (currentKey === startKey) {
				    length += startSelectedTextLength + 1;
				  } else if (currentKey === endKey) {
				    length += endSelectedTextLength;
				  } else {
				    length += currentContent.getBlockForKey(currentKey).getLength() + 1;
				  }

				  currentKey = currentContent.getKeyAfter(currentKey);
				};
			}
		}

		return length;
	}

	_handleBeforeInput = () => {
		const currentContent = this.state.editorState.getCurrentContent();
		const currentContentLength = currentContent.getPlainText('').length;
		const selectedTextLength = this._getLengthOfSelectedText();

		if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
			return 'handled';
		}
	}

	_handlePastedText = (pastedText) => {
		const currentContent = this.state.editorState.getCurrentContent();
		const currentContentLength = currentContent.getPlainText('').length;
		const selectedTextLength = this._getLengthOfSelectedText();

		if (currentContentLength + pastedText.length - selectedTextLength > MAX_LENGTH) {
			return 'handled';
		}
	}

	render() {
		const inlineStyles = [
			{
		        value: 
		        	<img 
		        		src="https://d2voyh5ncb6zec.cloudfront.net/bold.svg" 
		        		alt="Bold" 
		        		width="16" 
		        		height="16"
		        	/>,
		        style: 'BOLD'
		    },

		    {
		        value: 
		        	<img 
		        		src="https://d2voyh5ncb6zec.cloudfront.net/italic.svg"
		        		alt="Italic" 
		        		width="16" 
		        		height="16"
		        	/>,
		        style: 'ITALIC'
		    },

		    { 
		        value: 
		        	<img 
		        		src="https://d2voyh5ncb6zec.cloudfront.net/underline.svg" 
		        		alt="Underline" 
		        		width="16" 
		        		height="16"
		        	/>,
		        style: 'UNDERLINE'
		    },

		    {
		        value: 
		        	<img 
		        		src="https://d2voyh5ncb6zec.cloudfront.net/strikethrough.svg" 
		        		alt="Strikethrough" 
		        		width="18" 
		        		height="18"
		        	/>,
		        style: 'STRIKETHROUGH'
		    },
 
		    {
		        value: 
		        	<img 
		        		src="https://d2voyh5ncb6zec.cloudfront.net/coding.svg"
		        		alt="Code Block" 
		        		width="18" 
		        		height="18"
		        	/>,
		        style: 'CODE'
		    }
		];

		const blockTypes = [
		    {
		      value: 
		          <img 
		          	src="https://d2voyh5ncb6zec.cloudfront.net/header1.png" 
		          	alt="Heading One"
		          />,
		      block: 'header-one'
		    },

		    {
		      value: 
		          <img 
		      		src="https://d2voyh5ncb6zec.cloudfront.net/header2.png" 
		      		alt="Heading Two"
		      	/>,
		      block: 'header-two'
		    },

		    {
		      value: 
		          <img 
		          	src="https://d2voyh5ncb6zec.cloudfront.net/header3.png" 
		          	alt="Heading Three"
		          />,
		      block: 'header-three'
		    },

		    {
		      value: 
		          <img 
		          	src="https://d2voyh5ncb6zec.cloudfront.net/quote.svg" 
		          	alt="Blockquote"
		          	width="16" 
		          	height="16"
		         />,
		      block: 'blockquote'
		    },

		    {
		      value: 
		          <img 
		          	src="https://d2voyh5ncb6zec.cloudfront.net/unorderedlist.svg" 
		          	alt="Unordered List Item" 
		          	width="18" 
		          	height="18"
		          />,
		      block: 'unordered-list-item'
		    },

		    {
		      value: 
		          <img 
		          	src="https://d2voyh5ncb6zec.cloudfront.net/orderedlist.svg" 
		          	alt="Ordered List Item" 
		          	width="18" 
		          	height="18"
		          />,
		      block: 'ordered-list-item'
		    }
    	];
		const inlineStyleButtons = inlineStyles.map(button => {
			return (
				<button 
					type="button" 
					key={button.style}
					data-style={button.style}
					onClick={this.toggleInlineStyle}
					className={styles.toolbarButton}
				>
				{button.value}
				</button>
			);
		})

		const blockTypeButtons = blockTypes.map(button => {
			return (
				<button
					type="button"
					key={button.block}
					data-block={button.block}
					onClick={this.toggleBlockType}
					className={styles.toolbarButton}
				>
				{button.value}	
				</button>
			);
		});
		return ( 
			<div>
				<div className={styles.toolbar}>
					{inlineStyleButtons}
					{blockTypeButtons}
				</div>
				<div className={styles.editorRegion} style={{height:this.props.height}}>
					<Editor 
						editorState={this.state.editorState}
						handleKeyCommand={this.handleKeyCommand}
						onChange={this.onChange}
						keyBindingFn={this.keyBindingFunction}
						placeholder={this.props.placeholder || "Notes..."}
						handleBeforeInput={this._handleBeforeInput}
          				handlePastedText={this._handlePastedText} 
					/>
				</div>
			</div>
		);
	}
}
