import React, { Component } from 'react';

import LandingTopMenu from './LandingTopMenu';
import MobileMenu from './MobileMenu';

import styles from './css/support.module.css';

const CREATE_TODOS_TASKS = 'CREATE_TODOS_TASKS';
const EDIT_TODOS_TASKS = 'EDIT_TODOS_TASKS';
const PROJECT = 'PROJECT';
const ROADMAP = 'ROADMAP';
const PROFILE = 'PROFILE';


export default class Support extends Component {
	constructor(props){
		super(props);
		this.state = {
			openMobileMenu: false,
			menuClosingAnimation: false,
			selectedMenuItem: ""
		}
	}

	closeMenu = () => {
		this.setState({menuClosingAnimation: true});
		setTimeout(()=>{
			this.setState({openMobileMenu: false, menuClosingAnimation: false});
		}, 500);
			
	}

	render() {
		const items = [
			{
				title: "Creating events/tasks",
				icon: "https://d2voyh5ncb6zec.cloudfront.net/book.png",
				name: CREATE_TODOS_TASKS
			},
			{
				title: "Editing events/tasks",
				icon: "https://d2voyh5ncb6zec.cloudfront.net/pencil.png",
				name: EDIT_TODOS_TASKS
			},
			{
				title: "Projects",
				icon: "https://d2voyh5ncb6zec.cloudfront.net/person.png",
				name: PROJECT
			},
			{
				title: "Roadmap",
				icon: "https://d2voyh5ncb6zec.cloudfront.net/road.png",
				name: ROADMAP
			},
			{
				title: "Profile Settings",
				icon: "https://d2voyh5ncb6zec.cloudfront.net/settings.png",
				name: PROFILE
			},
		];


		return (
			<div>
				<LandingTopMenu 
					openMenu={()=>this.setState({openMobileMenu: true})}
				/>
				<div className={styles.content}>
					<div className={styles.menuItems}>
						{items.map(i=>
							<div 
								className={styles.item} 
								key={i.name} 
								onClick={()=>this.setState({selectedMenuItem: i.name})}
							>
								<img src={i.icon} alt="pencil" className={styles.itemPic}/>
								<div className={styles.title}>{i.title}</div>
							</div>
						)}
					</div>
					<div className={styles.container}>
						<div className={styles.info}>
							{views[this.state.selectedMenuItem]}
						</div>
					</div>
				</div>
				{this.state.openMobileMenu?
					<div className={styles.menuContainer}>
						<MobileMenu 
							closeMenu={this.closeMenu} 
							closeAnimation={this.state.menuClosingAnimation}
						/>
					</div>
					:null
				}
			</div>
		);
	}
}

const views = {
	CREATE_TODOS_TASKS: 
		<div>
			<div className={styles.infoTitle}>Creating Events</div>
			<div className={styles.text}>
				You can create an event by clicking on the 
				<span className={styles.highlighted}>Add Event</span>
				button on the dashboard page.
				<div className={styles.imgContainer}>
					<img 
						src="https://d2voyh5ncb6zec.cloudfront.net/tutorialCreateEvent.PNG"
						alt="create event"
						className={styles.infoPic}
					/>
				</div>
				<div>A modal will pop up:</div>
				<div className={styles.imgContainer}>
					<img 
						src="https://d2voyh5ncb6zec.cloudfront.net/tutorialEventModal.png"
						alt="event modal"
						className={styles.infoPic}
					/>
				</div>
			</div>
			<div className={styles.infoTitle}>Creating Tasks</div>
			<div className={styles.text}>
				Similarly, you can create a task by clicking on the 
				<span className={styles.highlighted}>Add Task</span>
				button on the dashboard page.
				<div className={styles.imgContainer}>
					<img 
						src="https://d2voyh5ncb6zec.cloudfront.net/tutorialCreateEvent.PNG"
						alt="add task"
						className={styles.infoPic}
					/>
				</div>
				<div>A modal will pop up:</div>
				<div className={styles.imgContainer}>
					<img 
						src="https://d2voyh5ncb6zec.cloudfront.net/tutorialTaskModal.png"
						alt="task modal"
						className={styles.infoPic}
					/>
				</div>
			</div>
		</div>,
	EDIT_TODOS_TASKS:
		<div>
			<div className={styles.infoTitle}>Editing Events</div>
			<div className={styles.text}>
				Right click on any event to bring up the context menu, and click
				the <span className={styles.highlighted}>Edit</span> button
				<div className={styles.imgContainer}>
					<img 
						src="https://d2voyh5ncb6zec.cloudfront.net/tutorialEventContext.png"
						alt="edit event"
						className={styles.infoPic}
					/>
				</div>
				<div className={styles.imgContainer}>
					<img 
						src="https://d2voyh5ncb6zec.cloudfront.net/tutorialEditEvent.PNG"
						alt="edit event modal"
						className={styles.infoPic}
					/>
				</div>
				Follow similar steps to edit a task.
			</div>
		</div>,
	PROJECT:
		<div>
			<div className={styles.infoTitle}>Creating Projects</div>
			<div className={styles.text}>
				Click on the <span className={styles.highlighted}>Create Project</span>
				Button on the sidebar. A modal will pop up:
				<div className={styles.imgContainer}>
					<img 
						src="https://d2voyh5ncb6zec.cloudfront.net/tutorialCreateProject.PNG"
						alt="project tabs"
						width="65%"
					/>
				</div>
				Give your project a meaningful name and add friends to the project
				as members. Note that you cannot create a project with no name.
			</div>
			<div className={styles.infoTitle}>Project Overview</div>
			<div className={styles.text}>
				<div className={styles.imgContainer}>
					<img 
						src="https://d2voyh5ncb6zec.cloudfront.net/tutorialProject.PNG"
						alt="project dashboard"
						className={styles.infoPic}
					/>
				</div>
				This is your dashboard for your project. At the very top you will
				see the project name. Double click on the name to edit the name.
				Under Events, you will find events that you and other people have
				created. On the right, you will see members of the project. To delete 
				a project, click on the gear icon on the top right corner and click
				the <span className={styles.highlighted}>Delete Project</span>
				button. Note that you have to be the project owner to delete the
				project.
			</div>
			<div className={styles.text}>
				<div className={styles.infoTitle}>Project Events</div> 
				Click on
				the <span className={styles.highlighted}>Create Event</span> button
				to create an event.
				<div className={styles.imgContainer}>
					<img 
						src="https://d2voyh5ncb6zec.cloudfront.net/tutorialCreateProjectEvent.PNG"
						alt="project event modal"
						width="70%"
					/>
				</div>
				To create a reoccuring event, click on the 
				<span className={styles.highlighted}>Repeat</span> box to bring
				up the inputs and follow the instructions below the date inputs.
				<div className={styles.imgContainer}>
					<img 
						src="https://d2voyh5ncb6zec.cloudfront.net/tutorialProjectTodo.PNG"
						alt="event info"
						width="70%"
					/>
				</div>
				Clicking on the event brings up the info context, where you will find
				more information about the event and completion status.
			</div>
		</div>,
		ROADMAP: 
			<div>
				<div className={styles.text}>
					<div className={styles.infoTitle}>Project Roadmap</div>
					To get to the roadmap, click on the 
					<span className={styles.highlighted}>Roadmap</span> tab in 
					any project.
					<div className={styles.imgContainer}>
						<img 
							src="https://d2voyh5ncb6zec.cloudfront.net/tutorialProjectTabs.PNG"
							alt="add roadmap section modal"
							width="70%"
						/>
					</div>
				</div>
				<div className={styles.text}>
					<div className={styles.infoTitle}>Sections</div>
					Once you create a project, a roadmap will be created automatically.
					It will look something like this:
					<div className={styles.imgContainer}>
						<img 
							src="https://d2voyh5ncb6zec.cloudfront.net/tutorialEmptyRoadmap.PNG"
							alt="empty roadmap"
							className={styles.infoPic}
						/>
					</div>
					This is the general section where all items that don't have a 
					parent section will go. To create a new section, click on the 
					<span className={styles.highlighted}>New Section</span> button
					<div className={styles.imgContainer}>
						<img 
							src="https://d2voyh5ncb6zec.cloudfront.net/tutorialCreateRoadmapSection.PNG"
							alt="add roadmap section modal"
							width="70%"
						/>
					</div>
					Just like events, roadmap sections have a start date and end date
					and also includes notes. After creating a section, it will appear
					and dates will adjust to fit the section.
					<div className={styles.imgContainer}>
						<img 
							src="https://d2voyh5ncb6zec.cloudfront.net/tutorialRoadmapSection.PNG"
							alt="roadmap with section"
							className={styles.infoPic}
						/>
					</div>
					The project roadmap updates in realtime, so when you or another
					member creates a section, it will appear on the other user's 
					screen immediately.
					<div className={styles.imgContainer}>
						<img 
							src="https://d2voyh5ncb6zec.cloudfront.net/tutorialSectionInfo.PNG"
							alt="roadmap section info"
							width="70%"
						/>
					</div>
					If you click on the section, the info context will pop up.
					It shows section start/end dates, section owner, and info on
					items. You can edit or delete the section by clicking on the
					corresponding buttons on the top right.
				</div>
				<div className={styles.text}>
					<div className={styles.infoTitle}>Items</div>
					To create an item, click on the 
					<span className={styles.highlighted}>New Item</span> button
					and a modal will pop up.
					<div className={styles.imgContainer}>
						<img 
							src="https://d2voyh5ncb6zec.cloudfront.net/tutorialCreateRoadmapItem.PNG"
							alt="create roadmap item"
							width="70%"
						/>
					</div>
					Roadmap items have a start time and an expected end time. The 
					expected end time is when the item is expected to be finished.
					You can add the item to a specific section, assign users to the
					item, add notes, and set the item color. Click create to create the
					item.
					<div className={styles.imgContainer}>
						<img 
							src="https://d2voyh5ncb6zec.cloudfront.net/tutorialPopulatedRoadmap.PNG"
							alt="roadmap section with item"
							className={styles.infoPic}
						/>
					</div>
					If you click on the item, the item info context will show up
					with various different options:
					<div className={styles.imgContainer}>
						<img 
							src="https://d2voyh5ncb6zec.cloudfront.net/tutorialItemInfo.PNG"
							alt="roadmap item info context"
							width="70%"
						/>
					</div>
					You can edit and delete the item just like a section, but you
					can also edit the status. Click on
					<span className={styles.highlighted}>Update Status</span>:
					<div className={styles.imgContainer}>
						<img 
							src="https://d2voyh5ncb6zec.cloudfront.net/tutorialItemStatus.PNG"
							alt="roadmap item update status modal"
							width="70%"
						/>
					</div>
					You can set the item as late or early, and also add status notes.
					Note that items are also updated in realtime, so any other members
					will see immediate changes.
				</div>
			</div>,
	PROFILE:
		<div>
			<div className={styles.text}>
				<div className={styles.infoTitle}>Profile Setting</div>
				You might have noticed that messages pop up as you create/edit
				anything. To disable these messages, click on your name in the 
				sidebar.
				<div className={styles.imgContainer}>
					<img 
						src="https://d2voyh5ncb6zec.cloudfront.net/tutorialProfile.PNG"
						alt="profile page"
						className={styles.infoPic}
					/>
				</div>
				Edit the toggles to enable/disables. You can also edit your name 
				and email by double clicking on the field and editing them.
			</div>
		</div>
};
