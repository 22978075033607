import React, { Component } from 'react';
import moment from 'moment';
import { convertToRaw } from 'draft-js';

import Modal from '../../../general/modal/modals/Modal';
import DatePicker from '../../../general/misc_components/DatePicker';
import TimePicker from '../../../general/misc_components/TimePicker';
import CheckAvailable from '../../../general/misc_components/CheckAvailable';
import RichEditor from '../../../general/misc_components/RichEditor';
import MembersSelector from './MembersSelector';
import OccuringSelector from '../../../general/misc_components/OccuringSelector';

import styles from './css/createprojecttodo.module.css';

const now = moment().startOf('day');

export default class CreateProjectTodo extends Component {
	constructor(props){
		super(props);
		this.state = {
			name: '',
			description: '',
			start_date: now,
			start_time: '12:00 AM',
			end_date: now,
			end_time: '12:00 AM',
			notes: '',
			assigned_users: [],
			reoccuring: false,
			reoccuringNum: '',  
			reoccurUntil: now,   
			reoccurUnit: DAY, 
			errors: { 
				name: false,
				reoccuring: true
			},
			warnings: { 
				start_time: false
			}
		};
		this.all_todos = this.getAllTodos();
	} 

	handleChange = e => {
		const { name, value } = e.target;
		this.setState({[name]:value});
	}

	updateDate = (name, value) => {
		const date = moment(value, 'YYYY-MM-DD');
		if(name==='start_date'){
			this.setState({
				[name]: date,
				end_date: date,
				reoccurUntil: date
			});
		} else {
			this.setState({
				[name]: date,
			});
		}	
		// this.setState({
		// 	[name]: date
		// });

	}

	updateTime = (name, value) => {
		this.setState({[name]:value});
	}

	setEditorState = state => {
		this.setState({notes: state});
	}

	getDaysFromUnit = (num, unit) => {
		const n = Math.abs(num)
		if(unit===DAY) return n;
		else if(unit===WEEK) return n*7;
		else if(unit===MONTH) return n*30; 
	}

	getAllTodos = () => {
		const project_todos = [];
		this.props.projects.forEach(p=>project_todos.push(p.events));
		// console.log([
		// 	...this.props.all_todos,
		// 	...project_todos
		// ])
		return [
			...this.props.all_todos,
			...project_todos
		];
	}

	checkWarnings = () => {
		const { start_date, start_time } = this.state;
		let isWarning = false;
		this.all_todos.map(todo => {
			const start = moment(todo.start_time);
			const end = moment(todo.end_time);
			if(moment(start_date+" "+start_time, 'YYYY-MM-DD h:mm A').isBetween(start, end)){
				isWarning = true;
				this.setState(prevState => {
					return ({
							warnings: {
								...prevState.warnings,
								start_time: true
							}});
				});
			}
			return 0;
		})
		return isWarning;
	}

	checkErrors(){
		if(this.state.name === ''){
			this.setState(prevState => {
				return {
					errors: {
						...prevState.errors,
						name: 'Name cannot be empty'
					}
				}
			});
		} else {
			this.setState(prevState => {
				return {
					errors: {
						...prevState.errors,
						name: "" 
					}
				}
			})
		} 
	}

	handleSubmit = async () => {
		await this.checkErrors();
		const oldState = this.state.warnings.start_time;
		const isWarning = this.checkWarnings();
		if(isWarning && !oldState){
			return;
		}
		if(!this.state.errors.name && 
			(this.state.reoccuring?!this.state.errors.reoccuring:true)
		){
			const { start_date, start_time, end_date, end_time } = this.state;
			const assigned_users = this.state.assigned_users.map(m=>m.id);
			const start = moment(start_date.format('YYYY-MM-DD')+" "+start_time, 'YYYY-MM-DD h:mm A').toDate();
			const todo = {
				name: this.state.name,
				description: this.state.description,
				start_time: start,
				end_time: moment(end_date.format('YYYY-MM-DD')+" "+end_time, 'YYYY-MM-DD h:mm A').toDate(), 
				notes: JSON.stringify(convertToRaw(this.state.notes.getCurrentContent())),
				assigned_users,
				reoccuring: this.state.reoccuring,
				repeat_days: this.getDaysFromUnit(this.state.reoccuringNum, this.state.reoccurUnit),
				repeat_until: this.state.reoccurUntil,
				original_start: start 
			};

			this.props.data.handleAdd(todo, this.props.data);
			this.props.hideModal();
		}
	}

	render() {
		const { start_date, end_date } = this.state;

		return ( 
			<Modal
				hideModal={this.props.hideModal}
				width="65vw" 
				maxWidth="900px"
				maxHeight="760px"
				textAlign="center"
				top={true}
				topText="Create Event"
			>
				<div className={styles.form}>
					<div className={styles.txtInput}>
						<input 
							className={`${styles.nameInput} ${this.state.errors.name?styles.error:null}`}
							onChange={this.handleChange}
							value={this.state.name}
							placeholder="Name"
							name="name"
							autoComplete="Off"
						/>
						<input 
							className={styles.descriptionInput}
							onChange={this.handleChange}
							value={this.state.description}
							placeholder="Description"
							name="description"
							autoComplete="Off"
						/>
					</div>
					<div className={styles.elementContainer}>
						<div className={styles.desktopLeft}>
							<img 
								src="https://d2voyh5ncb6zec.cloudfront.net/clock.svg"
								className={styles.desktopClock} 
								alt="clock"
							/>

							{/*START REGULAR DATE TIME PICKER*/}
							{!this.state.reoccuring?
								<div className={styles.start}>
									<img 
										src="https://d2voyh5ncb6zec.cloudfront.net/clock.svg" 
										alt="start time" 
										className={styles.clockPic}
									/>
									<h2 className={styles.subtitle}>Start</h2>
									<div className={styles.dateTimeInputRange}>
										<div className={styles.datePickerContainer}>
											<DatePicker 
												updateDate={this.updateDate} 
												name="start_date"
												
												warning={this.state.warnings.start_time}
											/>
										</div>
										<TimePicker 
											updateTime={this.updateTime} 
											name="start_time"
											warning={this.state.warnings.start_time}
										/>
									</div>
								</div>
								:null
							}

							{!this.state.reoccuring?
								<div className={styles.end}>
									<img 
										src="https://d2voyh5ncb6zec.cloudfront.net/clock.svg" 
										alt="end time" 
										className={styles.clockPic}
									/>
									<h2 className={styles.subtitle}>End</h2>
									<div className={styles.dateTimeInputRange}>
										<div className={styles.datePickerContainer}>
											<DatePicker 
												updateDate={this.updateDate} 
							        			name="end_date" 
							        			data={this.state.end_date.toDate()}
							        			minDate={this.state.start_date.toDate()}
											/>
										</div>
										<TimePicker updateTime={this.updateTime} name="end_time"/>
									</div>
								</div>
								:null
							}
							{/*END REGULAR DATE TIME PICKER*/}

							{/*START REOCCURING DATE TIME PICKER*/}

							{this.state.reoccuring?
								<div className={styles.start}>
									<img 
										src="https://d2voyh5ncb6zec.cloudfront.net/clock.svg" 
										alt="start time" 
										className={styles.clockPic}
									/>
									<h2 className={styles.subtitle}>Start</h2>
									<div className={styles.dateTimeInputRange}>
										<div className={styles.datePickerContainer}>
											<DatePicker 
												updateDate={this.updateDate} 
												name="start_date"
												warning={this.state.warnings.start_time}
												data={this.state.start_date.toDate()}
											/>
										</div>
										<TimePicker 
											updateTime={this.updateTime} 
											name="start_time"
											warning={this.state.warnings.start_time}
										/>
									</div>
									{this.state.reoccuring?
										<div className={styles.info}>
											This is the date the reoccuring event will start 
										</div>
										:null
									}
								</div>
								:null
							}

							{this.state.reoccuring?
								<div className={styles.end}>
									<img 
										src="https://d2voyh5ncb6zec.cloudfront.net/clock.svg" 
										alt="end time" 
										className={styles.clockPic}
									/>
									<h2 className={styles.subtitle}>End</h2>
									<div className={styles.dateTimeInputRange}>
										<div className={styles.datePickerContainer}>
											<DatePicker 
												updateDate={this.updateDate} 
							        			name="end_date" 
							        			data={this.state.end_date.toDate()}
							        			minDate={this.state.start_date.toDate()}
											/>
										</div>
										<TimePicker updateTime={this.updateTime} name="end_time"/>
									</div>
									{this.state.reoccuring?
										<div className={styles.info}>
											This is the date the reoccuring event
											will end 
										</div>
										:null
									}
								</div>
								:null
							}

							{this.state.reoccuring?
								<div className={styles.end}>
									<h2 className={styles.subtitle}>Reoccuring end</h2>
									<div className={styles.dateTimeInputRange}>
										<div 
											className={styles.datePickerContainer}
											style={{margin:'0 auto'}}
										>
											<DatePicker 
												updateDate={this.updateDate} 
							        			name="reoccurUntil" 
							        			data={this.state.start_date.toDate()}
							        			minDate={this.state.start_date.toDate()}
											/>
										</div>
									</div>
									{this.state.reoccuring?
										<div className={styles.info}>
											This is the date when the event will
											stop reoccuring. Set this to the start
											date for infinitely occuring events.
										</div>
										:null
									}
								</div>
								:null
							}

							{/*END REOCCURING DATE TIME PICKER*/}

							<div className={styles.reoccuringContainer}>
								<OccuringSelector 
									reoccuring={this.state.reoccuring}
									start_date={start_date}
									end_date={end_date}
									getDaysFromUnit={this.getDaysFromUnit}
									setReoccuring={val=>this.setState({reoccuring: val})}
									setReoccuringDays={days=>this.setState({reoccuringNum: days})}
									setReoccuringUnit={unit=>this.setState({reoccurUnit: unit})}
									setError={(val)=>this.setState(prevState=>{
										return {
											errors: {
												...prevState.errors,
												reoccuring: val
											}
										}
									})}
								/>
							</div>

							<div>
								<h3 className={styles.desktopSubtitle}>Assigned Users:</h3>
								<MembersSelector 
									member_details={this.props.data.member_details}
									updateParent={this.updateTime}
									name="assigned_users"
									added={this.state.assigned_users}
								/>
							</div>
							<div className={styles.richEditorRegion}>
								<RichEditor setEditorState={this.setEditorState} height="275px"/>
							</div>
						</div>
						<div className={styles.availableContainer}>
							<CheckAvailable 
								all_todos={this.all_todos} 
			        			date={this.state.start_date.toDate()}
							/>
						</div> 
					</div>
					<button className={styles.submit} onClick={this.handleSubmit}>
						Create
					</button>
				</div>
			</Modal>
		);
	}
}

const DAY = "DAY";
const WEEK = "WEEK";
const MONTH = "MONTH";


