import React, { Component } from 'react';
import moment from 'moment';

import DashTodo from '../../todo/components/DashTodo';
import Task from '../../task/components/Task';

import styles from './css/completed.module.css';


export default class CompletedItems extends Component {
	render() {
		const now = moment();
		const events = this.props.todos.filter(todo=>{
			return (moment(todo.start_time).isBefore(now, 'day') || todo.complete)
		}).map(todo=>{
			return(
					<DashTodo 
						todo={todo}
						key={todo._id}
						showContext={this.props.showContext}
						setCursorPos={this.props.setCursorPos}
						handleEdit={this.props.editTodo}
						handleDelete={this.props.deleteTodo}
					/>
				);
		});

		const tasks = this.props.tasks.filter(task=>{
			if(moment(task.due_time).isBefore(now, 'day') || task.complete){
				return true;
			}
			return false;
		}).map(task=>{
			return(
				<Task 
					task={task}
					key={task._id}
					showContext={this.props.showContext}
					setCursorPos={this.props.setCursorPos}
					editTask={this.props.editTask}
				/>
			);
		});

		return (
			<div className={styles.split}>
				<div className={styles.containerL}>
					<p className={styles.title}>Events</p>
					<div className={`${styles.todoContainer}`}>
						{events}
					</div>
				</div>
				<div className={styles.containerR}>
					<p className={styles.title}>Tasks</p>
					<div className={`${styles.todoContainer}`}>
						{tasks}
					</div>
				</div>
			</div>
		);
	}
}
