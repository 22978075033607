import React, { Component } from 'react';

import LandingTopMenu from './LandingTopMenu';
import MobileMenu from './MobileMenu';

import styles from './css/blog.module.css';

export default class Blog extends Component {
	constructor(props){
		super(props);
		this.state = {
			openMobileMenu: false,
			menuClosingAnimation: false
		}
	}

	closeMenu = () => {
		this.setState({menuClosingAnimation: true});
		setTimeout(()=>{
			this.setState({openMobileMenu: false, menuClosingAnimation: false});
		}, 500);
			
	}

	render() {
		return (
			<div>
				<LandingTopMenu 
					openMenu={()=>this.setState({openMobileMenu: true})}
				/>
				<div className={styles.content}>
					<div className={styles.title}>Blog Coming Soon!</div>
					<img 
						src="https://d2voyh5ncb6zec.cloudfront.net/LandingUnderConstruction.png"
						alt="coming soon"
						className={styles.construction}
					/>
				</div>
				{this.state.openMobileMenu?
					<div className={styles.menuContainer}>
						<MobileMenu 
							closeMenu={this.closeMenu} 
							closeAnimation={this.state.menuClosingAnimation}
						/>
					</div>
					:null
				}
			</div>
		);
	}
}
