import React, { Component } from 'react';
import moment from 'moment';
import { convertToRaw, convertFromRaw } from 'draft-js';

import Modal from './Modal';
import CategorySelector from '../../misc_components/CategorySelector';
import DatePicker from '../../misc_components/DatePicker';
import TimePicker from '../../misc_components/TimePicker';
import RemindSelector from '../../misc_components/RemindSelector';
import RichEditor from '../../misc_components/RichEditor';

import styles from '../css/createtask.module.css';

export default class EditTask extends Component {
	constructor(props){
		super(props);
		this.state = {
			name:  this.props.task.name,
			description: this.props.task.description,
			categories: this.props.task.categories,
			due_date: moment(this.props.task.due_time).format('YYYY-MM-DD'),
			due_time: moment(this.props.task.due_time).format('h:mm A'),
			remind_time: moment(this.props.task.remind_time),
			notes: convertFromRaw(JSON.parse(this.props.task.notes)),
			name_error: false
		};
	}

	handleChange = e =>{
		const { name, value } = e.target;
		this.setState({
			[name]: value
		});
	}

	getCategories = () => {
		let categories = [];
		this.props.all_todos.forEach(todo => {
			todo.categories.forEach(category => {
				let res = categories.map(e => e.name);
				if(!res.includes(category.name)){
					categories.push(category);
				}
			})
		})
		return categories;
	}

	updateState = (name, val) => {
		this.setState({[name]: val});
	}

	setEditorState = state => {
		this.setState({notes: state});
	}	

	setWrapperRef = node =>{
		this.wrapperRef = node;
	}

	checkErrors = () => {
		this.setState({name_error: this.state.name===''});
	}

	handleSubmit = async () =>{
		await this.checkErrors();
		if(this.state.name_error){
			return;
		}
		const task = {
			name: this.state.name,
			description: this.state.description,
			categories: this.state.categories,
			due_time: moment(this.state.due_date+" "+this.state.due_time, 'YYYY-MM-DD h:mm A').toDate(),
			remind_time: this.state.remind_time,
			notes: JSON.stringify(convertToRaw(this.state.notes.getCurrentContent())),
			cleared: false
		};
		this.props.editTask(task, this.props.task._id);
		this.props.hideModal();
	}

	render() {
		return (
			<Modal
				hideModal={this.props.hideModal}
				width="60vw"
				maxWidth="600px"
				height="80vh"
				maxHeight="660px"
				textAlign="center"
				top={true}
				topText="Edit Task"
			>
				<form className={styles.taskForm}>
					<div className={styles.formContainer}>
						<input 
							autoComplete="off"
			        		className={`${styles.taskInput} ${this.state.name_error ? styles.error : null}`} 
			        		name="name"
			        		value={this.state.name}
			        		onChange={this.handleChange}
			        		placeholder="Name"
						/>
						<input 
							autoComplete="off"
			        		className={`${styles.taskDescription}`} 
			        		name="description"
			        		value={this.state.description}
			        		onChange={this.handleChange}
			        		placeholder="Description"
						/>
					</div>
					<CategorySelector 
						all_categories={this.getCategories()}
		        		updateState={this.updateState}
		        		data={this.props.task.categories}
		        	/>
					<div className={`${styles.mt} ${styles.remindContainer}`}>
		        		<div className={styles.dateContainer}>
							<DatePicker 
				        		updateDate={this.updateState} 
				        		name="due_date"
				        		data={moment(this.props.task.due_time).toDate()} 
				        	/>
			        	</div>
			        	<div className={styles.timeContainer}>
			        		<TimePicker updateTime={this.updateState} name="due_time" data={this.state.due_time}/>
			        	</div>
					</div>
					<div className={styles.mtl}>
			        	<RemindSelector 
			        		updateParent={this.updateState}
			        		name="remind_time"
			        		display={this.state.remind_time}
			        		data={{date:moment(this.state.due_date+" "+this.state.due_time, 'YYYY-MM-DD h:mm A')}}
			        	/>
			        </div>
					<div className={styles.mt}>
						<RichEditor setEditorState={this.setEditorState} data={this.state.notes} height="300px"/>
					</div>
					<div className={styles.bottom}>
						<button 
							type="button" 
							onClick={this.handleSubmit}
							className={styles.createBtn}
						>
							Save
						</button>
					</div>
				</form>
			</Modal>
		);
	}
}




