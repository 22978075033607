import { SET_FRIENDS } from './actionTypes';
import axios from 'axios';

function getConfig(){
	return {
		headers: {
			token: localStorage.getItem('token')
		}
	};
}

export const encode = buffer => {
	const d = new Uint8Array(buffer);
	const STRING_CHAR = d.reduce((data, byte)=> {
		return data + String.fromCharCode(byte);
	}, '');
	return btoa(STRING_CHAR);
}

export const getProfileFromId = async(id) =>{
	const res = await axios.post('https://taskcence.herokuapp.com/api/profile/getURL', {id}, getConfig());
	return axios.get(res.data.signedURL, {responseType:"arraybuffer"})
		.then(res=>{return res.data});
}

export const getFriends = () =>{
	return function(dispatch){
		return axios.get('https://taskcence.herokuapp.com/api/friend/getAll', getConfig())
			.then(res=>{
				const friends = res.data.friends.map(async (friend)=>{
					const ab = await getProfileFromId(friend.id);
					const profile_pic = "data:image/jpeg;base64,"+encode(ab);
					return {
						...friend,
						profile_pic
					};
				});

				Promise.all(friends)
					.then(friends=>dispatch({type: SET_FRIENDS, payload:friends}));
			})
			.catch(err=>console.log(err));
	}
}

