import React, { Component } from 'react';
import moment from 'moment';
import { Portal } from 'react-portal';
import { connect } from 'react-redux';
import _ from 'lodash';
import axios from 'axios';
import io from 'socket.io-client';

import NotificationBar from './NotificationBar';

import { editTodo } from '../../todo/actions';
import { editTask } from '../../task/actions';
import { editProjectTodo } from '../../project/actions';
import { 
	addNotification, 
	hideNotiBar, 
	showNotiBar, 
	clearNotification, 
	clearAllNotifications,
	setNotifications 
} from '../actions';

const ENDPOINT = 'https://taskcence.herokuapp.com/friend';

class NotificationManager extends Component {
	componentDidMount(){
		this.getAllNotifications();

		this.interval = setInterval(()=>{
			if(new Date().getSeconds()===0){
				this.checkNotifications();
			}
		},1000);
	}

	componentDidUpdate(prevProps, prevState) {
		if(!_.isEqual(prevProps.all_items, this.props.all_items)){
			this.checkNotifications();
		}
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	getAllNotifications = async () => {
		const res = await axios.get(
						'https://taskcence.herokuapp.com/api/notification/getAll', 
						{headers:{token:localStorage.getItem('token')}}
					);
		let notifications = [];
		res.data.notifications.forEach(id=>{
			const item = _.find(this.props.all_items, {'_id':id});
			if(typeof id==='object'){notifications.push(id)}
			else if(item){notifications.push(item)}
		})


		this.props.setNotifications(notifications);
	}

	checkNotifications = () => {
		const now = moment();
		const notifications = this.props.all_items.filter(item => {
			if(item.cleared || item.assigned_users){return false}
			return moment(item.remind_time).isSame(now, 'minute');
		});
		notifications.forEach(item=>{
			this.props.addNotification(item);
		});
		if(notifications.length>0){
			this.props.showNotiBar();
		}
	}

	handleFriend = (req, action) => {
		const socket = io(ENDPOINT);
		const data = {...req, token: localStorage.getItem('token')};
		socket.emit('friend-request-'+action, data);
		this.props.clearNotification(req._id);
	}

	render() {
		return (
			this.props.showBar?
			<Portal>
				<NotificationBar 
					notifications={this.props.notifications}
					hideNotiBar={this.props.hideNotiBar}
					showBar={this.props.showBar}
					clearNotification={this.props.clearNotification}
					clearAllNotifications={this.props.clearAllNotifications}
					editTodo={this.props.editTodo}
					editTask={this.props.editTask}
					handleFriend={this.handleFriend}
					editProjectTodo={this.props.editProjectTodo}
				/>
			</Portal>
			: null
		); 
	}
}

const mapStateToProps = state => {
	let project_todos = [];
	state.project.data.forEach(project=>{
		project.events.forEach(event=>{
			project_todos.push(event);
		});
	});
	return {
		all_items: state.todos.data.concat(state.tasks.data).concat(project_todos),
		notifications: state.notification.data,
		showBar: state.notification.show
	};
}

const mapDispatchToProps = {
	addNotification,
	hideNotiBar,
	showNotiBar,
	clearNotification,
	clearAllNotifications,
	setNotifications,
	editTodo,
	editTask,
	editProjectTodo
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationManager);
