import React, { Component } from 'react';
import { CirclePicker } from 'react-color';

import styles from './css/category.module.css';

export default class CategorySelector extends Component {
	constructor(props){
		super(props);
		this.state = {
			categories: this.props.data ? this.props.data : [],
			inputText: "",
			showNew: false,
			passedCategories: this.props.all_categories,
			colorChooser: {
				show: false,
				color: "#0062B1"
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if(JSON.stringify(prevState.categories)!==JSON.stringify(this.state.categories)){
			this.updateParent();
		}
	}

	updateParent = (name, value) => {
		this.props.updateState('categories', this.state.categories)
	}

	found = () => {
		return this.state.passedCategories.find(category => category.name===this.state.inputText);
	}

	isInCategories = name => {
		return this.state.categories.find(category => category.name===name);
	}

	getResults = () => {
		let results = [];
		for(let i=0;i<this.state.passedCategories.length;i++){
			let curr = this.state.passedCategories[i];
			if(curr.name.indexOf(this.state.inputText) > -1){
				results.push(curr);
			}
		}
		return results;
	}

	onKeyDown = async (e) => {
		if(e.keyCode === 32){
			const selected = this.getResults()[0];
			if(selected && !this.isInCategories(selected.name)){
				this.setState(prevState => {
					return ({
						categories: [...prevState.categories, {name: selected.name, color: selected.color}]
					});
				});
			}
			this.setState({inputText: ""});
			e.preventDefault();
		} else if(e.keyCode === 8 && e.target.selectionStart===0 && this.state.categories.length>0){
			e.preventDefault();
			const { categories } = this.state;
			await this.setState({inputText: categories[categories.length-1].name});
			this.setState(prevState => {return({categories: prevState.categories.slice(0,prevState.categories.length-1)})});
		}
	}

	handleResultClick = e => {
		const name = e.target.getAttribute("name");
		const color = e.target.getAttribute("color");
		if(!this.isInCategories(name)){
			this.setState(prevState => {
					return ({
						categories: [...prevState.categories, {name, color}]
					});
				});
		}
		this.setState({inputText: ""});
		
	}

	handleCreate = e => {
		const name = this.state.inputText;
		const color = this.state.colorChooser.color;
		this.setState(prevState => {
			return({
				categories: [
					...prevState.categories,
					{name, color}
				],
				inputText: "",
				passedCategories: [...prevState.passedCategories, {name, color}]
			});
		});
	}

	handleColorClick = e => {
		e.stopPropagation();
		this.setState(prevState => {
			return({
				colorChooser: {
					...prevState.colorChooser,
					show: !prevState.colorChooser.show
				}
			});
		})
	}

	handleColorChange = (color, e) => {
		this.setState(prevState => {
			return({
				colorChooser: {
					show: false,
					color: color.hex
				}
			});
		})
	}

	handleChange = e => {
		const { name, value } = e.target;
		this.setState({
			[name]: value
		});
	}

	renderResults = () => {
		if(this.getResults().length===0){
			return (
				<div>
					<div 
						className={styles.inputDialog} 
						style={{width: this.input.offsetWidth}}
						onClick={this.handleCreate}
					>
						<div className={styles.newCategoryContainer}>
							<p>Category not found.&nbsp; 
								<span 
									className={styles.bold} 
								>
									Create {this.state.inputText}
								</span>
							</p>
							<div 
								className={styles.colorChooser} 
								style={{backgroundColor: this.state.colorChooser.color}}
								onClick={this.handleColorClick}
							>
							</div>
						</div>
					</div>
					{this.state.colorChooser.show ? 
							<div className={styles.colorChooserDialog}>
								<CirclePicker onChangeComplete={this.handleColorChange}/>
							</div>
							: null
					}
				</div>
			);
		} else {
			const results = this.getResults();
			return (
				<div className={styles.inputDialog} style={{width: this.input.offsetWidth}}>
					<ul className={styles.resultsContainer}>
						{results.map((category, index) => {
							return(
								<li 
									key={index} 
									color={category.color}
									onClick={this.handleResultClick}
									name={category.name}
								>
									<div style={{backgroundColor:category.color}} className={styles.liBefore}></div>
									{category.name}
								</li>
							);
						})}
					</ul>
				</div>
			);
		}
	}

	render() {
		return (
			<div className={styles.categoryContainer}>
				<div className={styles.inputContainer} ref={el => (this.input=el)}>
					<div className={styles.categories}>
						{this.state.categories.map((category, index) => {
							return(
								<div 
									style={{color: category.color, border:"1px solid "+category.color}} 
									className={styles.category}
									key={index}
								>
									<span>{category.name}</span>
								</div>
							);
						})}
					</div>
					<input 
						className={styles.input}
						autoComplete="off"
						name="inputText" 
						value={this.state.inputText}
						onChange={this.handleChange}
						onKeyDown={this.onKeyDown}
						maxLength="50"
						placeholder="Categories"
					/>
				</div>
				{this.state.inputText ? this.renderResults() : null}
			</div>
		);
	}
}
