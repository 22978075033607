import React, { Component } from 'react';
import io from 'socket.io-client';
import { connect } from 'react-redux';
import { Portal } from 'react-portal';

import FriendBar from './FriendBar';
import { SUCCESS } from '../../message/actionTypes';
import { setMessage } from '../../message/actions';

import styles from './css/friendbar.module.css';

let socket;
const ENDPOINT = 'https://taskcence.herokuapp.com/friend';

class FriendManager extends Component {
	constructor(props){
		super(props);
		this.state = {
			showBar: false,
			friends: []
		};
	}

	componentWillUnmount(){
		if(socket){
			socket.emit('disconnect');
			socket.off();
		}
	}

	handleAddFriend = to_id => {
		const data = {
			token: localStorage.getItem('token'),
			to_id
		};
		socket = io(ENDPOINT);
		socket.emit('friend-request', data);
		this.props.setMessage("Friend request sent", SUCCESS);
	}

	hideBar = () => {
		this.setState({showBar: false});
	}

	render() {
		return (
			<div>
				<div onClick={()=>this.setState({showBar: true})} className={styles.friendBtn}>	
					<img 
						className={styles.friendImg}
						src="https://d2voyh5ncb6zec.cloudfront.net/friends.svg" 
						alt="friends" 
					/>
				</div>
				{this.state.showBar?
					<Portal>
						<FriendBar 
							friends={this.props.friends}
							hideBar={this.hideBar}
							handleAddFriend={this.handleAddFriend}
							id={this.props.id}
						/>
					</Portal>
					:null 
				}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		friends: state.friend.data,
		id: state.auth.id
	};
}

const mapDispatchToProps = {
	setMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(FriendManager);