import React, { Component } from 'react';
import anime from 'animejs/lib/anime.es.js';

import styles from './css/message.module.css'

export default class Message extends Component {
	componentDidMount(){
		setTimeout(this.animateUnmount, 2000)
		this.props.startTimer();
		anime({
			targets: this.wrapperRef,
			translateY: 150,
			easing: 'spring(1, 80, 30, 30)' 
		});
	}

	animateUnmount = () => {
		anime({
			targets: this.wrapperRef,
			translateY: -150,
			duration: 1000 
		});
	}

	render() {
		return (
			<div 
				ref={node=>this.wrapperRef=node}
				className={`${styles[this.props.messageType]} ${styles.message}`}
			>
				<p className={styles.messageText}>{this.props.message}</p>
			</div>
		);
	}
}
