import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Portal } from 'react-portal';

import CreateTodoModal from './modals/CreateTodoModal';
import UpdateTodoModal from './modals/UpdateTodoModal';
import ConfirmDelete from './modals/ConfirmDelete';
import CreateTask from './modals/CreateTask';
import EditTask from './modals/EditTask';
import CreateProject from './modals/CreateProject';
import AddMembers from '../../project/components/general/AddMembers';
import CreateProjectTodo from '../../project/components/general/CreateProjectTodo';
import EditProjectTodo from '../../project/components/general/EditProjectTodo';
import AddRoadmapSection from '../../project/components/general/AddRoadmapSection';
import AddRoadmapItem from '../../project/components/general/AddRoadmapItem';
import UpdateStatus from '../../project/components/roadmap/UpdateStatus';

import { 
	ADD_TODO_MODAL, 
	EDIT_TODO_MODAL,
	CONFIRM_DELETE_MODAL,
	ADD_TASK, 
	EDIT_TASK,
	ADD_PROJECT,
	ADD_MEMBER,
	ADD_PROJECT_TODO,
	EDIT_PROJECT_TODO,
	ADD_ROADMAP_SECTION,
	ADD_ROADMAP_ITEM,
	UPDATE_ITEM_STATUS 
} from './modalTypes';

import { hideModal } from './actions'
import { addTodo, editTodo, } from '../../todo/actions';
import { addTask, editTask, } from '../../task/actions';
import { createProject } from '../../project/actions';

class ModalManager extends Component {
	render() {
		switch(this.props.modalType){
			case ADD_TODO_MODAL:
				return(
					<Portal>
						<CreateTodoModal 
							data={this.props.data}
							hideModal={this.props.hideModal}
							all_todos={this.props.all_todos}
							projects={this.props.projects}
						/>
					</Portal>
				);

			case EDIT_TODO_MODAL:
				return(
					<Portal>
						<UpdateTodoModal 
							hideModal={this.props.hideModal}
							data={this.props.data}
							editTodo={this.props.editTodo}
							//bad naming, all_todos is only todos
							all_todos={this.props.all_todos}
							//todos- combination of todos and tasks
							todos={this.props.all_todos.concat(this.props.tasks)}
							projects={this.props.projects}
						/>
					</Portal>
				);

			case CONFIRM_DELETE_MODAL:
				return(
					<Portal>
						<ConfirmDelete
							hideModal={this.props.hideModal}
							// Todo- todo or task
							data={this.props.data}
						/>
					</Portal>
				); 

			case ADD_TASK:
				return(
					<Portal>
						<CreateTask 
							hideModal={this.props.hideModal}
							all_todos={this.props.all_todos.concat(this.props.tasks)}
							addTask={this.props.addTask}
						/>
					</Portal>
				);

			case EDIT_TASK:
				return(
					<Portal>
						<EditTask 
							task={this.props.data}
							hideModal={this.props.hideModal}
							all_todos={this.props.all_todos.concat(this.props.tasks)}
							editTask={this.props.editTask}
						/>
					</Portal>
				);

				case ADD_PROJECT:
					return(
						<Portal>
							<CreateProject 
								hideModal={this.props.hideModal}
								friends={this.props.friends}
								createProject={this.props.createProject}
								id={this.props.id}
							/>
						</Portal>
					);

				case ADD_MEMBER:
					return(
						<Portal>
							<AddMembers 
								data={this.props.data}
								hideModal={this.props.hideModal}
							/>
						</Portal>
					);

				case ADD_PROJECT_TODO:
					return(
						<Portal>
							<CreateProjectTodo 
								hideModal={this.props.hideModal}
								all_todos={this.props.all_todos}
								data={this.props.data}
								projects={this.props.projects}
							/> 
						</Portal>
					);

				case EDIT_PROJECT_TODO:
					return(
						<Portal>
							<EditProjectTodo 
								hideModal={this.props.hideModal}
								data={this.props.data}
								projects={this.props.projects}
								all_todos={this.props.all_todos}
							/>
						</Portal>
					);

				case ADD_ROADMAP_SECTION:
					return(
						<Portal>
							<AddRoadmapSection 
								hideModal={this.props.hideModal}
								data={this.props.data}
							/>
						</Portal>
					);

				case ADD_ROADMAP_ITEM:
					return(
						<AddRoadmapItem 
							hideModal={this.props.hideModal}
							data={this.props.data}
						/>
					);

				case UPDATE_ITEM_STATUS:
					return(
						<UpdateStatus 
							hideModal={this.props.hideModal}
							data={this.props.data}
						/>
					);

			default:
				return null;
		}
	}
} 

function mapStateToProps(state) {
	return {
		modalType: state.modal.type,
		data: state.modal.data,
		all_todos: state.todos.data,
		tasks: state.tasks.data,
		friends: state.friend.data,
		id: state.auth.id,
		projects: state.project.data
	}
}

const mapDispatchToProps = {
	hideModal, 
	addTodo, 
	editTodo,  
	addTask,
	editTask,
	createProject
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalManager);
