import { GET_TASKS, SET_LOADING, ADD_TASK, DELETE_TASK, EDIT_TASK } from './actionTypes';
import { SUCCESS, ERROR } from '../message/actionTypes';
import { setMessage } from '../message/actions';
import axios from 'axios';

export function getTasks(token){
	let config = {
			headers: { 
				token: token
			}
		};
	
	return function(dispatch) {
		dispatch({type: SET_LOADING, payload: true});
		return axios.get('https://taskcence.herokuapp.com/api/task', config) 
			.then(res => {
				dispatch({type: GET_TASKS, payload: res.data});
				dispatch({type: SET_LOADING, payload: false});
			})
			.catch(err => {
				console.log(err);
				dispatch(setMessage("An error occurred while loading tasks", ERROR));
			}); 
		}
}

export function addTask(task){
	let config = {
			headers: { 
				token: localStorage.getItem('token')
			}
		};
	return function(dispatch){
		return axios.post('https://taskcence.herokuapp.com/api/task/create', task, config)
			.then(res => {
				dispatch({type: ADD_TASK, payload: res.data.task});
				dispatch(setMessage("Task Successfully Created!", SUCCESS));
			})
			.catch(err => {
				console.log(err);
				dispatch(setMessage("Error while creating task", ERROR));
			});
	}
}

export function deleteTask(task){
	const task_id = task._id;
	let config = {
		headers: {
			token: localStorage.getItem('token')
		}
	};
	return function(dispatch){
		return axios.delete('https://taskcence.herokuapp.com/api/task/delete/'+task_id, config) 
			.then(res => {
				dispatch({type: DELETE_TASK, payload: task_id});
				dispatch(setMessage("Deleted Task", SUCCESS));
			})
			.catch(err => {
				console.log(err);
				dispatch(setMessage("An error occurred while deleting the task", ERROR));
			});
	}
}

export function editTask(task, task_id){
	let config = {
		headers: {
			token: localStorage.getItem('token')
		}
	};
	return function(dispatch){
		return axios.post('https://taskcence.herokuapp.com/api/task/update/'+task_id, task, config)
			.then(res => {
				dispatch({type: EDIT_TASK, payload: res.data.task});
				dispatch(setMessage("Edited Task", SUCCESS))
			})
			.catch(err => {
				console.log(err);
				dispatch(setMessage("An error occurred while editing the task", ERROR));
			}); 
	}
}

