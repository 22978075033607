import React, { Component } from 'react';
import io from 'socket.io-client';
import _ from 'lodash';

import ProjectHome from './ProjectHome';
import ProjectChat from './ProjectChat';
import ProjectRoadmap from './ProjectRoadmap';
import TextInput from '../../../general/misc_components/TextInput';

import { encode, getProfileFromId } from '../../../friend/actions';
import { getExtraMemberDetail } from '../../actions';

import { PROJECT } from '../../../navigation/components/contentSwitcherTypes';
import { PROJECT_HOME, PROJECT_CHAT, PROJECT_ROADMAP } from '../../projectShowTypes';
import { CONFIRM_DELETE_MODAL } from '../../../general/modal/modalTypes';

import styles from '../css/projectviewer.module.css';

let socket;
const ENDPOINT = 'https://taskcence.herokuapp.com/project';

export default class ProjectViewer extends Component {
	constructor(props){
		super(props); 
		this.state = {
			member_details: [],
			projectShow: PROJECT_HOME,
			online: [],
			showSettings: false
		};
		socket = io(ENDPOINT);
	}

	componentDidMount() {
		this.getOnline();
		this.getMemberDetails();
		document.addEventListener('mousedown', this.handleClickOutside);

		socket.on('delete-project', data=>{
			this.props.deleteProject(data.project_id, data.showMessage)
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevProps.project._id!==this.props.project._id || 
			prevProps.project.members.length!==this.props.project.members.length){
			this.setState({member_details: [], online: [], projectShow:PROJECT_HOME});
			this.getMemberDetails();
			this.getOnline();
		}
	}

	componentWillUnmount(){
		document.removeEventListener('mousedown', this.handleClickOutside);
		if(socket){
			socket.emit('project-logout', { 
				project_id:this.props.project._id
			});
			socket.off();
		}
	}

	handleClickOutside = e => {
		if(this.state.showSettings && this.wrapperRef && !this.wrapperRef.contains(e.target)
			&& !this.settingsPic.contains(e.target)){
			this.setState({showSettings: false});
		}
	}

	deleteProject = project => {
		const section_ids = project.roadmap.sections.map(s=>s._id);

		socket.emit('delete-project', {
			project_id: project._id,
			section_ids,
			token: localStorage.getItem('token')
		});
		this.props.setShow({
			name: PROJECT,
			data: this.props.projects[this.props.projects.length - 2]._id
		});
	}

	handleDeleteProject = e => {
		this.setState({showSettings: false});
		this.props.showModal(CONFIRM_DELETE_MODAL, {
			item: this.props.project,
			height: '225px',
			handleDelete: this.deleteProject,
			topText: "Delete Project",
			deleteText: "Are you sure you want to delete",
			deleteName: this.props.project.name,
			info: 'Everything in the project will be deleted.',
			deleteBtnTxt: 'Delete',
		});
	}

	getMember = async (member) => {
		const found = _.find(this.props.friends, {id:member});
		let m = found?found: await getExtraMemberDetail(member);
		let profile_pic = m.profile_pic?m.profile_pic:"data:image/jpeg;base64,"+encode(await getProfileFromId(member));

		return {...m, profile_pic};
	}

	getMemberDetails = () => {
		this.props.project.members.forEach(async (member)=>{
			if(member===this.props.id) return;

			const m = await this.getMember(member);

			this.setState(prevState=>{
				return {
					member_details: [
						...prevState.member_details,
						{
							...m
						}
					]
				};
			});
		});
	}

	//when another user in the project adds members
	updateMembers = members => {
		members.forEach(async (member)=>{
			const m = await this.getMember(member.id);
			this.setState(prevState=>{
				return {
					member_details: [
						...prevState.member_details,
						{
							...m
						}
					]
				}
			});
		});
	}

	getOnline = () => {
		socket.emit('project-login', {
			token:localStorage.getItem('token'), 
			project_id:this.props.project._id,
			members: this.props.project.members
		}, online=>{
			this.setState({online});
		});
	}

	changeProjectName = (name, value) => {
		this.props.changeProjectName(this.props.project, value);
	}

	getTab = () => {
		switch(this.state.projectShow){
			case PROJECT_HOME:
			default:
				return( 
					<ProjectHome 
						online={this.state.online}
						project={this.props.project}
						member_details={this.state.member_details}
						showModal={this.props.showModal}
						createProjectTodo={this.props.createProjectTodo}
						setCursorPos={this.props.setCursorPos}	
						showContext={this.props.showContext}
						editProjectTodo={this.props.editProjectTodo}
						deleteProjectTodo={this.props.deleteProjectTodo}
						friends={this.props.friends}
						addMembersToProject={this.props.addMembersToProject}
						id={this.props.id}
						removeMember={this.props.removeMember}
						socket={socket}
						updateMembers={this.updateMembers}
					/>
				);

			case PROJECT_CHAT:
				return(
					<ProjectChat 
						project={this.props.project}
						id={this.props.id}
						username={this.props.username}
						name={this.props.name}
						socket={socket}
						member_details={this.state.member_details}
						profile_pic={this.props.profile_pic}
					/>
				);

			case PROJECT_ROADMAP:
				return(
					<ProjectRoadmap 
						showModal={this.props.showModal}
						project={this.props.project}
						member_details={this.state.member_details}
						addRoadmapItem={this.props.addRoadmapItem}
						editRoadmapItem={this.props.editRoadmapItem}
						delRoadmapItem={this.props.delRoadmapItem}
						id={this.props.id}
						username={this.props.username}
						name={this.props.name}
						profile_pic={this.props.profile_pic}
						setCursorPos={this.props.setCursorPos}	
						showContext={this.props.showContext}
						addRoadmapSection={this.props.addRoadmapSection}
						delRoadmapSection={this.props.delRoadmapSection}
						editRoadmapSection={this.props.editRoadmapSection}
						socket={socket}
						context_menu={this.props.context_menu}
						modal={this.props.modal}
					/>
				);
		}
	}

	getTabStyles = name => 
		this.state.projectShow===name?
		{
			fontWeight: 'bold',
			backgroundColor: '#dbdbd7' 
		}
		:{}


	render() { 
		const tabs = [
			{name:PROJECT_HOME, tabName:'Home'}, 
			{name:PROJECT_CHAT, tabName:'Chat'},
			{name:PROJECT_ROADMAP, tabName:'Roadmap'}
		];
		return (        
			<div className={styles.projectViewContainer}>
				{/*project tabs*/}
				{this.state.projectShow===PROJECT_HOME?
					this.props.id===this.props.project.owner_id?
					<TextInput 
						bold={false}
						fontSize={30}
						minLength={0}
						name={'projectName'}
						val={this.props.project.name}
						width={'100%'}
						updateParent={this.changeProjectName}
					/> 
					:
					<div>
						<h1 className={styles.projectTitle}>{this.props.project.name}</h1>
					</div>
					:null
				}

				<div className={styles.projectTabs}>
					<div className={styles.tabContainer}>
						{tabs.map((tab, index)=>
							<div 
								className={styles.tab}
								onClick={()=>this.setState({projectShow: tab.name})} 
								key={index}
								style={this.getTabStyles(tab.name)}
							>
								{tab.tabName}
							</div>
						)}
					</div>
					{this.props.project.owner_id===this.props.id?
						<div className={styles.settingsContainer}>
							<img 
								src="https://d2voyh5ncb6zec.cloudfront.net/settings.svg"
								alt="settings"
								className={styles.settings}
								onClick={()=>this.setState({showSettings: !this.state.showSettings})}
								ref={node=>this.settingsPic=node}
							/>
							{this.state.showSettings?
								<div className={styles.settingsResults} ref={node=>this.wrapperRef=node}>
									<div 
										className={`${styles.result} ${styles.red}`} 
										onClick={this.handleDeleteProject}
									>
										Delete Project
									</div> 
								</div>
								:null
							}
						</div>
						:null
					}

				</div>
				{/*end project tabs*/}
				<div className={styles.projectContent}>
					{this.getTab()}
				</div>				
			</div>
		);
	}
}
