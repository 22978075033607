import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { convertFromRaw } from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import ReactTooltip from "react-tooltip";

import { 
	CONFIRM_DELETE_MODAL, 
	ADD_ROADMAP_ITEM,
	UPDATE_ITEM_STATUS 
} from '../../../general/modal/modalTypes';

import { getTime } from '../../../general/misc_components/times';

import styles from './css/sectioninfo.module.css';

export default class ItemInfo extends Component {
	constructor(props){
		super(props);
		this.state = {
			context_height: 0
		};
	}   

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
		this.setState({context_height: this.wrapperRef.clientHeight});
	}  

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside = e =>{
		if(this.wrapperRef && !this.wrapperRef.contains(e.target)){
			this.props.closeContext();
		}
	}

	handleStatus = () => {
		this.props.showModal(UPDATE_ITEM_STATUS, {
			topText: 'Update Item Status',
			item: this.props.data.item,
			editRoadmapItem: this.props.data.editRoadmapItem,
			project_id: this.props.data.project_id
		});
		this.props.closeContext();
	}

	handleEdit = () =>{
		this.props.showModal(ADD_ROADMAP_ITEM, {
			...this.props.data.item,
			handleSubmit: this.props.data.editRoadmapItem,
			project_id: this.props.data.project_id,
			project: this.props.data.project,
			id: this.props.data.id,
			member_details: this.props.data.member_details,
			btnName: 'Save',
			topText: 'Edit Item'
	 	});
		this.props.closeContext();
	} 

	handleDelete = () =>{
		this.props.showModal(CONFIRM_DELETE_MODAL, {
			handleDelete: this.props.data.delRoadmapItem,
			topText: "Delete Item",
			deleteText: "Are you sure you want to delete",
			deleteName: this.props.data.item.name,
			deleteBtnTxt: 'Delete',
			item: {
				item: this.props.data.item,
				project_id: this.props.data.project_id
			}

		});
		this.props.closeContext();
	}

	getNotes = str => {
		const editorState = convertFromRaw(JSON.parse(str));
		let html = stateToHTML(editorState);
		return(html);
	} 

	renderAssignedUsers = ids => {
		return ids.map(id=>{
			const user = _.find(this.props.data.member_details, {id});
			if(user){
				return(
					<div className={styles.user} key={user.id} data-tip data-for={user.id}>
						<img src={user.profile_pic} alt="profile" className={styles.profilePic}/>
						<ReactTooltip id={user.id}>{`${user.username} (${user.name})`}</ReactTooltip>
					</div>
				);
			}

			return undefined;
		});
	}

	render() {
		let posX = this.props.cursorX;
		let posY = this.props.cursorY;
		const owner = this.props.data.owner;
		const item = this.props.data.item;
		if(this.props.cursorX+370 > window.innerWidth){
			posX = posX - (370-(window.innerWidth-posX));
		}
		if(this.props.cursorY+this.state.context_height>window.innerHeight){
			posY = posY - (this.state.context_height-(window.innerHeight-posY))
		} 

		const notes = item.notes?this.getNotes(item.notes):null;
		const status_notes = item.status_notes?this.getNotes(item.status_notes):null;

		const start = moment(item.start_time);
		const end = moment(item.end_time);
		const current_end = moment(item.current_end_time);

		const late = current_end.isAfter(end);
		const early = current_end.isBefore(end);
		const late_time = getTime(current_end, end);

		return (
			<div 
				className={styles.contextMenu} 
				ref={node=>this.wrapperRef=node}
				style={{top: posY, left: posX}}
			>
				<div className={styles.top}>
					<div className={styles.status}>
						<button className={styles.statusBtn} onClick={this.handleStatus}>
							Update Status
						</button>
					</div>
					<button className={styles.btn} onClick={this.handleEdit}>
						<img 
							src="https://d2voyh5ncb6zec.cloudfront.net/edit.svg"
							alt="edit" 
							className={styles.btnIcon} 
						/>
					</button>
					<button className={styles.btn} onClick={this.handleDelete}>
						<img 
							src="https://d2voyh5ncb6zec.cloudfront.net/trash.svg" 
							alt="trash" 
							className={styles.btnIcon}
						/>
					</button>
				</div>
				<h2 className={styles.name}>{item.name}</h2>
				{owner?
					<div className={styles.smallInfo}>
						<p className={styles.small}>Created By:</p>
						<img 
							src={owner.profile_pic} 
							alt={owner.username} 
							className={styles.profilePic}
						/>
						<p className={styles.profileUsername}>
							{`${owner.username} (${owner.username===this.props.data.username?'You':owner.name})`}
						</p>
					</div>
					:null
				}
				<div className={styles.timeContainer}>
					<div className={styles.start}>
						<p className={styles.small}>Start</p>
						<p className={styles.date}>
							{start.format('MM/D/YY h:mm A')}
						</p>
						<p>{start.format('ddd, MMMM Do')}</p>
					</div>
					<div className={styles.arrows}>
						<div className={styles.rightArrow}></div>
						<div className={styles.rightArrow2}></div>
					</div>
					<div className={styles.end}>
						<p className={styles.small}>Current End</p>
						<p 
							className={styles.date}
							style={{color: late?'red':'black'}}
						>
							{current_end.format('MM/D/YY h:mm A')}
						</p>
						<p
							style={{color: late?'red':'black'}}
						>
							{current_end.format('ddd, MMMM Do')}
						</p>
					</div>
				</div>
				{late?
					<div className={styles.lateContainer}>
						<p className={styles.small}>Original End:</p>
						<p className={styles.late}>{end.format('MM/D/YY h:mm A')}</p>
						<p className={`${styles.late} ${styles.red}`}>
							{`(${late_time.num} ${late_time.time} ${late_time.status})`}
						</p>
					</div> 
					:null 
				}
				{early?
					<div className={styles.lateContainer}>
						<p className={styles.small}>Original End:</p>
						<p className={styles.late}>{end.format('MM/D/YY h:mm A')}</p>
						<p className={`${styles.late} ${styles.green}`}>
							{`(${late_time.num} ${late_time.time} ${late_time.status})`}
						</p>
					</div> 
					:null 
				}
				{item.assigned_users.length>0?
					<div className={styles.assignedSection}>
						Assigned to: 
						{this.renderAssignedUsers(item.assigned_users)}
					</div>
					:null
				}
				{notes!=='<p><br></p>'?
					<div 
						className={styles.notes}
						dangerouslySetInnerHTML={{__html: notes}}>
					</div>
					:null
				}
				{status_notes!=='<p><br></p>' && status_notes!==null?
					<div className={styles.notes}>
						<div className={styles.small}>Status Notes:</div>
						<div 
							dangerouslySetInnerHTML={{__html: status_notes}}
						>
						</div>
					</div>
					:null
				}
			</div>
		);
	}
}
