import { SET_SHOW } from './actionTypes';
import axios from 'axios';

export function setShow(val, prev){
	if(val.name===prev) return {
		type: SET_SHOW,
		payload: val
	};

	updateShow(val);
	return {
		type: SET_SHOW,
		payload: val 
	}
}

const updateShow = obj =>{
	const updated = {
		navigation: {
			...obj
		}
	};
	axios.post('https://taskcence.herokuapp.com/api/user/update', updated, 
		{headers:{token:localStorage.getItem('token')}})
		.catch(err=>console.log(err));
}