import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import anime from 'animejs/lib/anime.es.js';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

import LandingTopMenu from './LandingTopMenu';
import MobileMenu from './MobileMenu';

import styles from './css/landingpage.module.css';

export default class LandingPage extends Component {
	constructor(props){
		super(props);
		this.state = {
			openMobileMenu: false,
			menuClosingAnimation: false,
			redirect: false
		};
	}

	componentDidMount() {
		anime({
			targets: this.arrow,
			translateY: -5,
			direction: 'alternate',
			easing: 'easeInOutSine',
			loop: true,
		});

		this.checkToken();
	}

	checkToken = async () => {
		const body = {
			token: localStorage.getItem('token')
		};
		if(body.token){
			const isValidToken = await axios.post('https://taskcence.herokuapp.com/api/user/verify', body)
									.then(token => {return token})
									.catch(err => console.log(err));
			if(isValidToken.data.authenticated){
				this.setState({redirect: true});
			}
		}
	}

	closeMenu = () => {
		this.setState({menuClosingAnimation: true});
		setTimeout(()=>{
			this.setState({openMobileMenu: false, menuClosingAnimation: false});
		}, 500);
			
	}

	render() {
		return (
			this.state.redirect?
				<Redirect to="/login"/> 
				:
				<div className={styles.container}>
					<div className={styles.main}>
						<LandingTopMenu 
							openMenu={()=>this.setState({openMobileMenu: true})}
						/>
						<div className={styles.content}>
							<div className={styles.landingContainerMain}>
								<div className={styles.title}>
									Task management for teams and individuals
								</div>
								<div className={styles.subtitle}>Get jobs done with Taskcence</div>
								<div className={styles.signup}>
									<Link to="/register">
										Sign up for free!
										<div className={styles.arrow} ref={node=>this.arrow=node}></div>
									</Link>
								</div>
								<img 
									src="https://d2voyh5ncb6zec.cloudfront.net/LandingMainPic.PNG"
									alt="dashboard" 
									className={styles.mainPic}
								/>
							</div>
	
							<div className={styles.landingContainer}>
								<div className={styles.title2}>
									No excessive features- just focus on completing your tasks
								</div>
								<img 
									src="https://d2voyh5ncb6zec.cloudfront.net/LandingEasyInterface.PNG" 
									alt="features" 
									className={styles.easyInterface}
								/>
							</div>
	
							<div className={styles.landingContainer}>
								<div className={styles.title2}>
									Work together in teams
								</div>
								<div className={styles.subtitle}>Distribute the workload- get more done in less time</div>
								<img 
									src="https://d2voyh5ncb6zec.cloudfront.net/LandingProject.PNG" 
									alt="project" 
									className={styles.project}
								/>
							</div>
	
							<div className={styles.landingContainer}>
								<div className={styles.title2}>
									Map out your project timeline and edit in&nbsp; 
									<span className={styles.highlight}>
										realtime
									</span>
								</div>
								<img 
									src="https://d2voyh5ncb6zec.cloudfront.net/LandingRoadmap.PNG" 
									alt="roadmap" 
									className={styles.roadmap}
								/>
							</div>
	
							<div className={styles.landingContainer}>
								<div className={styles.title2}>
									Always stay updated on new information 
								</div>
								<img 
									src="https://d2voyh5ncb6zec.cloudfront.net/LandingChat.PNG" 
									alt="chat" 
									className={styles.chat}
								/>
							</div>
						</div>
					</div>
					{this.state.openMobileMenu?
						<div className={styles.menuContainer}>
							<MobileMenu 
								closeMenu={this.closeMenu} 
								closeAnimation={this.state.menuClosingAnimation}
							/>
						</div>
						:null
					}
					<div className={styles.footer}>
						© Taskcence
					</div>
				</div>
		);
	}
}



