//Create Project Only
import React, { Component } from 'react';

import styles from './css/addmember.module.css';

export default class AddMember extends Component {
	constructor(props){
		super(props);
		this.state = {
			searchText: "",
			friends: [],
		}
	}


	handleChange = e => {
		const { name, value } = e.target;
		this.setState({
			[name]:value
		});
	}

	componentDidMount(){
		this.props.friends.forEach(friend=>{
			this.setState(prevState=>{
				return {
					friends: [
						...prevState.friends,
						{
							...friend,
							render: 
							<div 
								className={styles.friendCard} 
								onClick={()=>this.handleAddMember(friend)}
								key={friend.id}
							>
								<img 
									src={friend.profile_pic}
									className={styles.profilePic}
									alt="profile"
								/>
								<span className={styles.friendUsername}>{friend.username}&nbsp;</span>
								<span className={styles.friendName}>({friend.name})</span>
							</div>
						}
					]
				};
			});	
		});
	}

	handleAddMember = (friend) => {
		this.props.updateParent(friend);
		let members = this.state.friends;
		members.filter(member=>member.id!==friend.id);
		this.setState({friends:members});
	}

	getResults = () => {
		let results = [];
		this.state.friends.forEach(friend=>{
			if(friend.username.indexOf(this.state.searchText)>-1){
				results.push(friend.render)
			}
		});
		return results;
	}

	render() {
		return (
			<div className={styles.addMember}>
				<input 
					className={styles.search}
					onChange={this.handleChange}
					value={this.state.searchText}
					name="searchText"
					placeholder="Member usernames..."
					autoComplete="off"
				/>
				<div className={styles.resultsContainer}>
					{this.state.searchText.length>0?this.getResults():null}
				</div>
			</div>
		);
	}
}
