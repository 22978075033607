import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import anime from 'animejs/lib/anime.es.js';

import styles from './css/landingpage.module.css';

export default class MobileMenu extends Component {
	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
		anime({
			targets: this.menu,
			width: [
            	{ value: 0, duration: 0 },
            	{ value: '65%', duration: 300 }
	        ],
	        easing: 'spring(0, 80, 20, 30)'
		});
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.props.closeAnimation){
			anime({
				targets: this.menu,
				width: [
	            	{ value: '65%', duration: 0 },
	            	{ value: 0, duration: 500 }
		        ],
		        easing: 'spring(0, 80, 20, 30)'
			});
		}
	}

	handleClickOutside = e =>{
		if(this.menu && !this.menu.contains(e.target)){
			this.props.closeMenu();
		}
	}

	render() {
		return (
			<div className={this.props.closeAnimation?'':styles.overlay}>
				<div className={styles.mobileMenu} ref={node=>this.menu=node}>
					<div className={styles.menuTop}>
						<img 
							src="https://d2voyh5ncb6zec.cloudfront.net/leftArrow.svg" 
							alt="hide menu" 
							className={styles.leftArrow}
							onClick={()=>this.props.closeMenu()}
						/>
					</div>
					<div className={this.props.closeAnimation?styles.hidden:null}>
						<div className={styles.menuItem}>
							<Link to="/support">Support</Link>
						</div>
						<div className={styles.menuItem}>
							<Link to="/pricing">Pricing</Link>
						</div>
						<div className={styles.menuItem}>
							<Link to="/blog">Blog</Link>
						</div>
						<div className={`${styles.menuItem} ${styles.color}`}>
							<Link to="/login">Login</Link>
						</div>
						<div className={`${styles.menuItem} ${styles.color}`}>
							<Link to="/register">Signup</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
