import React, { useMemo, useState, useCallback, useRef, useEffect } from 'react';
import { createEditor, Editor, Transforms, Range } from 'slate';
import { Slate, Editable, withReact, useSelected, useFocused, ReactEditor } from 'slate-react';
import { withHistory } from "slate-history";

import MentionSelector from './MentionSelector';

import styles from '../css/projectchat.module.css';

const LIST_TYPES = ["orderedlist", "unorderedlist"];
const initialValue = [{
  	type: "paragraph",
  	children: [{ text: "" }]
}];

const withMentions = editor => {
	const { isInline, isVoid } = editor;

	editor.isInline = element => {
		return element.type === 'mention' ? true : isInline(element);
	}

	editor.isVoid = element => {
		return element.type === 'mention' ? true : isVoid(element);
	}

	return editor;
}

export default function MessageInput(props) {
	const ref = useRef();
	const [value, setValue] = useState(initialValue);
	const [target, setTarget] = useState();
	const [mentionText, setMentionText] = useState('');

	const renderElement = useCallback(props => <Element {...props} />, []);
	const renderLeaf = useCallback(props => <Leaf {...props} />, []);
	const editor = useMemo(() => withMentions(withHistory(withReact(createEditor()))), []);

	const pressedKeys = {};
	const onKeyDown = e => {
		pressedKeys[e.key] = true; 

		if(pressedKeys['Shift'] && pressedKeys['Enter']){
			return;
		} else if(pressedKeys['Enter']){
			e.preventDefault();
			handleSend(editor, value);
		}

		if (pressedKeys["b"] && pressedKeys["Control"]) {
			e.preventDefault();
			toggleMark(editor, "bold");
		} else if (pressedKeys["i"] && pressedKeys["Control"]) {
			e.preventDefault();
			toggleMark(editor, "italic");
		} else if (pressedKeys["u"] && pressedKeys["Control"]) {
			e.preventDefault();
			toggleMark(editor, "underline");
		} else if (pressedKeys["o"] && pressedKeys["Alt"]) {
			e.preventDefault();
			toggleBlock(editor, "orderedlist");
		} else if (pressedKeys["u"] && pressedKeys["Alt"]) {
			e.preventDefault();
			toggleBlock(editor, "unorderedlist");
		}
	};

	const onKeyUp = e => {
		delete pressedKeys[e.key];
	};

	const toggleBlock = (editor, format) => {
		const isActive = isBlockActive(editor, format);
		const isList = LIST_TYPES.includes(format);

		Transforms.unwrapNodes(editor, {
			match: n => LIST_TYPES.includes(n.type),
			split: true
		});

		Transforms.setNodes(editor, {
			type: isActive ? "paragraph" : isList ? "list-item" : format
		});

		if (!isActive && isList) {
			const block = { type: format, children: [] };
			Transforms.wrapNodes(editor, block);
		}
	};

	const isBlockActive = (editor, format) => {
		const [match] = Editor.nodes(editor, {
	  		match: n => n.type === format
		});

		return !!match;
	};

	const toggleMark = (editor, format) => {
		const isActive = isMarkActive(editor, format);

		if (isActive) {
			Editor.removeMark(editor, format);
		} else {
			Editor.addMark(editor, format, true);
		}
	};

	const isMarkActive = (editor, format) => {
		const marks = Editor.marks(editor);
		return marks ? marks[format] === true : false;
	};

	const insertMention = member => {
		if(target){
			Transforms.select(editor, target);
			const mention = { type: 'mention', member, children: [{ text: '' }] };
			Transforms.insertNodes(editor, mention);
			Transforms.move(editor);
			setTarget(null);
		}
	}

	const Element = props => {
		const { attributes, children, element } = props;
		switch (element.type) {
			case "unorderedlist":
				return <ul {...attributes}>{children}</ul>;
			case "list-item":
				return <li {...attributes}>{children}</li>;
			case "orderedlist":
				return <ol {...attributes}>{children}</ol>;
			case "mention":
				return <MentionElement {...props}/>
			default:
				return <p {...attributes}>{children}</p>;
		}	
	};

	const Leaf = ({ attributes, children, leaf }) => {
		if (leaf.bold) {
	  		children = <strong>{children}</strong>;
		}

		if (leaf.italic) {
		  children = <em>{children}</em>;
		}

		if (leaf.underline) {
		  children = <u>{children}</u>;
		}

		return <span {...attributes}>{children}</span>;
	};

	const MentionElement = ({ attributes, children, element }) => {
		const selected = useSelected();
  		const focused = useFocused();

  		return(
  			<span 
  				{...attributes} 
  				className={styles.mention}
  				style={{boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none'}}
  			>
  				{'@'+element.member.username}
  				{children}
  			</span>
  		);
	}

	useEffect(()=>{
		if(target && ref.current){
			const el = ref.current
			const domRange = ReactEditor.toDOMRange(editor, target);
			const rect = domRange.getBoundingClientRect();
			el.style.top = `${rect.top + window.pageYOffset - 200}px`;
			el.style.left = `${rect.left + window.pageXOffset}px`;
		}
	}, [editor, target, mentionText]);

	const handleChange = value => {
		setValue(value);
		props.handleTyping();

		const { selection } = editor;
		if (selection && Range.isCollapsed(selection)) {
        	const [start] = Range.edges(selection);
          	const wordBefore = Editor.before(editor, start, { unit: 'word' });
         	const before = wordBefore && Editor.before(editor, wordBefore);
          	const beforeRange = before && Editor.range(editor, before, start);
          	const beforeText = beforeRange && Editor.string(editor, beforeRange);
          	const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);
          	const after = Editor.after(editor, start);
          	const afterRange = Editor.range(editor, start, after);
          	const afterText = Editor.string(editor, afterRange);
          	const afterMatch = afterText.match(/^(\s|$)/);

          if (beforeMatch && afterMatch) {
            setTarget(beforeRange);
            setMentionText(beforeMatch[1])
            return;
          }
        }

        setTarget(null)
	}

	const handleSend = (editor, value) => {
		const point = { path: [0, 0], offset: 0 };
		editor.selection = { anchor: point, focus: point };

		setValue(initialValue);
		props.handleSendMsg(value);
	}

	const buttons = [
		{
			name: 'Bold',
			icon: "https://d2voyh5ncb6zec.cloudfront.net/bold.svg",
			iconDimension: 16,
			onClick: editor=>{
				toggleMark(editor, "bold");
			}
		},
		{
			name: 'Italics',
			icon: "https://d2voyh5ncb6zec.cloudfront.net/italic.svg",
			iconDimension: 16,
			onClick: editor=>{
				toggleMark(editor, "italic");
			}
		},
		{
			name: 'Underline',
			icon: "https://d2voyh5ncb6zec.cloudfront.net/underline.svg",
			iconDimension: 16,
			borderRight: true,
			onClick: editor=>{
				toggleMark(editor, "underline");
			}
		},
		{
			name: 'UnorderedList',
			icon: "https://d2voyh5ncb6zec.cloudfront.net/unorderedlist.svg",
			iconDimension: 18,
			onClick: editor=>{
				toggleBlock(editor, "unorderedlist");
			}
		},
		{
			name: 'OrderedList',
			icon: "https://d2voyh5ncb6zec.cloudfront.net/orderedlist.svg",
			iconDimension: 18,
			onClick: editor=>{
				toggleBlock(editor, "orderedlist");
			}
		}
	];

	const toolbar = buttons.map(btn=>{
		return(
			<button
				type="button"
				key={btn.name}
				onClick={()=>btn.onClick(editor)}
				className={`${styles.toolbarButton} ${btn.borderRight?styles.borderRight:""}`}
			>
				<img 
					src={btn.icon} 
					alt={btn.name} 
					width={btn.iconDimension}
					height={btn.iconDimension}
				/>
			</button>
		);
	});

	const getMatchedMembers = () =>
		props.member_details.filter(member=>member.username.indexOf(mentionText)>-1);

	return ( 
		<div className={styles.input}>
			<div className={styles.toolbar}>
				{toolbar}
				<p className={styles.small}>Note: press&nbsp; 
					<span className={styles.bold}>shift</span> and&nbsp;  
					<span className={styles.bold}>enter</span> for a new line
				</p>
			</div>
			<div className={styles.flex}>
				<div className={styles.editorRegion}>
					{target && getMatchedMembers().length>0? 
						<div className={styles.metionSelectorContainer} ref={ref}>
							<MentionSelector 
								matchedMembers={getMatchedMembers()}
								insertMention={insertMention}
							/>
						</div>
						:null
					}
					<Slate editor={editor} value={value} onChange={value => handleChange(value)}>
						<Editable
							placeholder="Type your message..."
							onKeyDown={onKeyDown}
							renderElement={renderElement}
							renderLeaf={renderLeaf}
							onKeyUp={onKeyUp}
						/>
					</Slate>
				</div>
				<button className={styles.sendBtn} onClick={()=>handleSend(editor, value)}>
					Send
				</button>
			</div>
		</div>
	);
}





