import { LOGIN } from './actionTypes';
import { setError } from '../error/actions';
import axios from 'axios';

export function login(data){
	return function(dispatch) {
		return axios.post('https://taskcence.herokuapp.com/api/user/login', data)
			.then(res => {
				localStorage.setItem('token', res.data.token);
				dispatch({type: LOGIN, payload: res.data});
			})
			.catch(err => {
				if(!err.response.data.err){
					dispatch(setError({name:'login_pass', err:'Password length must be at least 6 characters'}));
				} else {
					dispatch(setError({name:err.response.data.err.name, err:err.response.data.err.msg}));
				}
			});
	}
}


