import { 
	ADD_NOTIFICATION, 
	CLEAR_NOTIFICATION, 
	CLEAR_ALL,
	 SET_NOTIFICATIONS, 
	 SHOW_NOTI_BAR,
	 HIDE_NOTI_BAR 
} from './actionTypes';

import axios from 'axios';

const getConfig = () => {
	return {
		headers: {
			token: localStorage.getItem('token')
		}
	};
}

export function saveNotification(notification){
	axios.post('https://taskcence.herokuapp.com/api/notification/save', {id:notification._id}, getConfig())
		.catch(err=>console.log(err));
}

export function addNotification(notification){
	saveNotification(notification);
	return {
		type: ADD_NOTIFICATION,
		payload: notification
	};
}

export function setNotifications(notifications){
	return {
		type: SET_NOTIFICATIONS,
		payload: notifications
	};
}

export function clearNotification(id){
	axios.post('https://taskcence.herokuapp.com/api/notification/delete/'+id, null, getConfig())
		.catch(err=>console.log(err));
	return {
		type: CLEAR_NOTIFICATION,
		payload: id
	};
}

export function clearAllNotifications(){
	axios.post('https://taskcence.herokuapp.com/api/notification/deleteAll', null, getConfig())
		.catch(err=>console.log(err));
	return {
		type: CLEAR_ALL
	};
}

export function showNotiBar(){
	return {
		type: SHOW_NOTI_BAR
	};
}

export function hideNotiBar(){
	return {
		type: HIDE_NOTI_BAR
	}
}