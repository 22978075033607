import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';

import Section from '../roadmap/Section';

import { ADD_ROADMAP_ITEM, ADD_ROADMAP_SECTION } from '../../../general/modal/modalTypes';

import styles from '../css/roadmap.module.css';

export default class ProjectRoadmap extends Component {
	constructor(props){
		super(props);
		this.socket = this.props.socket;
	}

	shouldComponentUpdate(nextProps, nextState) {
		if(_.isEqual(this.props.project.roadmap, nextProps.project.roadmap)){
			return false;
		}
		return true;
	} 

	componentDidMount() {
		this.socket.on('roadmap-add-section', data=>{
			this.props.addRoadmapSection(data.section, data.project_id, this.props.id, data.showMessage);
		});

		this.socket.on('roadmap-edit-section', data=>{
			this.props.editRoadmapSection(data.section, data.project_id, this.props.id, data.showMessage);
		});

		this.socket.on('roadmap-delete-section', data=>{
			this.props.delRoadmapSection(data.section, data.project_id, this.props.id, 
				this.props.context_menu, this.props.modal, data.showMessage);
		});

		this.socket.on('roadmap-add-item', data=>{
			this.props.addRoadmapItem(data.item, this.props.id, data.showMessage);
		});

		this.socket.on('roadmap-edit-item', data=>{
			this.props.editRoadmapItem(data.item, data.project_id, this.props.id, data.showMessage, data.old_item);
		});

		this.socket.on('roadmap-delete-item', data=>{
			this.props.delRoadmapItem(data.item, data.project_id, this.props.id, 
				this.props.context_menu, this.props.modal, data.showMessage);
		});
	}

	getToken = () => {
		return localStorage.getItem('token');
	}

	handleSection = (event, section, project_id) => {
		this.socket.emit(event, {
			section,
			project_id,
			token: this.getToken()
		});
	}

	handleItem = (event, item, project_id, old_item) => {
		this.socket.emit(event, {
 			item, 
 			old_item,
 			project_id,
 			token: this.getToken()
 		});
	}

	addRoadmapSection = (section, project_id) => {
		this.handleSection('roadmap-add-section', section, project_id);
	}

	editRoadmapSection = (section, project_id) => {
		this.handleSection('roadmap-edit-section', section, project_id);
	}

	delRoadmapSection = data => {
		this.handleSection('roadmap-delete-section', data.section, data.project_id);
	}

	addRoadmapItem = (item, project_id) => {
 		this.handleItem('roadmap-add-item', item, project_id);
	}

	editRoadmapItem = (item, project_id, old_item) => {
		this.handleItem('roadmap-edit-item', item, project_id, old_item);
	}

	delRoadmapItem = data => {
		this.handleItem('roadmap-delete-item', data.item, data.project_id);
	}

	getRoadmapDates = () => {
		let all = [];
		this.props.project.roadmap.items.forEach(i=>all.push(i));
		this.props.project.roadmap.sections.forEach(s=>s.items.forEach(i=>all.push(i)))

		if(all.length===0){
			return {
				start: moment(),
				end: moment()
			};
		}

		let minDate = moment(all[0].start_time);
		let maxDate = moment(all[all.length-1].current_end_time);

		all.forEach(item=>{
			const start = moment(item.start_time);
			const end = moment(item.current_end_time);

			if(start.isBefore(minDate)){
				minDate = start;
			} if(end.isAfter(maxDate)){
				maxDate = end;
			}

		});

		this.props.project.roadmap.sections.forEach(s=>{
			const start = moment(s.start_date);
			const end = moment(s.end_date);

			if(start.isBefore(minDate)){
				minDate = start;
			} if(end.isAfter(maxDate)){
				maxDate = end;
			}
		});

		return {
			start: minDate,
			end: maxDate
		}
	}

	renderSection = (section, markerDates) =>{
		return(
			<Section 
				key={section._id}
				section={section} 
				dates={this.dates} 
				showModal={this.props.showModal}
				setCursorPos={this.props.setCursorPos}	
				showContext={this.props.showContext}
				delRoadmapSection={this.delRoadmapSection}
				editRoadmapSection={this.editRoadmapSection}
				editRoadmapItem={this.editRoadmapItem}
				delRoadmapItem={this.delRoadmapItem}
				project={this.props.project} 
				member_details={this.props.member_details}
				id={this.props.id}
				username={this.props.username}
				name={this.props.name}
				profile_pic={this.props.profile_pic}
				markerDates={markerDates}
			/>
		);
	}  


	render(){
		this.dates = this.getRoadmapDates();

		const markerDates = [this.dates.start.clone().subtract(1, 'day')];
		const s = this.dates.start.clone().startOf('day');
		const e = this.dates.end.clone().startOf('day');
		for(let i=0; i<=Math.abs(s.diff(e, 'day'))+1; i++ ) {
			const date = markerDates[markerDates.length-1].clone();
			markerDates.push(date.add(1, 'day'))
		}

		return (
			<div className={styles.roadmapContainer}>
				<div className={styles.top}>
					<button onClick={()=>this.props.showModal(ADD_ROADMAP_SECTION,{
						topText: "Create Roadmap Section",
						btnName: "Create Section",
						handleSubmit: this.addRoadmapSection,
						project_id: this.props.project._id,
						id:this.props.id
					})}
						className={styles.topBtn}
					>
						New Section 
					</button>
					<button onClick={()=>this.props.showModal(ADD_ROADMAP_ITEM,{
						topText: "Create Roadmap Item",
						btnName: "Create Item",
						handleSubmit: this.addRoadmapItem,
						project_id: this.props.project._id,
						member_details: this.props.member_details, 
						project: this.props.project,
						id: this.props.id
					})}
						className={styles.topBtn}
					>
						New Item
					</button>
				</div>
				<div className={styles.roadmap}>
					<div className={styles.roadmapTop}>
						<div 
							className={`${styles.markerContainer} ${'scrollSync'}`}
						>
							{markerDates.map((date, i)=>
								<div className={styles.marker} key={i}>
									<p className={styles.date}>{date.format('MMM D')}</p>
								</div>
							)}
						</div>
					</div>
					<div className={styles.sectionsContainer}>
						{this.renderSection(this.props.project.roadmap.items, markerDates)}
						{this.props.project.roadmap.sections.map(section=>
							this.renderSection(section, markerDates)
						)}
					</div>
				</div>
			</div>
		);
	}
} 
  