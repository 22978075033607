import React, { Component } from 'react';
import _ from 'lodash';

import styles from './css/membersselector.module.css';

export default class MembersSelector extends Component {
	constructor(props){
		super(props);
		this.state = {
			search: ""
		};
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside = e =>{
		if(this.wrapperRef && !this.wrapperRef.contains(e.target)){
			this.setState({search: ''});
		}
	}

	handleAdd = member => {
		this.props.updateParent(this.props.name, [
			...this.props.added,
			member
		]);
	}

	handleDelete = member => {
		const added = this.props.added.filter(m=>m.id!==member.id);
		this.props.updateParent(this.props.name, added);
	}

	renderMember = (member, showDel) => {
		return(
			<div 
				className={styles.memberCard}  
				key={member.id} 
				onClick={!showDel?()=>this.handleAdd(member):null}
			>
				<img src={member.profile_pic} className={styles.profilePic} alt="profile"/>
				<p className={styles.username}>
					{member.username}&nbsp; 
					<span className={styles.name}>
						({member.name})
					</span> 
				</p>
				{showDel?
					<div className={styles.delete} onClick={()=>this.handleDelete(member)}>
						&times;
					</div>
					:null
				}
			</div>
		);
	}

	getResults = () => {
		let results = this.props.member_details.filter(m=>
			m.username.indexOf(this.state.search)>-1 &&
			!_.find(this.props.added, {id:m.id})
		);
		if(results.length===0){
			return <div>None found</div>
		}

		return results.map(member=>{
			return this.renderMember(member, false);
		});
	}

	handleChange = e => {
		const { name, value } = e.target;
		this.setState({[name]:value});
	}

	render() {
		return (
			<div className={styles.selectorContainer}>
				<div className={styles.addedContainer}>
					{this.props.added.map(m=>this.renderMember(m, true))}
				</div>
				<input 
					className={styles.searchBar}
					onChange={this.handleChange}
					value={this.state.search}
					name="search"
					autoComplete="Off"
					placeholder="Member username..."
				/>
				{this.state.search?
					<div className={styles.resultsContainer} ref={node=>this.wrapperRef=node}>
						{this.getResults()}
					</div>
					:null
				}
			</div>
		);
	}
}
