import React, { Component } from 'react';
import moment from 'moment';

import styles from './css/weekview.module.css';

export default class WeekView extends Component {
	constructor(props){
		super(props);
		this.state = {
			displayedDays: this.getCurrentWeek()
		};
	}

	getCurrentWeek = () => {
		let days = [];
		for(let i=0; i<7;i++){
			days.push(moment().add(i, 'days'));
		}
		return days;
	}

	getNextWeek = () => {
		let lastDay = this.state.displayedDays.slice(-1)[0];
		let days = [];
		for(let i=1;i<8;i++){
			let temp = moment(lastDay);
			days.push(temp.add(i, 'days'));
		}
		this.setState({displayedDays: days})
	}

	getPrevWeek = () => {
		let lastDay = this.state.displayedDays[0];
		let days = [];
		for(let i=7;i>0;i--){
			let temp = moment(lastDay);
			days.push(temp.subtract(i, 'days'));
		}
		this.setState({displayedDays: days})
	}

	getStyles = day => {
		let num = 0;
		this.props.all_todos.forEach(todo => {
			if(moment(todo.start_time).isSame(day, 'day')){
				num++;
			}
		});
		return [`${styles.day} ${num >= 3 ? (num>=5 ? styles.red : styles.yellow) : null}`, num]
	}

	render() {
		const now = moment();
		const displayedDates = this.state.displayedDays.map((day, index) => {
			const style = this.getStyles(day);
			return(
				<div 	
					className={`${style[0]} ${moment(day).isSame(now, 'day')?styles.today:null}`} 
					key={index}
					onClick={()=>this.props.updateViewShowDate(day)}
				>
					<div className={styles.content}>
						<p className={styles.date}>{day.format('ddd')}</p>
						<p>{day.format('MMM D')}</p>
						{style[1]>2?<p className={styles.eventNum}>{style[1]+' events'}</p>:null}
					</div>	
				</div>
			);
		});
		return (
			<div className={styles.weekContainer}>
				<div className={styles.arrowLeft} onClick={this.getPrevWeek}></div>
				<div className={styles.daysContainer}>
					{displayedDates}
				</div>
				<div className={styles.arrowRight} onClick={this.getNextWeek}></div>
			</div>
		); 
	}
}
