import moment from 'moment';

export const time_choices = [
	'12:00 AM',
	'12:30 AM',
	'1:00 AM',
	'1:30 AM',
	'2:00 AM',
	'2:30 AM',
	'3:00 AM',
	'3:30 AM',
	'4:00 AM',
	'4:30 AM',
	'5:00 AM',
	'5:30 AM',
	'6:00 AM',
	'6:30 AM',
	'7:00 AM',
	'7:30 AM',
	'8:00 AM',
	'8:30 AM',
	'9:00 AM',
	'9:30 AM',
	'10:00 AM',
	'10:30 AM',
	'11:00 AM',
	'11:30 AM',
	'12:00 PM',
	'12:30 PM',
	'1:00 PM',
	'1:30 PM',
	'2:00 PM',
	'2:30 PM',
	'3:00 PM',
	'3:30 PM',
	'4:00 PM',
	'4:30 PM',
	'5:00 PM',
	'5:30 PM',
	'6:00 PM',
	'6:30 PM',
	'7:00 PM',
	'7:30 PM',
	'8:00 PM',
	'8:30 PM',
	'9:00 PM',
	'9:30 PM',
	'10:00 PM',
	'10:30 PM',
	'11:00 PM',
	'11:30 PM'
];

export const selectionTimes = [
	'Never', 
	'At time of event', 
	'5 minutes before', 
	'15 minutes before', 
	'30 minutes before', 
	'1 hour before', 
	'2 hours before', 
	'12 hours before',
	'1 day before', 
	'3 days before', 
	'1 week before'
];

export const statusTimes = [
	'minutes',
	'hours',
	'days',
	'weeks',
	'months',
	'years'
];

export const getTime = (current_end, end) => {
	const early = current_end.diff(end, 'minute')<0
	const min_diff = Math.abs(current_end.diff(end, 'minute'));
	const today = moment();
	const days_this_month = today.daysInMonth();
	if(min_diff<60) return { num: min_diff, time: statusTimes[0], status: early?'early':'late' };
	else if(min_diff<1440) return { num: min_diff/60, time: statusTimes[1], status: early?'early':'late' };  
	else if(min_diff<10080) return { num: min_diff/1440, time: statusTimes[2], status: early?'early':'late' };  
	else if(min_diff<days_this_month*1440) return { num: min_diff/10080, time: statusTimes[3], status: early?'early':'late' };
	else if(min_diff<483840) return { num: min_diff/(days_this_month*1440), time: statusTimes[4], status: early?'early':'late' };
	else return { num: Math.round(min_diff/483840), time: statusTimes[5], status: early?'Early':'late' };

} 