// ----------------------------Todo-----------------------------------
export const ADD_TODO_MODAL = 'ADD_TODO_MODAL';
export const EDIT_TODO_MODAL = 'EDIT_TODO_MODAL';
export const CONFIRM_DELETE_MODAL = 'CONFIRM_DELETE_MODAL';


// ----------------------------Task-----------------------------------
export const ADD_TASK = 'ADD_TASK';
export const EDIT_TASK = 'EDIT_TASK';

// ----------------------------Project-----------------------------------
export const ADD_PROJECT = 'ADD_PROJECT';
export const ADD_MEMBER = 'ADD_MEMBER';
export const ADD_PROJECT_TODO = 'ADD_PROJECT_TODO';
export const EDIT_PROJECT_TODO = 'EDIT_PROJECT_TODO';

export const ADD_ROADMAP_SECTION = 'ADD_ROADMAP_SECTION';
export const ADD_ROADMAP_ITEM = 'ADD_ROADMAP_ITEM';
export const UPDATE_ITEM_STATUS = 'UPDATE_ITEM_STATUS';