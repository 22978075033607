import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Portal } from 'react-portal';

import { 
		TODO_CONTEXT, 
		TODO_INFO, 
		TASK_CONTEXT, 
		TASK_INFO, 
		SIDEBAR_CONTEXT, 
		PROJECT_TODO_CONTEXT,
		ROADMAP_SECTION_INFO,
		ROADMAP_ITEM_INFO
} from './contextTypes';

import TodoContext from './menus/TodoContext';
import TodoInfo from './menus/TodoInfo';
import TaskContext from './menus/TaskContext';
import TaskInfo from './menus/TaskInfo';
import SidebarContext from './menus/SidebarContext';
import ProjectTodoContext from './menus/ProjectTodoContext';
import SectionInfo from '../../project/components/general/SectionInfo';
import ItemInfo from '../../project/components/general/ItemInfo';

import { closeContext } from './actions';
import { showModal } from '../modal/actions';
import { deleteTask } from '../../task/actions';

class ContextManager extends Component {
	render() {
		switch(this.props.contextType){
			case TODO_CONTEXT:
				return(
					<Portal>
						<TodoContext 
							closeContext={this.props.closeContext}
							cursorX={this.props.cursorPos.cursorX}
							cursorY={this.props.cursorPos.cursorY}
							data={this.props.data}
							showModal={this.props.showModal}
						/>
					</Portal>
				); 

			case TODO_INFO:
				return(
					<Portal>
						<TodoInfo 
							closeContext={this.props.closeContext}
							cursorX={this.props.cursorPos.cursorX}
							cursorY={this.props.cursorPos.cursorY}
							todo={this.props.data}
						/>
					</Portal>
				);

			case TASK_CONTEXT:
				return(
					<Portal>
						<TaskContext 
							closeContext={this.props.closeContext}
							cursorX={this.props.cursorPos.cursorX}
							cursorY={this.props.cursorPos.cursorY}
							task={this.props.data}
							showModal={this.props.showModal}
							deleteTask={this.props.deleteTask} 
						/>
					</Portal>
				);

			case TASK_INFO:
				return(
					<Portal>
						<TaskInfo 
							closeContext={this.props.closeContext}
							cursorX={this.props.cursorPos.cursorX}
							cursorY={this.props.cursorPos.cursorY}
							task={this.props.data}
						/>
					</Portal>
				);

			case SIDEBAR_CONTEXT:
				return(
					<Portal>
						<SidebarContext 
							showModal={this.props.showModal} 
							closeContext={this.props.closeContext}
							cursorX={this.props.cursorPos.cursorX}
							cursorY={this.props.cursorPos.cursorY}
						/>
					</Portal>
				);

			case PROJECT_TODO_CONTEXT:
				return(
					<Portal>
						<ProjectTodoContext 
							showModal={this.props.showModal}
							closeContext={this.props.closeContext}
							cursorX={this.props.cursorPos.cursorX}
							cursorY={this.props.cursorPos.cursorY} 
							data={this.props.data}
						/>
					</Portal>
				);				

			case ROADMAP_SECTION_INFO:
				return(
					<Portal>
						<SectionInfo 
							showModal={this.props.showModal}
							closeContext={this.props.closeContext}
							cursorX={this.props.cursorPos.cursorX}
							cursorY={this.props.cursorPos.cursorY} 
							data={this.props.data}
						/>
					</Portal>
				);

			case ROADMAP_ITEM_INFO:
				return(
					<Portal>
						<ItemInfo 
							showModal={this.props.showModal}
							closeContext={this.props.closeContext}
							cursorX={this.props.cursorPos.cursorX}
							cursorY={this.props.cursorPos.cursorY} 
							data={this.props.data}
						/>
					</Portal>
				);
			default:
				return null;
		}
	}
}

function mapStateToProps(state) {
	const contextType = state.context_menu.type
	return {
		contextType: contextType,
		data: state.context_menu.data,
		cursorPos: {
			cursorX: state.context_menu.cursorPos.cursorX,
			cursorY: state.context_menu.cursorPos.cursorY 
		}
	}
} 

const mapDispatchToProps = {
	closeContext, 
	showModal,
	deleteTask,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContextManager);
