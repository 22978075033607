import React, { Component } from 'react';
import anime from 'animejs/lib/anime.es.js';

import Notification from './Notification.js';

import styles from '../css/notificationbar.module.css';

export default class NotificationBar extends Component { 	
	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
		anime({
			targets: this.wrapperRef,
			width: [
            	{ value: 0, duration: 0 },
            	{ value: 250, duration: 500 }
	        ],
		});
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside = e =>{
		if(this.wrapperRef && !this.wrapperRef.contains(e.target)){
			this.props.hideNotiBar();
		}
	}

	handleClearAll = () => {
		this.props.clearAllNotifications();
	}

	setWrapperRef = node =>{
		this.wrapperRef = node;
	}

	handleClose = () => {
		this.props.hideNotiBar();
	}

	render() {
		const notifications = this.props.notifications.map((item, index)=>{
			return(
				<Notification 
					item={item} 
					clearNotification={this.props.clearNotification}
					key={index}
					editTodo={this.props.editTodo}
					editTask={this.props.editTask}
					handleFriend={this.props.handleFriend} 
					editProjectTodo={this.props.editProjectTodo}
				/>
			);
		});
		return (
			this.props.showBar?
				<div className={styles.notificationBarContainer} ref={this.setWrapperRef}>
					<div className={styles.notificationBar}>
						<div className={styles.top}>
							<p>Notifications</p>
							<button onClick={this.handleClose} className={styles.close}>&times;</button>
						</div>
						{notifications.length>0?
							<React.Fragment>
								<div className={styles.clear}>
									<button onClick={this.handleClearAll}>Clear All</button>
								</div>
								{notifications}
							</React.Fragment>
						:
							<div className={styles.center}>No Notifications</div>
						}
					</div>
				</div>
			: null 
		);
	}
}
