import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import axios from 'axios';

import { LOGIN } from '../../auth/actionTypes';

//TAB FOCUS VARS
let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support 
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}
//------------------------------------------------------------
 
export class ProtectedRoute extends Component {
	constructor(props){
		super(props);
		this.state = {
			value: null
		}
	}

	componentDidMount(){
		this._isMounted = true;
		this.validate();
		//tab focus
		document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
	}

	componentWillUnmount() {
		this._isMounted = false;
		document.removeEventListener(visibilityChange, this.handleVisibilityChange);
	}


	handleVisibilityChange = () => {
		if(!document[hidden]) {
			this.validate()
		} 
	}

	async validate(){
		if(this._isMounted){
			if(!localStorage.getItem('token')){
				this.setState({
					value: <Redirect to="/login"/>
				});
				return;
			}
			const body = {
				token: localStorage.getItem('token')
			};
			const isValidToken = await axios.post('https://taskcence.herokuapp.com/api/user/verify', body)
									.then(token => {return token})
									.catch(err => console.log(err));
			if(!isValidToken.data.authenticated){
				this.setState({
					value: <Redirect to="/login"/> 
				});
				return ;
			}

			this.props.setLogin({
				...isValidToken.data
			}); 
			
			this.setState({
				value: <Route path={this.props.path} component={this.props.component}/>
			})
		}
	}

	render(){
		return(this.state.value);
	}
}

const mapDispatchToProps = dispatch => {
	return {
		setLogin: payload => dispatch({type: LOGIN, payload})
	};
	
}	

export default connect(null, mapDispatchToProps)(ProtectedRoute);


