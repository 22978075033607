//Adding additional members only
import React, { Component } from 'react';
import _ from 'lodash';
import ReactTooltip from "react-tooltip";

import Modal from '../../../general/modal/modals/Modal';

import styles from '../css/addmember.module.css';

export default class AddMembers extends Component {
	constructor(props){
		super(props);
		this.state = {
			search: "",
			members: [],
			availableMembers: this.props.data.friends.filter(friend=>
				!_.find(this.props.data.member_details, {id:friend.id})
			)
		};
	} 


	handleChange = e => {
		const { name, value } = e.target;
		this.setState({
			[name]:value 
		});
	} 

	handleAddMember = member => {
		this.setState({
			members: [
				...this.state.members,
				member
			],
			availableMembers: this.state.availableMembers.filter(m=>m.id!==member.id)
		});
	}

	handleDeleteMember = member => {
		const members = this.state.members.filter(m=>m.id!==member.id);
		this.setState({
			members,
			availableMembers: [
				...this.state.availableMembers,
				member
			]
		});
	}

	handleConfirm = () => {
		this.props.data.addMembersToProject(this.state.members, this.props.data.project_id);
		this.props.hideModal();
	}

	member = (member, showDel, onClick) => {
		return(
			<div 
				className={styles.memberCard} 
				key={member.id} 
				data-tip 
				data-for={member.id}
				onClick={()=>onClick?this.handleAddMember(member):null}
				style={{cursor:onClick?'pointer':'auto'}}
			>
				<img src={member.profile_pic} alt="profile" className={styles.profilePic}/>
				<p className={styles.memberName}>{member.username}</p>
				{showDel?<button 
					onClick={()=>this.handleDeleteMember(member)} 
					className={styles.delete}
				>
					&times;
				</button>
				:null
				}
				<ReactTooltip id={member.id} className={styles.tooltip}>{member.name}</ReactTooltip>
			</div>
		);
	}

	renderResults = () => {
		if(!this.state.search) return;
		return this.state.availableMembers.filter(
			member=>member.username.indexOf(this.state.search)>-1
		).map(member=>this.member(member, false, true));
	}

	render() {
		return (
			<Modal 
				width="55vw" 
				maxWidth="600px" 
				height="80vh" 
				maxHeight="535px"
				hideModal={this.props.hideModal}
				top={true}
				topText="Add Members"
			>
				<div className={styles.addMemberContent}>
					<div>
						<input 
							className={styles.searchInput}
							value={this.state.search}
							onChange={this.handleChange}
							placeholder="Member username..."
							autoComplete="off"
							name="search"
						/>
						<div className={styles.results}>
							{this.renderResults()}
						</div>
					</div>
					<div className={styles.listContainers}>
						<div className={styles.currentMembers}>
							<h2 className={styles.subtitle}>Current Members:</h2>
							<div className={styles.memberContainer}>
								{this.props.data.member_details.map(member=>this.member(member, false, false))}
							</div>
						</div>
						<div className={styles.resultsContainer}>
							<h2 className={styles.subtitle}>Added Members:</h2>
							<div className={styles.memberContainer}>
								{this.state.members.map(member=>this.member(member, true, false))}
							</div>
							
						</div>
					</div>
				</div>
				<button 
					className={styles.confirm}
					onClick={this.handleConfirm}
				>
					Add Members
				</button>
			</Modal>
		);
	}
}
