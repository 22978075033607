import { LOGIN, SET_PROFILE_PIC, UPDATE_USER } from './actionTypes';

const initialState = {
	auth: {
		username: "",
		name: "",
		email: "",
		profilePic: {},
		id: '',
		navigation: {}
	}
}

export function auth(state=initialState, action) {
	switch(action.type) {
		case LOGIN:
			return { 
				...state,
				username: action.payload.username,
				name: action.payload.name,
				email: action.payload.email,
				friends: action.payload.friends,
				id: action.payload.id,
				navigation: action.payload.navigation, //Dashboard has access to data,
				disable_success: action.payload.disable_success,
				disable_error: action.payload.disable_error,
				disable_info: action.payload.disable_info
			};


		case SET_PROFILE_PIC:
			return {
				...state,
				profilePic: action.payload 
			};

		case UPDATE_USER:
			return {
				...state,
				...action.payload
			};

		default:
			return state; 
	}
}

