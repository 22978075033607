import React, { Component } from 'react';
import moment from 'moment';

import { ADD_TODO_MODAL, ADD_TASK } from '../modal/modalTypes';

import DashHeader from './DashHeader';
import TodayView from './TodayView';
import TodoList from './TodoList';

import styles from './css/dashboard.module.css';

const now = moment();

export default class Home extends Component {
	constructor(props){
		super(props);
		this.state = {
			todayViewShowDate: now
		};
	}

	handleTodoModal = () =>{
		this.props.showModal(ADD_TODO_MODAL, {handleAdd: this.props.addTodo});
	}

	handleTaskModal = () =>{
		this.props.showModal(ADD_TASK);
	}
	render() {
		return (
			<div>
				<DashHeader 
					name={this.props.name}
					all_todos={this.props.todos}
					todayViewShowDate={this.state.todayViewShowDate}
					updateViewShowDate={date=>this.setState({todayViewShowDate: date})}
				/>
				<button className={styles.addBtn} onClick={this.handleTodoModal}>Add Event</button>
				<button className={styles.addBtn} onClick={this.handleTaskModal}>Add Task</button>
				{!this.state.todayViewShowDate.isSame(now, 'day')?
					<div>
						Events for&nbsp; 
						{this.state.todayViewShowDate.format('M/D/YYYY')}
					</div>
					:null
				}
				<div className={styles.split}>
					<div className={styles.todayContainer}>
						<TodayView 
							all_todos={this.props.todos.concat(this.props.tasks)}
							showContext={this.props.showContext}
							setCursorPos={this.props.setCursorPos}
							editTodo={this.props.editTodo}
							editTask={this.props.editTask}
							deleteTodo={this.props.deleteTodo}
							todayViewShowDate={this.state.todayViewShowDate}
						/>
					</div>
					<div className={styles.upcomingContainer}>
						<TodoList 
							todos={this.props.todos}
							tasks={this.props.tasks}
							showContext={this.props.showContext}
							setCursorPos={this.props.setCursorPos}
							editTodo={this.props.editTodo}
							editTask={this.props.editTask}
							deleteTodo={this.props.deleteTodo}
						/>
					</div>
				</div>
			</div>
		);
	}
}
