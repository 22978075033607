import React, { Component } from 'react';
import Compressor from 'compressorjs';
import { connect } from 'react-redux';
import Switch from "react-switch";

import { uploadProfilePic, getProfilePic, updateUser } from '../actions';

import TextInput from '../../general/misc_components/TextInput';

import styles from './css/profile.module.css';

class Profile extends Component {
	constructor(props){
		super(props);
		this.state = {
			email: this.props.email,
			name: this.props.name,
			disable_success: this.props.disable_success || false,
			disable_error: this.props.disable_error || false,
			disable_info: this.props.disable_info || false
		};

		this.config = {
			headers: { 
				token: localStorage.getItem('token')
			}
		};
	}

	componentDidUpdate(prevProps, prevState){
		if(this.encode(prevProps.profilePic)!==this.encode(this.props.profilePic)){
			this.props.getProfilePic();
		}
	}

	handleFileUpload = e => {
		e.preventDefault();
		const file = e.target.files[0];
		if(!file || !file.type.match(/image.[png, jpeg, gif]/)){return}
		const t = this;
		new Compressor(file, {
			width: 150,
			height: 150,
			minWidth: 150,
			minHeight: 150,
			maxWidth: 150,
			maxHeight: 150,
			success(result) {
				t.props.uploadProfilePic(result);
			}
		});
	}

	encode = buffer => {
		const d = new Uint8Array(buffer);
		const STRING_CHAR = d.reduce((data, byte)=> {
			return data + String.fromCharCode(byte);
		}, '');
		return btoa(STRING_CHAR);
	}

	updateState = (name, val) => {
		this.setState({[name]:val});
	}

	handleChecked = name => {
		this.setState({
			[name]: !this.state[name]
		});
	}

	handleSave = () => {
		const updatedUser = {
			...this.state
		};
		this.props.updateUser(updatedUser);
	} 

	render() {
		return (
			<div className={styles.profileContainer}>
				<div className={styles.profileTop}>
					<div>
						<label className={styles.fileUploadBtn}>
							<input type="file" onChange={this.handleFileUpload} className={styles.fileUpload}/>
						</label>
						<img 
							className={styles.profilePic}
							src={"data:image/jpeg;base64," +this.encode(this.props.profilePic)} 
							alt="profile"
						/>
					</div>
					<div className={styles.profileName}>
						<TextInput 
							name="name"
							val={this.state.name}
							minLength={0}
							updateParent={this.updateState}
							fontSize={50}
							bold={true}
							width={"calc(100% - 180px)"}
						/>
					</div>
				</div>
				<div className={styles.profileContent}>
					<p className={styles.light}>Double click on any field to update</p>
					<div className={styles.inputField}>
						<p className={styles.bold}>Email:</p>
						<TextInput 
							name="email"
							val={this.state.email} 
							minLength={6}
							updateParent={this.updateState}
							fontSize={20}
							width={"calc(100% - 180px)"}
						/>
					</div>
					<div className={styles.disableMessages}>
						<div className={styles.disable}>
							<div className={styles.disableText}>
								Disable <span className={styles.green}>Success</span> Messages
							</div>
							<label>
								<Switch 
									onChange={()=>this.handleChecked('disable_success')} 
									checked={this.state.disable_success}
								/>
							</label>
						</div>
						<div className={styles.disable}>
							<div className={styles.disableText}>
								Disable <span className={styles.red}>Error</span> Messages
							</div>
							<label>
								<Switch 
									onChange={()=>this.handleChecked('disable_error')} 
									checked={this.state.disable_error}
								/>
							</label>
						</div>
						<div className={styles.disable}>
							<div className={styles.disableText}>
								Disable <span className={styles.gray}>Info</span> Messages
							</div>
							<label>
								<Switch 
									onChange={()=>this.handleChecked('disable_info')} 
									checked={this.state.disable_info}
								/>
							</label>
						</div>
					</div>
				</div>
				<div className={styles.bottom}>
					<button 
						className={styles.saveBtn}
						onClick={this.handleSave}
					>
						Save
					</button>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		name: state.auth.name,
		email: state.auth.email,
		profilePic: state.auth.profilePic,
		disable_success: state.auth.disable_success,
		disable_error: state.auth.disable_error,
		disable_info: state.auth.disable_info
	};
}

const mapDispatchToProps = {
	uploadProfilePic,
	getProfilePic,
	updateUser
}


export default connect(mapStateToProps, mapDispatchToProps)(Profile);
