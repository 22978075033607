export const CREATE_PROJECT = 'CREATE_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const SET_PROJECTS = 'SET_PROJECTS';
export const CHANGE_PROJECT_NAME = 'CHANGE_PROJECT_NAME';

export const ADD_MEMBERS = 'ADD_MEMBERS';
export const DELETE_MEMBER = 'DELETE_MEMBER';

export const CREATE_PROJECT_TODO = 'CREATE_PROJECT_TODO';
export const EDIT_PROJECT_TODO = 'EDIT_PROJECT_TODO';
export const DELETE_PROJECT_TODO = 'DELETE_PROJECT_TODO';

export const ADD_ROADMAP_SECTION = 'ADD_ROADMAP_SECTION';
export const EDIT_ROADMAP_SECTION = 'EDIT_ROADMAP_SECTION';
export const DEL_ROADMAP_SECTION = 'DEL_ROADMAP_SECTION';
export const ADD_ROADMAP_ITEM = 'ADD_ROADMAP_ITEM';
export const EDIT_ROADMAP_ITEM = 'EDIT_ROADMAP_ITEM';
export const DEL_ROADMAP_ITEM = 'DEL_ROADMAP_ITEM';
