import React, { Component } from 'react';
import { Link } from 'react-router-dom';  

import styles from './css/landingpage.module.css';

export default class LandingTopMenu extends Component {
	render() {
		return (
			<div>
				<div className={`${styles.mobile} ${styles.topMenu}`}>
					<div 
						className={`${styles.hamburgerMenu}`} 
						onClick={()=>this.props.openMenu()}
					>
						<div className={`${styles.line} ${styles.long1}`}></div>
						<div className={`${styles.line} ${styles.short1}`}></div>
						<div className={`${styles.line} ${styles.long2}`}></div>
						<div className={`${styles.line} ${styles.short2}`}></div>
					</div>
					<div className={`${styles.mobileLogo}`}>
						<Link to="/">
							<img 
								src="https://d2voyh5ncb6zec.cloudfront.net/logo.png" 
								alt="taskcence" 
								className={styles.logo}
							/>
						</Link>
					</div>
				</div>

				<div className={`${styles.topMenuDesktop}`}>
					<div className={`${styles.desktopLogo}`}>
						<Link to="/">
							<img 
								src="https://d2voyh5ncb6zec.cloudfront.net/logo.png"
							 	alt="taskcence" 
							 	className={styles.logo}
							/>
						</Link>
					</div>
					<div className={`${styles.topMenuItems}`}>
						<div className={styles.topMenuItem}>
							<Link to="/support">Support</Link>
						</div>
						<div className={styles.topMenuItem}>
							<Link to="/pricing">Pricing</Link>
						</div>
						<div className={styles.topMenuItem}>
							<Link to="/blog">Blog</Link>
						</div>
					</div>
					<div className={`${styles.topMenuAccount}`}>
						<button className={styles.login}>
							<Link to="/login">Login</Link>
						</button>
						<button className={styles.signupTop}>
							<Link to="/register">Get Started</Link>
						</button>
					</div>
				</div>
			</div>
		);
	}
}
