import React, { Component } from 'react';
import { Link } from 'react-router-dom'; 

import LandingTopMenu from './LandingTopMenu';
import MobileMenu from './MobileMenu';

import styles from './css/notfound.module.css';

export default class NotFound extends Component {
	constructor(props){
		super(props);
		this.state = {
			openMobileMenu: false,
			menuClosingAnimation: false
		};
	}

	closeMenu = () => {
		this.setState({menuClosingAnimation: true});
		setTimeout(()=>{
			this.setState({openMobileMenu: false, menuClosingAnimation: false});
		}, 500);
			
	}

	render() {
		return (
			<div>
				<LandingTopMenu 
					openMenu={()=>this.setState({openMobileMenu: true})}
				/>
				<div>
					<div className={styles.center}>
						<img 
							// src="https://d2voyh5ncb6zec.cloudfront.net/NotFound.png"
							src="https://d2voyh5ncb6zec.cloudfront.net/NotFound.png"
							alt="404 Not Found"
							className={styles.notFound}
						/>
						<a 
							href="http://www.freepik.com"
							className={styles.none}
						>
								Designed by pikisuperstar / Freepik
						</a>
						<button className={styles.btn}>
							<Link to="/">
								Go Back Home
							</Link>
						</button>
					 </div>
				</div>
				{this.state.openMobileMenu?
					<div className={styles.menuContainer}>
						<MobileMenu 
							closeMenu={this.closeMenu} 
							closeAnimation={this.state.menuClosingAnimation}
						/>
					</div>
					:null
				}
			</div>
		);
	}
}
