import { SHOW_MODAL, HIDE_MODAL } from './actionTypes';

const initialState = {
	show: false,
	type: "",
	data: {}
}

export function modal(state=initialState, action){
	switch(action.type){
		case SHOW_MODAL:
			return {
				show: true,
				type: action.payload.modalType,
				data: action.payload.data
			}; 

		case HIDE_MODAL:
			return {
				show: false,
				type: "",
				data: {}
			};

		default:
			return state;
	}
}