import { GET_TODOS, SET_LOADING, ADD_TODO, DELETE_TODO, EDIT_TODO } from './actionTypes';

const initialState = {
	data: [],
	isLoading: false
}
export function todos(state=initialState, action){
	switch(action.type){
		case GET_TODOS:
			return {
				...state,
				data: action.payload
			};

		case SET_LOADING:
			return { 
				...state,
				isLoading: action.payload
			};

		case ADD_TODO:
			const data = [...state.data];
			data.push(action.payload);
			return {
				...state,
				data: data
			};

		case DELETE_TODO:
			const newData = state.data
				.filter(todo => todo._id!==action.payload)
				.map(todo => todo)
			return {
				...state,
				data: newData
			};

		case EDIT_TODO:
			const index = state.data.findIndex(todo => todo._id===action.payload._id);
			return {
				...state,
				data: Object.assign([], state.data,{[index]: action.payload})
			};

		default:
			return state;
	}
}