import { SHOW_MODAL, HIDE_MODAL } from './actionTypes';

export function showModal(modalType, data){
	return {
		type: SHOW_MODAL,
		payload: {
			modalType: modalType,
			data: data 
		}
	};
}

export function hideModal(){
	return {
		type: HIDE_MODAL
	};
}